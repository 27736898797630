import React from 'react';

import { Image } from 'antd';
import styled from 'styled-components';

const ImageContainer = styled.div`
  position: relative;
  .ant-image {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: ${({ ratio = 1 }) => `${ratio * 100}%`};
  }
`;

function ImageRatio({ src, alt, ratio, preview = false, ...props }) {
  return (
    <ImageContainer ratio={ratio} {...props}>
      <Image alt={alt} src={src} preview={preview} />
    </ImageContainer>
  );
}

export default ImageRatio;
