import React from "react";

export default function NextSvg() {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.14645 9.64645L7.79289 10L8.5 10.7071L8.85355 10.3536L8.14645 9.64645ZM10.5 8L10.8536 8.35355L11.2071 8L10.8536 7.64645L10.5 8ZM8.85355 5.64645L8.5 5.29289L7.79289 6L8.14645 6.35355L8.85355 5.64645ZM8.85355 10.3536L10.8536 8.35355L10.1464 7.64645L8.14645 9.64645L8.85355 10.3536ZM10.8536 7.64645L8.85355 5.64645L8.14645 6.35355L10.1464 8.35355L10.8536 7.64645ZM10.5 7.5H4V8.5H10.5V7.5ZM7.5 14.5C3.91015 14.5 1 11.5899 1 8H0C0 12.1421 3.35786 15.5 7.5 15.5V14.5ZM14 8C14 11.5899 11.0899 14.5 7.5 14.5V15.5C11.6421 15.5 15 12.1421 15 8H14ZM7.5 1.5C11.0899 1.5 14 4.41015 14 8H15C15 3.85786 11.6421 0.5 7.5 0.5V1.5ZM7.5 0.5C3.35786 0.5 0 3.85786 0 8H1C1 4.41015 3.91015 1.5 7.5 1.5V0.5Z"
        fill="#666666"
      />
    </svg>
  );
}
