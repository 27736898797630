import React from "react";

export default function ImageVideoSvg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.79987 3.73345L5.15342 3.37989C4.95816 3.18463 4.64158 3.18463 4.44632 3.37989L4.79987 3.73345ZM1.59987 1.03345H14.3999V0.0334473H1.59987V1.03345ZM14.9665 1.60011V14.4001H15.9665V1.60011H14.9665ZM14.3999 14.9668H1.59987V15.9668H14.3999V14.9668ZM1.0332 14.4001V1.60011H0.0332031V14.4001H1.0332ZM1.59987 14.9668C1.28691 14.9668 1.0332 14.7131 1.0332 14.4001H0.0332031C0.0332031 15.2654 0.734624 15.9668 1.59987 15.9668V14.9668ZM14.9665 14.4001C14.9665 14.7131 14.7128 14.9668 14.3999 14.9668V15.9668C15.2651 15.9668 15.9665 15.2654 15.9665 14.4001H14.9665ZM14.3999 1.03345C14.7128 1.03345 14.9665 1.28715 14.9665 1.60011H15.9665C15.9665 0.734868 15.2651 0.0334473 14.3999 0.0334473V1.03345ZM1.59987 0.0334473C0.734623 0.0334473 0.0332031 0.734868 0.0332031 1.60011H1.0332C1.0332 1.28715 1.28691 1.03345 1.59987 1.03345V0.0334473ZM0.533203 11.7001H15.4665V10.7001H0.533203V11.7001ZM0.886757 8.35367L5.15342 4.087L4.44632 3.37989L0.17965 7.64656L0.886757 8.35367ZM4.44632 4.087L11.913 11.5537L12.6201 10.8466L5.15342 3.37989L4.44632 4.087ZM11.1999 5.36678C10.8869 5.36678 10.6332 5.11307 10.6332 4.80011H9.6332C9.6332 5.66536 10.3346 6.36678 11.1999 6.36678V5.36678ZM11.7665 4.80011C11.7665 5.11307 11.5128 5.36678 11.1999 5.36678V6.36678C12.0651 6.36678 12.7665 5.66536 12.7665 4.80011H11.7665ZM11.1999 4.23345C11.5128 4.23345 11.7665 4.48715 11.7665 4.80011H12.7665C12.7665 3.93487 12.0651 3.23345 11.1999 3.23345V4.23345ZM11.1999 3.23345C10.3346 3.23345 9.6332 3.93487 9.6332 4.80011H10.6332C10.6332 4.48715 10.8869 4.23345 11.1999 4.23345V3.23345Z"
        fill="currentColor"
      />
    </svg>
  );
}
