import React from "react";

export default function CountrySvg() {
  return (
    <svg
      width="11"
      height="16"
      viewBox="0 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 7L10.7236 7.44721C10.8868 7.3656 10.9926 7.20154 10.9996 7.01919C11.0066 6.83683 10.9137 6.66514 10.7572 6.57125L10.5 7ZM0.5 1L0.757248 0.571254C0.602783 0.478574 0.410405 0.476147 0.253651 0.5649C0.0968963 0.653653 0 0.819864 0 1H0.5ZM10.7572 6.57125L0.757248 0.571254L0.242752 1.42875L10.2428 7.42875L10.7572 6.57125ZM0 1V12H1V1H0ZM0.723607 12.4472L10.7236 7.44721L10.2764 6.55279L0.276393 11.5528L0.723607 12.4472ZM1 15.5V12H0V15.5H1Z"
        fill="#666666"
      />
    </svg>
  );
}
