// json
import common_en from 'translation/en/common.json';
import inventory_en from 'translation/en/inventory.json';
import signin_en from 'translation/en/signin.json';
import signup_en from 'translation/en/signup.json';
import verify_phone_en from 'translation/en/verifyphone.json';
import approve_email_en from 'translation/en/approveEmail.json';
import email_verify_en from 'translation/en/emailVerify.json';
import order_en from 'translation/en/order.json';
import category_en from 'translation/en/category.json';
import finance_en from 'translation/en/finance.json';
import user_en from 'translation/en/user.json';
import breadcrumb_en from 'translation/en/breadcrumb.json';
import status_en from 'translation/en/status.json';
import forgot_password_en from 'translation/en/forgotPassword.json';
import profile_en from 'translation/en/profile.json';
import marketplace_en from 'translation/en/marketplace.json';
import purchase_en from 'translation/en/purchase.json';
import error_en from 'translation/en/error.json';
import store_en from 'translation/en/store.json';
import chat_en from 'translation/en/chat.json';
import platform_fee_report_en from 'translation/en/platformFeeReport.json';

const translation = {
  common: common_en,
  inventory: inventory_en,
  signin: signin_en,
  signup: signup_en,
  verifyPhone: verify_phone_en,
  approveEmail: approve_email_en,
  emailVerify: email_verify_en,
  order: order_en,
  finance: finance_en,
  user: user_en,
  breadcrumb: breadcrumb_en,
  status: status_en,
  category: category_en,
  forgotPassword: forgot_password_en,
  profile: profile_en,
  marketplace: marketplace_en,
  purchase: purchase_en,
  error: error_en,
  store: store_en,
  chat: chat_en,
  platformFeeReport: platform_fee_report_en,
};

export default translation;
