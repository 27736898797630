import { createReducer } from "reduxsauce";
import { Types } from "./actions";
import { PAGINATION } from "./../../consts/Enum";

const INITIAL_STATE = {
  isFetching: false,
  requestList: [],
  params: {
    page: PAGINATION.page,
    recordPerPage: PAGINATION.recordPerPage
  },
  requestDetail: {},
  isFechingAction: false,
  isFetchingStatus: false
};

export const requestListAction = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: true
});

export const requestListSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  params: { ...state.params, ...action.data.params },
  requestList: action.data.data
});

export const requestDetailAction = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: true
});

export const requestDetailSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  requestDetail: action.data
});

export const requestFinanceUpdateStatusAction = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  isFetchingStatus: true
});

export const requestFinanceUpdateStatusSuccess = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  isFetchingStatus: false
});

export const requestFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isFetchingStatus: false,
  isFechingAction: false
});

export const createFinanceAction = (state = INITIAL_STATE, action) => ({
  ...state,
  isFechingAction: true
});

export const createFinanceSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFechingAction: false
});

export const HANDLERS = {
  [Types.REQUEST_LIST_ACTION]: requestListAction,
  [Types.REQUEST_LIST_SUCCESS]: requestListSuccess,
  [Types.REQUEST_DETAIL_ACTION]: requestDetailAction,
  [Types.REQUEST_DETAIL_SUCCESS]: requestDetailSuccess,
  [Types.REQUEST_FINANCE_UPDATE_STATUS_ACTION]:
    requestFinanceUpdateStatusAction,
  [Types.REQUEST_FINANCE_UPDATE_STATUS_SUCCESS]:
    requestFinanceUpdateStatusSuccess,
  [Types.CREATE_FINANCE_ACTION]: createFinanceAction,
  [Types.CREATE_FINANCE_SUCCESS]: createFinanceSuccess,
  [Types.REQUEST_FAILURE]: requestFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
