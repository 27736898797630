import {
  call,
  put,
  takeLatest,
  fork,
  all,
  delay,
  takeLeading
} from "redux-saga/effects";
import { AuthService } from "../../service";
import {
  sagaErrorHandler,
  sagaSuccessMessage,
  sagaWrapper
} from "../../utils/common";
import AuthorizationAction, { Types } from "./actions";
import storage from "../../utils/localStorage";
import { isEmpty, last } from "lodash";
import Urls from "consts/Urls";

function* signUp() {
  yield takeLatest(
    Types.SIGN_UP_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(AuthService.SIGN_UP, action.params);
      delay(1000);
      sagaSuccessMessage({
        action,
        successMessage: "Create account success",
        description: result?.data?.email
      });
      yield put(AuthorizationAction.signUpSuccess());
      action.callback && action.callback();
    }, errorHandle(Types.AUTHORIZATION_FAILURE))
  );
}
function* changePassword() {
  yield takeLatest(
    Types.CHANGE_PASSWORD_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(AuthService.CHANGE_PASSWORD, action.params);
      sagaSuccessMessage({
        action,
        successMessage: "Đổi mật khẩu thành công",
        description: result?.data?.email
      });
      yield put(AuthorizationAction.changePasswordSuccess());
      action?.callback && action.callback();
    }, errorHandle(Types.AUTHORIZATION_FAILURE))
  );
}

function* getCode() {
  yield takeLeading(
    Types.GET_CODE_ACTION,
    sagaWrapper(function* (action) {
      yield call(AuthService.GET_CODE, action.params);
      yield put(AuthorizationAction.changePasswordSuccess());
      action?.callback && action.callback();
    }, errorHandle(Types.AUTHORIZATION_FAILURE))
  );
}

function* getLink() {
  yield takeLeading(
    Types.GET_LINK_ACTION,
    sagaWrapper(function* (action) {
      yield call(AuthService.GET_LINK, action.params);
      yield put(AuthorizationAction.changePasswordSuccess());
      action?.callback && action.callback();
    }, errorHandle(Types.AUTHORIZATION_FAILURE))
  );
}

function* resetPassWithCode() {
  yield takeLatest(
    Types.RESET_PASS_WITH_CODE_ACTION,
    sagaWrapper(function* (action) {
      yield call(AuthService.RESET_PASS_WITH_CODE, action.params);
      sagaSuccessMessage({
        action,
        successMessage: "Reset password success"
      });
      yield put(AuthorizationAction.changePasswordSuccess());
      action?.callback && action.callback();
    }, errorHandle(Types.AUTHORIZATION_FAILURE))
  );
}

function* resetPassWithLink() {
  yield takeLatest(
    Types.RESET_PASS_WITH_LINK_ACTION,
    sagaWrapper(function* (action) {
      yield call(AuthService.RESET_PASS_WITH_LINK, action.params);
      sagaSuccessMessage({
        action,
        successMessage: "Reset password success"
      });
      yield put(AuthorizationAction.changePasswordSuccess());
      action?.callback && action.callback();
    }, errorHandle(Types.AUTHORIZATION_FAILURE))
  );
}

function* signIn() {
  yield takeLatest(
    Types.SIGN_IN_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(AuthService.SIGN_IN, action.params);
      delay(1000);
      sagaSuccessMessage({
        action,
        successMessage: "Đăng nhập thành công",
        description: action.params.email
      });
      yield storage.setAccessToken(result?.data?.user?.token);
      yield storage.setEmailOrPhoneSigin(result?.data?.user?.email);
      yield storage.setCompanyName(result?.data?.user?.companyName || result?.data?.user?.storeName);
      if (isEmpty(result?.data?.user?.avatar)) {
        result.data.user.avatarDefault = "";
      } else {
        result.result.user.avatarDefault =
          Urls.URL_FILE + last(result?.data?.user?.avatar)?.path?.origin;
      }
      yield storage.setAvatar(result.data.user.avatarDefault);
      yield put(AuthorizationAction.signUpSuccess());
      action.callback &&
        action.callback(
          result.data.user.userRole || "dealer",
          result.data.user.merchantCode,
          result.data.user.code,
          {
            nationality: result?.data?.user?.nationality,
            phone: result?.data?.user?.phone,
            address: result?.data?.user?.address,
            phoneCode: result?.data?.user?.phoneCode
          }
        );
    }, errorHandle(Types.AUTHORIZATION_FAILURE))
  );
}

function* signUpDealer() {
  yield takeLatest(
    Types.SIGN_UP_DEALER_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(AuthService.SIGN_UP_DEALER, action.params);
      delay(1000);
      sagaSuccessMessage({
        action,
        successMessage: "Tạo tài khoản thành công",
        description: `${result?.data?.phoneCode} ${result?.data?.phone}`
      });
      yield put(AuthorizationAction.signUpDealerSuccess());
      action.callback && action.callback();
    }, errorHandle(Types.AUTHORIZATION_FAILURE))
  );
}

function* signInDealer() {
  yield takeLatest(
    Types.SIGN_IN_DEALER_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(AuthService.SIGN_IN_DEALER, action.params);
      delay(1000);
      sagaSuccessMessage({
        action,
        successMessage: "Đăng nhập thành công",
        description: action.params.phone
      });
      yield storage.setAccessToken(result?.data?.user?.token);
      yield storage.setEmailOrPhoneSigin(result?.data?.user?.phone);
      yield storage.setCompanyName(result?.data?.user?.companyName);
      yield storage.setPhoneCodeSigin(result?.data?.user?.phoneCode);
      if (isEmpty(result?.data?.user?.avatar)) {
        result.data.user.avatarDefault = "";
      } else {
        result.result.user.avatarDefault =
          Urls.URL_FILE + last(result?.data?.user?.avatar)?.path?.origin;
      }
      yield storage.setAvatar(result.data.user.avatarDefault);
      yield put(AuthorizationAction.signInDealerSuccess());

      action.callback &&
        action.callback(
          result.data.user.userRole || "dealer",
          result.data.user.merchantCode,
          result.data.user.code,
          {
            nationality: result?.data?.user?.nationality,
            phone: result?.data?.user?.phone,
            address: result?.data?.user?.address,
            phoneCode: result?.data?.user?.phoneCode
          }
        );
    }, errorHandle(Types.AUTHORIZATION_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return sagaErrorHandler(function* (e, action) {
    console.warn("SAP SYSTEM ERROR:", e);
    yield put({ type: errorActionType, sessionId: action?.sessionId });
  });
}

export default function* rootSaga() {
  yield all([
    fork(signUp),
    fork(signIn),
    fork(changePassword),
    fork(signUpDealer),
    fork(signInDealer),
    fork(getCode),
    fork(getLink),
    fork(resetPassWithCode),
    fork(resetPassWithLink)
  ]);
}
