import React from "react";

export default function CostEstimateSvg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.79961 7.50011H4.29961V8.50011H4.79961V7.50011ZM11.1996 8.50011H11.6996V7.50011H11.1996V8.50011ZM9.06628 10.7001H8.56628V11.7001H9.06628V10.7001ZM11.1996 11.7001H11.6996V10.7001H11.1996V11.7001ZM4.79961 4.30011H4.29961V5.30011H4.79961V4.30011ZM6.93294 5.30011H7.43294V4.30011H6.93294V5.30011ZM11.1996 0.533447L11.5532 0.179894L11.4067 0.0334473H11.1996V0.533447ZM14.3996 3.73345H14.8996V3.52634L14.7532 3.37989L14.3996 3.73345ZM4.79961 8.50011H11.1996V7.50011H4.79961V8.50011ZM9.06628 11.7001H11.1996V10.7001H9.06628V11.7001ZM4.79961 5.30011H6.93294V4.30011H4.79961V5.30011ZM13.3329 14.9668H2.66628V15.9668H13.3329V14.9668ZM2.09961 14.4001V1.60011H1.09961V14.4001H2.09961ZM2.66628 1.03345H11.1996V0.0334473H2.66628V1.03345ZM13.8996 3.73345V14.4001H14.8996V3.73345H13.8996ZM10.8461 0.887001L14.0461 4.087L14.7532 3.37989L11.5532 0.179894L10.8461 0.887001ZM2.66628 14.9668C2.35331 14.9668 2.09961 14.7131 2.09961 14.4001H1.09961C1.09961 15.2654 1.80103 15.9668 2.66628 15.9668V14.9668ZM13.3329 15.9668C14.1982 15.9668 14.8996 15.2654 14.8996 14.4001H13.8996C13.8996 14.7131 13.6459 14.9668 13.3329 14.9668V15.9668ZM2.09961 1.60011C2.09961 1.28715 2.35331 1.03345 2.66628 1.03345V0.0334473C1.80103 0.0334473 1.09961 0.734868 1.09961 1.60011H2.09961Z"
        fill="currentColor"
      />
    </svg>
  );
}
