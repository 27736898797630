import React from "react";

export default function StoreAddressSvg() {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 12H4V12.5H4.5V12ZM9.5 12V12.5H10V12H9.5ZM5 12V11.4999H4V12H5ZM9 11.5V12H10V11.5H9ZM9.5 11.5H4.5V12.5H9.5V11.5ZM7 9.5C8.10457 9.5 9 10.3954 9 11.5H10C10 9.84315 8.65685 8.5 7 8.5V9.5ZM5 11.4999C5 10.3953 5.8954 9.5 7 9.5V8.5C5.34318 8.5 4 9.843 4 11.4999H5ZM7 3.5C5.89543 3.5 5 4.39543 5 5.5H6C6 4.94772 6.44772 4.5 7 4.5V3.5ZM9 5.5C9 4.39543 8.10457 3.5 7 3.5V4.5C7.55228 4.5 8 4.94772 8 5.5H9ZM7 7.5C8.10457 7.5 9 6.60457 9 5.5H8C8 6.05228 7.55228 6.5 7 6.5V7.5ZM7 6.5C6.44772 6.5 6 6.05228 6 5.5H5C5 6.60457 5.89543 7.5 7 7.5V6.5ZM2.5 1.5H11.5V0.5H2.5V1.5ZM12 2V14H13V2H12ZM11.5 14.5H2.5V15.5H11.5V14.5ZM2 14V2H1V14H2ZM2.5 14.5C2.22386 14.5 2 14.2761 2 14H1C1 14.8284 1.67157 15.5 2.5 15.5V14.5ZM12 14C12 14.2761 11.7761 14.5 11.5 14.5V15.5C12.3284 15.5 13 14.8284 13 14H12ZM11.5 1.5C11.7761 1.5 12 1.72386 12 2H13C13 1.17157 12.3284 0.5 11.5 0.5V1.5ZM2.5 0.5C1.67157 0.5 1 1.17157 1 2H2C2 1.72386 2.22386 1.5 2.5 1.5V0.5ZM3 4.5H0V5.5H3V4.5ZM3 10.5H0V11.5H3V10.5Z"
        fill="#666666"
      />
    </svg>
  );
}
