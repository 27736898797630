import { TYPE_FILE } from "consts/Enum";
import Urls from "consts/Urls";
import { isEmpty, last } from "lodash";
import { call, put, takeLatest, fork, all, delay } from "redux-saga/effects";
import storage from "utils/localStorage";
import { UserService } from "../../service";
import {
  parseDateColumnTable,
  promiseUpload,
  sagaErrorHandler,
  sagaSuccessMessage,
  sagaWrapper,
  statusDeadler
} from "../../utils/common";
import UserAction, { Types } from "./actions";

function* getUserList() {
  yield takeLatest(
    Types.USER_LIST_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(UserService.LIST_USER_ADIM, action.params);
      const data = result.data.data.map((item) => {
        const dealerName = {
          email: item?.email || `${item?.phoneCode} ${item?.phone}`,
          avatar: item?.avatar,
          name: item?.merchant?.contactName
        };
        const buyers = {
          email: item?.email || `${item?.phoneCode} ${item?.phone}`,
          avatar: item?.avatar,
          name: item?.fullname || item?.companyName
        };
        const verify = parseDateColumnTable(
          item?.emailVerify || item?.phoneVerify
        );
        const companyName = item?.companyName;
        const dateRegistered = parseDateColumnTable(item?.createdAt);
        const lastVisited = "-";
        const status = statusDeadler(item.merchant);
        const inventory = item.carInventory;
        const totalIncome = "";
        const totalCharged = "";
        const id = item.code;
        const blockAt = item?.blockAt || item?.merchant?.blockAt;
        return {
          ...item,
          buyers,
          companyName,
          dealerName,
          dateRegistered,
          lastVisited,
          status,
          totalIncome,
          inventory,
          totalCharged,
          id,
          verify,
          blockAt
        };
      });
      const params = {
        page: result.data?.metadata[0]?.pageCurrent || 1,
        recordTotal: result?.data.metadata[0]?.recordTotal || 10,
        pageTotal:
          (result.data.metadata[0]?.recordTotal /
            result.data.metadata[0]?.recordPerPage) *
          10 || 1,
        recordPerPage: result.data.metadata[0]?.recordPerPage || 10,
        filter: {
          ...action.params.filter
        }
      };
      const newResult = Object.assign(result, { data, params });
      yield put(UserAction.userListSuccess(newResult));
    }, errorHandle(Types.USER_FAILURE))
  );
}

function* getUserDetail() {
  yield takeLatest(
    Types.USER_DETAIL_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(UserService.DETAIL_USER, action.userCode);
      result.result.user.fullName = result?.result?.user?.name
        ? result?.result?.user?.firstName +
        " " +
        result?.result?.user?.lastName +
        " " +
        result?.result?.user?.name
        : "";
      if (isEmpty(result?.result?.avatar)) {
        result.result.user.avatarDefault = "";
        result.result.user.avatar = [];
      } else {
        result.result.user.avatar = [last(result?.result?.avatar)];
        result.result.user.avatarDefault =
          Urls.URL_FILE + last(result?.result?.avatar)?.path?.origin;
      }
      yield storage.setAvatar(result.result.user.avatarDefault);
      yield put(UserAction.userDetailSuccess(result.result.user));
    }, errorHandle(Types.USER_FAILURE))
  );
}

function* updateUser() {
  yield takeLatest(
    Types.USER_UPDATE_ACTION,
    sagaWrapper(function* (action) {
      const oldAvatar = action.params.avatar;
      delete action.params.avatar;
      const result = yield call(UserService.UPDATE_USER, action.params);
      if (!isEmpty(oldAvatar)) {
        const imagesLocal = oldAvatar.filter((el) => el.originFileObj);
        yield promiseUpload(
          result?.data?.user?.code,
          TYPE_FILE.avatar,
          imagesLocal
        );
      }
      delay(1000);
      sagaSuccessMessage({
        action,
        successMessage: "Cập nhật thành công",
        description: action.params.companyName
      });
      storage.setCompanyName(action.params.companyName);
      yield put(UserAction.userUpdateSuccess(result?.data?.user ?? {}));
      action.callback && action.callback();
    }, errorHandle(Types.USER_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return sagaErrorHandler(function* (e, action) {
    console.warn("SAP SYSTEM ERROR:", e);
    yield put({ type: errorActionType, sessionId: action?.sessionId });
  });
}

export default function* rootSaga() {
  yield all([fork(getUserList), fork(getUserDetail), fork(updateUser)]);
}
