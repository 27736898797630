import React from "react";

export default function CompanyRegistrationNoSvg() {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1V0.5H1V1H1.5ZM11.5 1H12V0.5H11.5V1ZM3.94712 5.2238C4.07072 4.97686 3.97073 4.67648 3.7238 4.55288C3.47686 4.42928 3.17648 4.52927 3.05288 4.7762L3.94712 5.2238ZM1.50049 8.99475L1.05337 8.77095L0.906883 9.06361L1.11648 9.31497L1.50049 8.99475ZM6.50049 14.9908L6.11648 15.3111C6.21148 15.425 6.35215 15.4908 6.50049 15.4908C6.64882 15.4908 6.7895 15.425 6.8845 15.3111L6.50049 14.9908ZM11.5005 8.99475L11.8845 9.31497L12.0941 9.06356L11.9476 8.77087L11.5005 8.99475ZM9.94707 4.77611C9.82343 4.5292 9.52303 4.42928 9.27611 4.55293C9.0292 4.67657 8.92928 4.97697 9.05293 5.22389L9.94707 4.77611ZM7 9.99409L6.99995 9.49409L5.99995 9.49419L6 9.99419L7 9.99409ZM6.5 5.4967C4.01383 5.4967 2 3.48375 2 1H1C1 4.03665 3.46217 6.4967 6.5 6.4967V5.4967ZM1.5 1.5H11.5V0.5H1.5V1.5ZM11 1C11 3.48375 8.98617 5.4967 6.5 5.4967V6.4967C9.53783 6.4967 12 4.03665 12 1H11ZM3.05288 4.7762L1.05337 8.77095L1.94761 9.21855L3.94712 5.2238L3.05288 4.7762ZM1.11648 9.31497L6.11648 15.3111L6.8845 14.6706L1.8845 8.67454L1.11648 9.31497ZM6.8845 15.3111L11.8845 9.31497L11.1165 8.67454L6.11648 14.6706L6.8845 15.3111ZM11.9476 8.77087L9.94707 4.77611L9.05293 5.22389L11.0534 9.21864L11.9476 8.77087ZM7.00049 14.9908L7 9.99409L6 9.99419L6.00049 14.9909L7.00049 14.9908Z"
        fill="#666666"
      />
    </svg>
  );
}
