import React from "react";

export default function Mp4Svg() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.53359 7.46667H7.00026C7.25799 7.46667 7.46693 7.25773 7.46693 7C7.46693 6.74227 7.25799 6.53333 7.00026 6.53333H6.53359V7.46667Z"
        fill="#A5A3A9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.933594 1.4C0.933594 0.626801 1.5604 0 2.33359 0H9.99356L13.0669 3.07337V12.6C13.0669 13.3732 12.4401 14 11.6669 14H2.33359C1.56039 14 0.933594 13.3732 0.933594 12.6V1.4ZM5.60026 5.6H7.00026C7.77346 5.6 8.40026 6.2268 8.40026 7C8.40026 7.7732 7.77346 8.4 7.00026 8.4H6.53359V10.2667H5.60026V5.6ZM2.15501 5.63554C2.32939 5.56331 2.53011 5.60323 2.66358 5.7367L3.26693 6.34005L3.87028 5.7367C4.00374 5.60323 4.20446 5.56331 4.37885 5.63554C4.55323 5.70777 4.66693 5.87793 4.66693 6.06668V10.2667H3.73359V7.19331L3.26693 7.65998L2.80026 7.19331V10.2667H1.86693V6.06668C1.86693 5.87793 1.98063 5.70777 2.15501 5.63554ZM10.2669 5.6H9.33359V8.4H11.2003V10.2667H12.1336V5.6H11.2003V7.46667H10.2669V5.6Z"
        fill="#A5A3A9"
      />
    </svg>
  );
}
