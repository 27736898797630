import React from "react";

export default function LockSvg() {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 6.99756V3.99756C3.5 2.3407 4.84315 0.997559 6.5 0.997559C8.15685 0.997559 9.5 2.3407 9.5 3.99756V6.99756M1.5 6.99756H11.5C12.0523 6.99756 12.5 7.44527 12.5 7.99756V13.9976C12.5 14.5498 12.0523 14.9976 11.5 14.9976H1.5C0.947715 14.9976 0.5 14.5498 0.5 13.9976V7.99756C0.5 7.44527 0.947715 6.99756 1.5 6.99756Z"
        stroke="#666666"
      />
    </svg>
  );
}
