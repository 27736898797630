import language from "@iso/config/language.config";

import { EnglishFlag, IndonesiaFlag } from "../../assets/images/blimobil";
import storage from "../../utils/localStorage";

console.log(storage.getLanguage());
const config = {
  defaultLanguage: storage.getLanguage(),
  options: [
    {
      languageId: "vietnam",
      locale: "vi",
      text: "Vietnam",
      icon: IndonesiaFlag,
    },
    {
      languageId: "english",
      locale: "en",
      text: "English",
      icon: EnglishFlag,
    },
  ],
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = {};
  config.options.forEach((language) => {
    if (language.locale === lang) {
      selecetedLanguage = language;
    }
  });
  storage.setLanguage(selecetedLanguage.locale);
  return selecetedLanguage;
}
export default config;
