import React from "react";

export default function CartSvg() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.80518 0.475098L0.528076 0.858228L4.67061 14.6667H20.5V6C20.5 4.15905 19.0076 2.66666 17.1666 2.66666H2.46265L1.80518 0.475098Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.83342 16C6.72885 16 5.83342 16.8954 5.83342 18C5.83342 19.1046 6.72885 20 7.83342 20C8.93798 20 9.83342 19.1046 9.83342 18C9.83342 16.8954 8.93798 16 7.83342 16ZM7.16675 18C7.16675 17.6318 7.46523 17.3333 7.83342 17.3333C8.2016 17.3333 8.50008 17.6318 8.50008 18C8.50008 18.3682 8.2016 18.6667 7.83342 18.6667C7.46523 18.6667 7.16675 18.3682 7.16675 18Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1667 16C16.0622 16 15.1667 16.8954 15.1667 18C15.1667 19.1046 16.0622 20 17.1667 20C18.2713 20 19.1667 19.1046 19.1667 18C19.1667 16.8954 18.2713 16 17.1667 16ZM16.5001 18C16.5001 17.6318 16.7986 17.3333 17.1667 17.3333C17.5349 17.3333 17.8334 17.6318 17.8334 18C17.8334 18.3682 17.5349 18.6667 17.1667 18.6667C16.7986 18.6667 16.5001 18.3682 16.5001 18Z"
        fill="white"
      />
    </svg>
  );
}
