import Urls from "../consts/Urls";
import { apiMarketplace, removeKeyOjectValueEmpty } from "../utils/common";
import axiosClient from "./axios";

const MarketplaceService = {
  LIST_MARKETPLACE: async (params) => {
    delete params.recordTotal;
    delete params.pageTotal;
    const newPamrams = removeKeyOjectValueEmpty(params);
    const url = apiMarketplace();
    return await axiosClient.get(url, { params: newPamrams });
  },
  CREATE_MARKETPLACE_ORDER: async (params) => {
    // delete params.photos;
    if (params.paymentOption === "1") {
      delete params.financeCode;
    }
    return await axiosClient.post(`${Urls.CREATE_ORDER_MARKETPLACE}`, params);
  },
  INFO_DEALER_AS_BUYER: async (codeCar) => {
    return await axiosClient.get(`${Urls.INFO_DEALER_AS_BUYER}/${codeCar}`);
  }
};

export default MarketplaceService;
