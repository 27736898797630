import { createReducer } from 'reduxsauce';
import { PAGINATION } from 'consts/Enum';
import { Types } from './actions';

const INITIAL_STATE = {
  isFetching: false,
  reportList: [],
  params: {
    page: PAGINATION.page,
    recordPerPage: PAGINATION.recordPerPage,
  },
};

export const reportListAction = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: true,
  };
};

export const reportListSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  params: { ...state.params, ...action.data.params },
  reportList: action.data.data.data,
});

export const invoiceListAction = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: true,
  };
};

export const invoiceListSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  params: { ...state.params, ...action.data.params },
  reportList: action.data.data.data,
});

export const reportFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
});

export const HANDLERS = {
  [Types.REPORT_LIST_ACTION]: reportListAction,
  [Types.REPORT_LIST_SUCCESS]: reportListSuccess,
  [Types.INVOICE_LIST_ACTION]: invoiceListAction,
  [Types.INVOICE_LIST_SUCCESS]: invoiceListSuccess,
  [Types.REPORT_FAILURE]: reportFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
