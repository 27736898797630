import React from "react";

export default function OrderDetailMarketplaceSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0.666748V0.166748C1.72386 0.166748 1.5 0.390606 1.5 0.666748L2 0.666748ZM18 0.666748H18.5C18.5 0.390606 18.2761 0.166748 18 0.166748V0.666748ZM18 19.3334L17.7764 19.7806C17.9314 19.8581 18.1155 19.8498 18.2629 19.7587C18.4103 19.6676 18.5 19.5067 18.5 19.3334H18ZM15.3333 18.0001L15.5569 17.5529C15.4162 17.4825 15.2505 17.4825 15.1097 17.5529L15.3333 18.0001ZM12.6667 19.3334L12.4431 19.7806C12.5838 19.851 12.7495 19.851 12.8903 19.7806L12.6667 19.3334ZM10 18.0001L10.2236 17.5529C10.0828 17.4825 9.91716 17.4825 9.77639 17.5529L10 18.0001ZM7.33333 19.3334L7.10973 19.7806C7.25049 19.851 7.41618 19.851 7.55694 19.7806L7.33333 19.3334ZM2 19.3334H1.5C1.5 19.5067 1.58973 19.6676 1.73713 19.7587C1.88454 19.8498 2.06861 19.8581 2.22361 19.7806L2 19.3334ZM4.66667 18.0001L4.89027 17.5529C4.74951 17.4825 4.58382 17.4825 4.44306 17.5529L4.66667 18.0001ZM2 1.16675H18V0.166748H2V1.16675ZM17.5 0.666748V19.3334H18.5V0.666748H17.5ZM18.2236 18.8862L15.5569 17.5529L15.1097 18.4473L17.7764 19.7806L18.2236 18.8862ZM15.1097 17.5529L12.4431 18.8862L12.8903 19.7806L15.5569 18.4473L15.1097 17.5529ZM12.8903 18.8862L10.2236 17.5529L9.77639 18.4473L12.4431 19.7806L12.8903 18.8862ZM9.77639 17.5529L7.10973 18.8862L7.55694 19.7806L10.2236 18.4473L9.77639 17.5529ZM2.5 19.3334V0.666748H1.5V19.3334H2.5ZM7.55694 18.8862L4.89027 17.5529L4.44306 18.4473L7.10973 19.7806L7.55694 18.8862ZM4.44306 17.5529L1.77639 18.8862L2.22361 19.7806L4.89027 18.4473L4.44306 17.5529ZM5.33333 6.50008H8V5.50008H5.33333V6.50008ZM10.6667 6.50008H14.6667V5.50008H10.6667V6.50008ZM5.33333 10.5001H8V9.50008H5.33333V10.5001ZM10.6667 10.5001H14.6667V9.50008H10.6667V10.5001ZM10.6667 14.5001H14.6667V13.5001H10.6667V14.5001Z"
        fill="#666666"
      />
    </svg>
  );
}
