const storage = {
  setValueIntoKey(key, value) {
    localStorage.setItem(key, value);
  },
  getValueFromKey(key) {
    return localStorage.getItem(key);
  },
  setObjectIntoKey(key, obj) {
    localStorage.setItem(key, JSON.stringify(obj));
  },
  getObjectFromKey(key) {
    return JSON.parse(localStorage.getItem(key) || "{}");
  },
  setAccessToken(token) {
    this.setValueIntoKey("token", token);
  },
  setCDNAccessToken(cdnToken) {
    this.setValueIntoKey("cdnToken", cdnToken);
  },
  setRefreshToken(refreshToken) {
    this.setValueIntoKey("refreshToken", refreshToken);
  },
  setIdToken(idToken) {
    this.setValueIntoKey("id_token", idToken);
  },
  getAccessToken() {
    const token = this.getValueFromKey("token");
    if (!token) return null;
    return token;
  },
  getIdToken() {
    const idToken = this.getValueFromKey("id_token");
    if (!idToken) return null;
    return idToken;
  },
  setEmailOrPhoneSigin(email) {
    this.setValueIntoKey("email_phone", email);
  },
  getEmailOrPhoneSigin() {
    const emailPhone = this.getValueFromKey("email_phone");
    if (!emailPhone) return null;
    return this.getValueFromKey("email_phone");
  },
  removeEmailOrPhoneSigin() {
    const emailPhone = this.getEmailOrPhoneSigin();
    if (!emailPhone) return null;
    localStorage.removeItem("email_phone");
  },
  getPhoneCodeSigin() {
    const phoneCode = this.getValueFromKey("phoneCode");
    if (!phoneCode) return null;
    return this.getValueFromKey("phoneCode");
  },
  setPhoneCodeSigin(phoneCode) {
    this.setValueIntoKey("phoneCode", phoneCode);
  },
  removePhoneCodeSigin() {
    const phoneCode = this.getPhoneCodeSigin();
    if (!phoneCode) return null;
    localStorage.removeItem("phoneCode");
  },
  setCompanyName(companyName) {
    this.setValueIntoKey("companyName", companyName);
  },
  getCompanyName() {
    return this.getValueFromKey("companyName");
  },
  setAvatar(avatar) {
    this.setValueIntoKey("avatar", avatar);
  },
  getAvatar() {
    return this.getValueFromKey("avatar");
  },
  removeAvatar() {
    localStorage.removeItem("avatar");
  },
  getLanguage() {
    return this.getValueFromKey("lang") || "vi";
  },
  setLanguage(lang) {
    if (!lang) return "vi";
    return this.setValueIntoKey("lang", lang);
  },
  getRefreshToken() {
    const token = this.getValueFromKey("refreshToken");
    if (!token) return "";
    return token;
  },
  getIdStore() {
    const idStore = this.getValueFromKey("id_store");
    if (!idStore) return "";
    return idStore;
  },
  getPlatformFee() {
    const platformFee = this.getValueFromKey("platformFee");
    if (!platformFee) return "";
    return platformFee;
  },
  setPlatformFee(platformFee) {
    this.setValueIntoKey("platformFee", platformFee);
  },
  getIdUser() {
    const idUser = this.getValueFromKey("id_user");
    if (!idUser) return "";
    return idUser;
  },
  removeRefreshToken() {
    const token = this.getRefreshToken();
    if (!token) return null;
    localStorage.removeItem("refreshToken");
  },
  removeIdToken() {
    const idToken = this.getIdToken();
    if (!idToken) return null;
    localStorage.removeItem("idToken");
  },
  removeAccessToken() {
    const token = this.getAccessToken();
    if (!token) return null;
    localStorage.removeItem("token");
  },
  removeLanguage() {
    localStorage.removeItem("lang");
  },
  setDraftArticle(value) {
    this.setObjectIntoKey("article", value);
  },
};

export default storage;
