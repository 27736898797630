import { call, put, takeLatest, fork, all } from 'redux-saga/effects';

import { CategoryService } from 'service';
import CategoryAction, { Types } from './actions';
import { sagaErrorHandler, sagaWrapper } from 'utils/common';

function* getCategoryList() {
  yield takeLatest(
    Types.CATEGORY_LIST_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(CategoryService.CATEGORY_LIST, action.params);
      const params = {
        page: result?.pageCurrent || 1,
        recordTotal: result?.recordTotal || 10,
        pageTotal:
          (result?.recordTotal /
            result?.recordPerPage) *
            10 || 1,
        recordPerPage: result?.recordPerPage || 10,
      };
      yield put(CategoryAction.categoryListSuccess({ data: result.data, params }));
    }, errorHandle(Types.CATEGORY_FAILURE))
  );
}

function* createCategory() {
  yield takeLatest(
    Types.CREATE_CATEGORY_ACTION,
    sagaWrapper(function* (action) {
      const { params, callback } = action;
      const result = yield call(CategoryService.CREATE_CATEGORY, params);
      yield put(CategoryAction.createCategorySuccess({...result, params}));
      callback && callback()
    }, errorHandle(Types.CATEGORY_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return sagaErrorHandler(function* (e, action) {
    yield put({ type: errorActionType, sessionId: action?.sessionId });
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCategoryList), 
    fork(createCategory)
  ]);
}
