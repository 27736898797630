import React from "react";

export default function HistorySvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33333 16.5321L3.70346 16.1959L3.69709 16.1889L3.69045 16.1821L3.33333 16.5321ZM4.66667 18.0001V18.5001H5.16667V18.0001H4.66667ZM9.33333 19.31L9.3509 18.8103L9.29813 19.8087L9.33333 19.31ZM10 10.0001H9.5C9.5 10.1327 9.55268 10.2599 9.64645 10.3536L10 10.0001ZM18.8333 10.0001C18.8333 14.8786 14.8785 18.8334 10 18.8334V19.8334C15.4308 19.8334 19.8333 15.4309 19.8333 10.0001H18.8333ZM10 1.16675C14.8785 1.16675 18.8333 5.12157 18.8333 10.0001H19.8333C19.8333 4.56928 15.4308 0.166748 10 0.166748V1.16675ZM10 0.166748C4.5692 0.166748 0.166667 4.56928 0.166667 10.0001H1.16667C1.16667 5.12157 5.12149 1.16675 10 1.16675V0.166748ZM3.69045 16.1821C2.12874 14.5884 1.16667 12.4071 1.16667 10.0001H0.166667C0.166667 12.6792 1.23876 15.109 2.97621 16.882L3.69045 16.1821ZM2.96321 16.8682L4.29654 18.3362L5.03679 17.6639L3.70346 16.1959L2.96321 16.8682ZM10 18.8334C9.7876 18.8334 9.57703 18.8259 9.36853 18.8112L9.29813 19.8087C9.53004 19.8251 9.76409 19.8334 10 19.8334V18.8334ZM10.0176 18.8337L9.3509 18.8103L9.31576 19.8097L9.98243 19.8331L10.0176 18.8337ZM9.5 4.00008V10.0001H10.5V4.00008H9.5ZM9.64645 10.3536L13.6464 14.3536L14.3536 13.6465L10.3536 9.64653L9.64645 10.3536ZM0 18.5001H4.66667V17.5001H0V18.5001ZM5.16667 18.0001V13.3334H4.16667V18.0001H5.16667Z"
        fill="#666666"
      />
    </svg>
  );
}
