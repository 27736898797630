import { createActions } from "reduxsauce";

export const {Types, Creators} = createActions(
    {
        modelListAction: ["params"],
        modelListSuccess: ["data"],

        connectBrandAction: ["params", "callback"],
        connectBrandSuccess: [],

        modelFail: []
    },
    {}
)
export default Creators;