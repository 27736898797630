import IconBlomobil from "./blomobil_logo.svg";
import IconBlomobilWhite from "./iconBlomobilWhite.svg";
import IconCarhay from "./IconCarHaySVG.svg";
import IconChat from "./chat.svg";
import IconNotification from "./notification.svg";
import IconShop from "./shop.svg";
import IconAppsNav from "./apps_nav.svg";
import IconOrderNav from "./order_nav.svg";
import IconInventoryNav from "./inventory_nav.svg";
import InventorySvg from "./InventorySvg";
import OrderSvg from "./OrderSvg";
import AppsSvg from "./AppsSvg";
import SearchSvg from "./SearchSvg";
import FilterSvg from "./FilterSvg";
import ColumnSvg from "./ColumnSvg";
import PlusSvg from "./PlusSvg";
import ImageSvg from "./ImageSvg";
import Mp4Svg from "./Mp4Svg";
import BackSvg from "./BackSvg";
import CloseSvg from "./CloseSvg";
import CostEstimateSvg from "./CostEstimateSvg";
import FeatureSvg from "./FeatureSvg";
import GoogleAdSvg from "./GoogleAdSvg";
import DollarSvg from "./DollarSvg";
import EditSvg from "./EditSvg";
import LocationSvg from "./LocationSvg";
import InfoCircleSvg from "./InfoCircleSvg";
import CheckedSvg from "./CheckedSvg";
import ImageCarousel from "./image.png";
import ImageCarousel1 from "./image1.png";
import CompetitorsExtraSvg from "./CompetitorsExtraSvg";
import ImageVideoSvg from "./ImageVideoSvg";
import CompetitorsExtra1 from "./CompetitorsExtra1.png";
import CompetitorsExtra2 from "./CompetitorsExtra2.png";
import CompetitorsExtra3 from "./CompetitorsExtra3.png";
import PublicSvg from "./PublicSvg";
import SaveSvg from "./SaveSvg";
import WirelessCharger from "./WirelessCharger.png";
import feature2 from "./feature2.png";
import feature3 from "./feature3.png";
import feature4 from "./feature4.png";
import feature5 from "./feature5.png";
import feature6 from "./feature6.png";
import feature7 from "./feature7.png";
import feature8 from "./feature8.png";
import feature9 from "./feature9.png";
import feature10 from "./feature10.png";
import feature11 from "./feature11.png";
import feature12 from "./feature12.png";
import feature13 from "./feature13.png";
import feature14 from "./feature14.png";
import feature15 from "./feature15.png";
import feature16 from "./feature16.png";
import feature17 from "./feature17.png";
import feature18 from "./feature18.png";
import cabin1 from "./cabin/cabin1.png";
import cabin2 from "./cabin/cabin2.png";
import cabin3 from "./cabin/cabin3.png";
import cabin4 from "./cabin/cabin4.png";
import cabin5 from "./cabin/cabin5.png";
import cabin6 from "./cabin/cabin6.png";
import safety1 from "./safety/safety1.png";
import safety2 from "./safety/safety2.png";
import safety3 from "./safety/safety3.png";
import safety4 from "./safety/safety4.png";
import safety5 from "./safety/safety5.png";
import safety6 from "./safety/safety6.png";
import safety7 from "./safety/safety7.png";
import lighting1 from "./lighting/lighting1.png";
import lighting2 from "./lighting/lighting2.png";
import IconColorSvg from "./IconColorSvg";
import DateSvg from "./DateSvg";
import CarSvg from "./CarSvg";
import HistorySvg from "./HistorySvg";
import ImageCarouse2 from "./image2.png";
import BlimobiGraphic from "./Blimobi-Graphic.png";
import BlimobiGraphicAuth from "./Blimobi-Graphic-Auth.png";
import IconBlomobilAuth from "./Logo.png";
import EmailSvg from "./EmailSvg";
import LockSvg from "./LockSvg";
import CompanySvg from "./CompanySvg";
import NextSvg from "./NextSvg";
import UserSvg from "./UserSvg";
import CountrySvg from "./CountrySvg";
import StoreAddressSvg from "./StoreAddressSvg";
import CompanyRegistrationNoSvg from "./CompanyRegistrationNoSvg";
import UserSliderSvg from "./UserSliderSvg";
import StoreInfoSvg from "./StoreInfoSvg";
import BankSvg from "./BankSvg";
import InfoImage from "./Info.png";
import EmptyInventorySvg from "./EmptyInventorySvg";
import MarketplaceSvg from "./MarketplaceSvg";
import ImageError from "./error.png";
import CartSvg from "./CartSvg";
import ShippingMethodSvg from "./ShippingMethodSvg";
import OrderDetailMarketplaceSvg from "./OrderDetailMarketplaceSvg";
import StopwatchSvg from "./StopwatchSvg";
import DeliveryorPickupSvg from "./DeliveryorPickupSvg";
import React from "react";
import MapBooking from "./mapBooking.png";
import IndonesiaFlag from "./indonesia-flag-icon.png";
import EnglishFlag from "./english-flag-icon.png";
import VietnamFlag from "./vietnam-flag-icon.jpg";
import TransmissionSvg from "./TransmissionSvg";
import BrandModelSvg from "./BrandModelSvg";
import BodyTypeSvg from "./BodyTypeSvg";
import BodyType1 from "./BodyType1.png";
import BodyType2 from "./BodyType2.png";
import BodyType3 from "./BodyType3.png";
import BodyType4 from "./BodyType4.png";
import BodyType5 from "./BodyType5.png";
import PdfIcon from "./pdf_icon.png";
import DocIcon from "./icon_doc.png";

export const cabin = {
  cabin1,
  cabin2,
  cabin3,
  cabin4,
  cabin5,
  cabin6,
};

export const safety = {
  safety1,
  safety2,
  safety3,
  safety4,
  safety5,
  safety6,
  safety7,
};

export function LocalDeliveryCheckedSvg() {
  return (
    <svg
      width="32"
      height="28"
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.39459 4.39678C6.65989 2.28934 8.93788 1 11.396 1H20.604C23.0621 1 25.3401 2.28934 26.6054 4.39678L31 11.7163V24C31 25.6569 29.6569 27 28 27H26C24.3431 27 23 25.6569 23 24V23H9V24C9 25.6569 7.65685 27 6 27H4C2.34315 27 1 25.6569 1 24V11.7163L5.39459 4.39678ZM25 13H7V11H25V13ZM5 19H11V17H5V19ZM27 17H21V19H27V17Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-linecap="square"
      />
    </svg>
  );
}

export const IconCheckedAlert = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9ZM8.48616 12.8528L13.6686 6.37478L12.7315 5.62515L8.3139 11.1472L5.18414 8.53903L4.41592 9.4609L8.48616 12.8528Z"
      fill="#18BA92"
    />
  </svg>
);

export function InStorePickupSvg() {
  return (
    <svg
      width="width"
      height="height"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4188 0.186267C14.7665 -0.0620888 15.2335 -0.0620888 15.5812 0.186267L29.5812 10.1863C29.844 10.374 30 10.677 30 11V29C30 29.5523 29.5523 30 29 30H26V14H4V30H1C0.447715 30 0 29.5523 0 29V11C0 10.677 0.155966 10.374 0.418762 10.1863L14.4188 0.186267Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 30H24V22H6V30ZM18 26H12V24H18V26Z"
        fill="currentColor"
      />
      <path d="M24 20V16H6V20H24Z" fill="currentColor" />
    </svg>
  );
}

export function BriefcaseAltSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33334 4.00008V3.33341C7.33334 1.86066 8.52725 0.666748 10 0.666748C11.4728 0.666748 12.6667 1.86066 12.6667 3.33341V4.00008M0.666672 8.66675C5.6077 14.3136 14.3923 14.3136 19.3333 8.66675M2.00001 4.66675H18C18.7364 4.66675 19.3333 5.2637 19.3333 6.00008V18.0001C19.3333 18.7365 18.7364 19.3334 18 19.3334H2.00001C1.26363 19.3334 0.666672 18.7365 0.666672 18.0001V6.00008C0.666672 5.2637 1.26363 4.66675 2.00001 4.66675Z"
        stroke="#666666"
      />
    </svg>
  );
}

export function OrderPlacedSvg() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L2.2 5M2.2 5L7 21H29V9C29 6.79086 27.2091 5 25 5H2.2ZM17 8V18M12 13H22M25 29C23.8954 29 23 28.1046 23 27C23 25.8954 23.8954 25 25 25C26.1046 25 27 25.8954 27 27C27 28.1046 26.1046 29 25 29ZM9 27C9 25.8954 9.89543 25 11 25C12.1046 25 13 25.8954 13 27C13 28.1046 12.1046 29 11 29C9.89543 29 9 28.1046 9 27Z"
        stroke="#666666"
      />
    </svg>
  );
}

export function BankOutlineSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.666504L10.2236 0.21929C10.0828 0.148908 9.91716 0.148908 9.77639 0.21929L10 0.666504ZM0 19.8332H20V18.8332H0V19.8332ZM9.77639 0.21929L1.77639 4.2193L2.22361 5.11373L10.2236 1.11372L9.77639 0.21929ZM0 7.83318H20V6.83318H0V7.83318ZM18.2236 4.2193L10.2236 0.21929L9.77639 1.11372L17.7764 5.11373L18.2236 4.2193ZM6.83333 10.6665V15.9998H7.83333V10.6665H6.83333ZM12.1667 10.6665V15.9998H13.1667V10.6665H12.1667ZM1.5 7.33318V19.3332H2.5V7.33318H1.5ZM17.5 7.33318V19.3332H18.5V7.33318H17.5Z"
        fill="#666666"
      />
    </svg>
  );
}

export function ReservartionTimeSvg() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.9999 1.33333H6.99992V0H10.9999V1.33333Z" fill="#0D3E9A" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.99992 2.66667C4.21345 2.66667 0.333252 6.54687 0.333252 11.3333C0.333252 16.1198 4.21345 20 8.99992 20C13.7864 20 17.6666 16.1198 17.6666 11.3333C17.6666 6.54687 13.7864 2.66667 8.99992 2.66667ZM9.66658 10.6667V6.66667H8.33325V12H12.3333V10.6667H9.66658Z"
        fill="#0D3E9A"
      />
    </svg>
  );
}

export function SecureYourCarSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 5.33333C8.89543 5.33333 8 6.22876 8 7.33333V8H12V7.33333C12 6.22876 11.1046 5.33333 10 5.33333Z"
        fill="#0D3E9A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 -3.39262e-06 15.5228 0 10C0 4.47715 4.47715 -3.39262e-06 10 0ZM6.66667 7.33333V8.1138C5.88987 8.38836 5.33333 9.12919 5.33333 10V14C5.33333 15.1046 6.22876 16 7.33333 16H12.6667C13.7712 16 14.6667 15.1046 14.6667 14V10C14.6667 9.12919 14.1101 8.38836 13.3333 8.1138V7.33333C13.3333 5.49238 11.8409 4 10 4C8.15905 4 6.66667 5.49238 6.66667 7.33333Z"
        fill="#0D3E9A"
      />
    </svg>
  );
}

export function PurchaseSvg() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.0253906 0.772308L1.17478 0.42749L1.7665 2.3999H15.0001C16.6569 2.3999 18.0001 3.74304 18.0001 5.3999V13.1999H3.75367L0.0253906 0.772308ZM9.60019 10.7999V8.3999H7.20019V7.1999H9.60019V4.7999H10.8002V7.1999H13.2002V8.3999H10.8002V10.7999H9.60019Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.8002 16.1999C4.8002 15.2058 5.60608 14.3999 6.60019 14.3999C7.59431 14.3999 8.40019 15.2058 8.40019 16.1999C8.40019 17.194 7.59431 17.9999 6.60019 17.9999C5.60608 17.9999 4.8002 17.194 4.8002 16.1999ZM6.60019 15.5999C6.26882 15.5999 6.00019 15.8685 6.00019 16.1999C6.00019 16.5313 6.26882 16.7999 6.60019 16.7999C6.93157 16.7999 7.20019 16.5313 7.20019 16.1999C7.20019 15.8685 6.93157 15.5999 6.60019 15.5999Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2002 16.1999C13.2002 15.2058 14.0061 14.3999 15.0002 14.3999C15.9943 14.3999 16.8002 15.2058 16.8002 16.1999C16.8002 17.194 15.9943 17.9999 15.0002 17.9999C14.0061 17.9999 13.2002 17.194 13.2002 16.1999ZM15.0002 15.5999C14.6688 15.5999 14.4002 15.8685 14.4002 16.1999C14.4002 16.5313 14.6688 16.7999 15.0002 16.7999C15.3316 16.7999 15.6002 16.5313 15.6002 16.1999C15.6002 15.8685 15.3316 15.5999 15.0002 15.5999Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function FinanceInformation() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.666748L10.2236 0.219535C10.0828 0.149153 9.91716 0.149153 9.77639 0.219535L10 0.666748ZM0 19.8334H20V18.8334H0V19.8334ZM9.77639 0.219535L1.77639 4.21954L2.22361 5.11397L10.2236 1.11396L9.77639 0.219535ZM0 7.83342H20V6.83342H0V7.83342ZM18.2236 4.21954L10.2236 0.219535L9.77639 1.11396L17.7764 5.11397L18.2236 4.21954ZM6.83333 10.6668V16.0001H7.83333V10.6668H6.83333ZM12.1667 10.6668V16.0001H13.1667V10.6668H12.1667ZM1.5 7.33342V19.3334H2.5V7.33342H1.5ZM17.5 7.33342V19.3334H18.5V7.33342H17.5Z"
        fill="#666666"
      />
    </svg>
  );
}

export function SupplierInformation() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.666748L10.2236 0.219535C10.0828 0.149153 9.91716 0.149153 9.77639 0.219535L10 0.666748ZM6 11.3334V10.8334H5.5V11.3334H6ZM11.3333 11.3334H11.8333V10.8334H11.3333V11.3334ZM0 19.8334H20V18.8334H0V19.8334ZM9.77639 0.219535L1.77639 4.21954L2.22361 5.11397L10.2236 1.11396L9.77639 0.219535ZM0 7.83342H20V6.83342H0V7.83342ZM18.2236 4.21954L10.2236 0.219535L9.77639 1.11396L17.7764 5.11397L18.2236 4.21954ZM1.5 7.33342V19.3334H2.5V7.33342H1.5ZM17.5 7.33342V19.3334H18.5V7.33342H17.5ZM6.5 19.3334V11.3334H5.5V19.3334H6.5ZM6 11.8334H11.3333V10.8334H6V11.8334ZM10.8333 11.3334V19.3334H11.8333V11.3334H10.8333Z"
        fill="#666666"
      />
    </svg>
  );
}

export function CheckedLoanRequestApproved() {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.875 33.589L37.5215 19.031L38.5626 19.8639L25.4291 36.2808L15.6748 28.1522L16.5284 27.1279L24.454 33.7326L25.2377 34.3857L25.875 33.589ZM27 1C12.6406 1 1 12.6406 1 27C1 41.3594 12.6406 53 27 53C41.3594 53 53 41.3594 53 27C53 12.6406 41.3594 1 27 1Z"
        fill="#E9F1FE"
        stroke="#295EC2"
        stroke-width="2"
      />
    </svg>
  );
}

export function LoadnRequestDenied() {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.8216 36.7644L27.7072 28.65L27.0001 27.9429L26.293 28.65L18.1786 36.7644L17.2358 35.8216L25.3502 27.7072L26.0573 27.0001L25.3502 26.293L17.2358 18.1786L18.1786 17.2358L26.293 25.3502L27.0001 26.0573L27.7072 25.3502L35.8216 17.2358L36.7644 18.1786L28.65 26.293L27.9429 27.0001L28.65 27.7072L36.7644 35.8216L35.8216 36.7644ZM27 1C12.6406 1 1 12.6406 1 27C1 41.3594 12.6406 53 27 53C41.3594 53 53 41.3594 53 27C53 12.6406 41.3594 1 27 1Z"
        fill="#FEEAEA"
        stroke="#F65354"
        stroke-width="2"
      />
    </svg>
  );
}

export function DisburseLoan() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9091 12.2424L10.4848 12.6667L11.3333 13.5152L11.7576 13.091L10.9091 12.2424ZM14 10L14.4243 10.4243L14.8485 10L14.4243 9.57576L14 10ZM11.7576 6.90909L11.3333 6.48483L10.4848 7.33335L10.9091 7.75762L11.7576 6.90909ZM11.7576 13.091L14.4243 10.4243L13.5757 9.57576L10.9091 12.2424L11.7576 13.091ZM14.4243 9.57576L11.7576 6.90909L10.9091 7.75762L13.5757 10.4243L14.4243 9.57576ZM14 9.40002H5.33332V10.6H14V9.40002ZM9.99999 18.7334C5.1767 18.7334 1.26666 14.8233 1.26666 10H0.0666565C0.0666565 15.486 4.51396 19.9334 9.99999 19.9334V18.7334ZM18.7333 10C18.7333 14.8233 14.8233 18.7334 9.99999 18.7334V19.9334C15.486 19.9334 19.9333 15.486 19.9333 10H18.7333ZM9.99999 1.26669C14.8233 1.26669 18.7333 5.17673 18.7333 10H19.9333C19.9333 4.51399 15.486 0.066687 9.99999 0.066687V1.26669ZM9.99999 0.066687C4.51396 0.066687 0.0666565 4.51399 0.0666565 10H1.26666C1.26666 5.17673 5.1767 1.26669 9.99999 1.26669V0.066687Z"
        fill="white"
      />
    </svg>
  );
}

export function LinkSvg() {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 1H7M12.5 1V6.5M12.5 1L3.5 10M10.5 7.5C10.5 9.84315 10.5 13 10.5 13H0.5V3H7"
        stroke="#666666"
      />
    </svg>
  );
}

export function CreditCardSvg() {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4H15M2.5 8H8.5M10.5 8H13.5M1 2L1 10C1 10.5523 1.44772 11 2 11L14 11C14.5523 11 15 10.5523 15 10V2C15 1.44772 14.5523 1 14 1L2 1C1.44772 1 1 1.44772 1 2Z"
        stroke="#0D3E9A"
      />
    </svg>
  );
}

export function CategoriesSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H20V6.66667H0V0Z" fill="currentColor" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.33333 8V18C1.33333 19.1046 2.22876 20 3.33333 20H16.6667C17.7712 20 18.6667 19.1046 18.6667 18V8H1.33333ZM13.3333 12H6.66667V10.6667H13.3333V12Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ConfigSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.03518 10C8.03518 8.89695 8.91456 8.00062 9.9986 8.00062C11.0826 8.00062 11.962 8.89695 11.962 10C11.962 11.1032 11.0826 11.9995 9.9986 11.9995C8.91459 11.9995 8.03518 11.1032 8.03518 10Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.42452 0H12.5718L12.7898 1.11557L12.7905 1.11938L13.1491 2.89995C13.6605 3.12982 14.1348 3.41442 14.5734 3.73654L16.366 3.12563L17.4285 2.79011L17.9834 3.75974L19.4329 6.23204L20 7.22322L19.1555 7.97359L17.811 9.13932C17.8532 9.40099 17.8923 9.69638 17.8923 9.99996C17.8923 10.3036 17.8532 10.599 17.811 10.8607L19.1615 12.0317L20 12.7768L19.4293 13.774L17.9835 16.24L17.4285 17.2123L16.3523 16.8698L14.5734 16.2635C14.1348 16.5856 13.6605 16.8701 13.1491 17.1L12.7905 18.8805L12.7898 18.8843L12.5718 20H7.4245L7.20851 18.8819L6.84859 17.1001C6.33712 16.8702 5.86278 16.5856 5.42407 16.2635L3.63276 16.8739L2.56802 17.2127L2.01497 16.2395L0.563968 13.7668L0 12.7768L0.842006 12.0264L2.18699 10.8603C2.14493 10.5993 2.10516 10.3028 2.10516 9.99996C2.10516 9.69717 2.14492 9.40067 2.18699 9.13974L0.835275 7.96768L2.8399e-05 7.22321L0.568269 6.22566L2.01515 3.76021L2.56803 2.7898L3.64524 3.13014L5.42407 3.73654C5.86276 3.41437 6.3371 3.12973 6.84859 2.89985L7.20852 1.11797L7.42452 0ZM9.9986 6.66648C8.19249 6.66648 6.72624 8.15927 6.72624 10C6.72624 11.8408 8.19246 13.3337 9.9986 13.3337C11.8047 13.3337 13.271 11.8408 13.271 10C13.271 8.15927 11.8047 6.66648 9.9986 6.66648Z"
        fill="currentColor"
      />
    </svg>
  );
}
export function RequestSvg() {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 1.00588 0.805887 0.199997 1.8 0.199997H16.2C17.1941 0.199997 18 1.00588 18 2V3.44909L9.00001 8.51158L0 3.44908V2Z"
        fill="#555555"
      />
      <path
        d="M0 4.82589V14C0 14.9941 0.805887 15.8 1.8 15.8H16.2C17.1941 15.8 18 14.9941 18 14V4.82591L9.00001 9.8884L0 4.82589Z"
        fill="#555555"
      />
    </svg>
  );
}

export function ReportsSvg() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3333 10.6606V13.3333H9.66658V10.6606H12.3333Z"
        fill="currentColor"
      />
      <path
        d="M12.3333 6.66317V9.32723H9.66658V6.66317H12.3333Z"
        fill="currentColor"
      />
      <path
        d="M8.33325 6.66317H5.66658V9.32723H8.33325V6.66317Z"
        fill="currentColor"
      />
      <path
        d="M8.33325 10.6606H5.66658V13.3333H8.33325V10.6606Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.333252 2C0.333252 0.895431 1.22868 0 2.33325 0H13.2761L17.6666 4.39052V18C17.6666 19.1046 16.7712 20 15.6666 20H2.33325C1.22868 20 0.333252 19.1046 0.333252 18V2ZM13.6666 5.32983H4.33325V14.6667H13.6666V5.32983Z"
        fill="currentColor"
      />
    </svg>
  );
}

export const lighting = { lighting1, lighting2 };

export {
  IconBlomobil,
  IconChat,
  IconNotification,
  IconShop,
  IconAppsNav,
  IconOrderNav,
  IconInventoryNav,
  InventorySvg,
  OrderSvg,
  AppsSvg,
  SearchSvg,
  FilterSvg,
  ColumnSvg,
  PlusSvg,
  ImageSvg,
  Mp4Svg,
  BackSvg,
  CloseSvg,
  EditSvg,
  DollarSvg,
  GoogleAdSvg,
  FeatureSvg,
  CostEstimateSvg,
  LocationSvg,
  InfoCircleSvg,
  CheckedSvg,
  ImageCarousel,
  ImageCarousel1,
  CompetitorsExtraSvg,
  ImageVideoSvg,
  CompetitorsExtra1,
  CompetitorsExtra2,
  CompetitorsExtra3,
  PublicSvg,
  SaveSvg,
  WirelessCharger,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
  feature7,
  feature8,
  feature9,
  feature10,
  feature11,
  feature12,
  feature13,
  feature14,
  feature15,
  feature16,
  feature18,
  feature17,
  IconColorSvg,
  DateSvg,
  CarSvg,
  HistorySvg,
  ImageCarouse2,
  BlimobiGraphic,
  BlimobiGraphicAuth,
  IconBlomobilAuth,
  EmailSvg,
  LockSvg,
  CompanySvg,
  NextSvg,
  UserSvg,
  CountrySvg,
  StoreAddressSvg,
  CompanyRegistrationNoSvg,
  UserSliderSvg,
  StoreInfoSvg,
  BankSvg,
  InfoImage,
  EmptyInventorySvg,
  MarketplaceSvg,
  ImageError,
  CartSvg,
  ShippingMethodSvg,
  OrderDetailMarketplaceSvg,
  StopwatchSvg,
  DeliveryorPickupSvg,
  MapBooking,
  IconBlomobilWhite,
  IndonesiaFlag,
  EnglishFlag,
  IconCarhay,
  TransmissionSvg,
  BrandModelSvg,
  BodyTypeSvg,
  BodyType1,
  BodyType2,
  BodyType3,
  BodyType4,
  BodyType5,
  PdfIcon,
  VietnamFlag,
  DocIcon,
};
