import axios from "axios";
import _get from "lodash/get";
import _map from "lodash/map";
import queryString from "query-string";

import Urls from "../consts/Urls";
import axiosClient from "./axios";
import { promiseUpload } from "utils/common";
import { TYPE_FILE } from "consts/Enum";

const UploadService = {
  UPLOAD_FILE: async (params, data) => {
    const formData = new FormData();
    const parseParams = queryString.stringify(params);
    formData.append("file", data.file);
    data?.videoPath && formData.append("videoPath", data.videoPath);

    return await axiosClient({
      method: "POST",
      url: `${Urls.UPLOAD_FILE}?${parseParams}`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      transformRequest: (data, error) => {
        return formData;
      }
    });
  },
  UPLOAD_FILE_V2: async (params, data) => {
    const formData = new FormData();
    const parseParams = queryString.stringify(params);
    formData.append("file", data.file);
    data?.videoPath && formData.append("videoPath", data.videoPath);

    return await axiosClient({
      method: "POST",
      url: `${Urls.UPLOAD_FILE_V2}?${parseParams}`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      transformRequest: (data, error) => {
        return formData;
      }
    });
  }
};

export default UploadService;

export const uploadToS3 = async (
  fileList,
  code,
  type = TYPE_FILE.CAR,
  action
) => {
  const uploadFileResults = await Promise.all(
    _map(fileList, (video) => {
      return getPresignedPostUrl(
        { filename: _get(video, "name") },
        code,
        action
      );
    })
  );
  await Promise.all(
    _map(uploadFileResults, (item, index) =>
      axios.put(item?.presignedURL, fileList[index]?.originFileObj, {
        headers: { "Content-Type": fileList[index]?.originFileObj?.type }
      })
    )
  );
  const result = await promiseUpload(
    code,
    type,
    fileList,
    uploadFileResults,
    action
  );
  return result;
};

export const getPresignedPostUrl = async (data, code, action) => {
  const { data: presignedPostUrl } = await axiosClient.post(
    Urls.GET_PRESIGNED_URL + `?refCode=${code}`,
    action
      ? {
          filename: `${action}_${data.filename}`
        }
      : data
  );

  return presignedPostUrl;
};

export const deleteFile = async (fileId) => {
  const { data } = await axiosClient.post(
    Urls.DELETE_FILE + fileId + "/delete");
  return data;
};

export const deleteMerchantFile = async (fileId) => {
  const { data } = await axiosClient.post(
    Urls.DELETE_MERCHANT_FILE + fileId + "/delete");
  return data;
};

export const deleteFileV2 = async (fileId) => {
  const { data } = await axiosClient.post(
    Urls.DELETE_FILE_V2 + fileId + "/delete");
  return data;
};