export const TYPE_PAGE = Object.freeze({
  DETAIL: 1,
  CREATE: 2,
  UPDATE: 3
});

export const PAGINATION = Object.freeze({
  page: 1,
  recordPerPage: 10
});

export const CAR_STATUS_KEY = Object.freeze({
  DRAFT: "0",
  AVAILABLE: "1",
  BOOKED: "2",
  SOLD: "3"
});

export const TYPE_USER = Object.freeze({
  BUYER: "buyer",
  DEALER: "dealer",
  SUPPLIER: "supplier",
  ADMIN: "admin",
  FINANCE: "finance"
});

export const CAR_STATUS_VALUE = Object.freeze({
  0: "Nháp",
  1: "Có sẵn",
  2: "Được đặt",
  3: "Đã bán"
});

export const CAR_USAGE_TYPE = Object.freeze({
  0: "Xe Mới",
  1: "Xe Cũ"
});

export const CAR_USAGE_VALUE = Object.freeze({
  NEW: "0",
  USED: "1"
});

export const CATEGORY_TYPE = Object.freeze({
  CAR_YEAR: "carYear",
  CAR_VARIANT: "carVariant",
  ENGINE: "engine",
  TRANSMISSION: "transmission",
  FUEL_TYPE: "fuelType",
  SEATING_CAPACITY: "seatingCapacity",
  CAR_COLOR: "carColor",
  CAR_FEATURE_CONVENIENCE: "carFeatureConvenience",
  CAR_FEATURE_CABIN: "carFeatureCabin",
  CAR_FEATURE_SAFETY: "carFeatureSafety",
  CAR_FEATURE_LIGHTING: "carFeatureLighting",
  SHIPPING_METHOD: "shippingMethod",
  ORIGIN: "origin",
  GEARBOX: "gearbox",
  NUMBER_PLATE: "numberPlate",
});

export const ACTION_PAGE = Object.freeze({
  CREATE: "create",
  UPDATE: "update"
});

export const TAB_INFO = Object.freeze({
  INFORMATION: "INFORMATION",
  STORE: "STORE",
  BANK: "BANK",
  FEE_COMMISSION: "FEE_COMMISSION"
});

export const TYPE_FILE = Object.freeze({
  CAR: "car",
  CAR_THUMBNAIL: "carThumbnail",
  BRAND: "brand",
  ORDER: "order",
  avatar: "avatar",
  merchant: "merchant",
  merchantPhoto: "merchantPhoto",
  contract: "merchantContract"
});

export const PASSPORT_REG_EX =
  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*?]{8,}$/;

export const EMAIL_REG_EX =
  /^[a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+$/;

export const FIRST_CHARACTER_NOT_BE_0 = /^[1-9a-zA-Z][0-9a-zA-Z]*$/;
export const ONLY_NUMBER = /^\d+$/;
export const VALID_URL = /^(https?):\/\/[^\s$.?#].[^\s]*$/;

export const REGEX_EMBED_SRC = "<iframe[^>]*src=[\"|']([^'\"]+)[\"|'][^>]*>";
export const MATCH_BASE64_REG_EX =
  /data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/gi;
export const MAX_BASE_64_STRING_LENGTH = 1000;

export const STATUS_DEALER = Object.freeze({
  ALL_DEALERS: "all",
  NEW: "new",
  RESQUESTING_STORE: "requestingStore",
  AVAILABLE: "available",
  STORE_REJECTED: "rejected",
  DELETED: "deleted"
});

export const STATUS_SUPPLIER = Object.freeze({
  ALL_SUPPLIER: "all",
  NEW: "new",
  RESQUESTING_STORE: "requestingStore",
  AVAILABLE: "available",
  STORE_REJECTED: "rejected",
  DELETED: "deleted"
});

export const STATUS_ORDER_BUYER = Object.freeze({
  PENDING_PAYMENT: "pendingPayment",
  VERIFYING_PAYMENT: "verifyingPayment",
  PAID: "paid",
  DELIVERED: "delivered",
  RELEASED_PAYMENT: "releasedPayment",
  COMPLETED: "completed",
  CANCELLED: "cancelled"
});

export const STATUS_ORDER_DEALER = Object.freeze({
  PENDING_PAYMENT: "pendingPayment",
  VERIFYING_PAYMENT: "verifyingPayment",
  PAID_PARTIALLY_PAID: "paid/partiallyPaid",
  DELIVERED: "delivered",
  RELEASED_PAYMENT: "releasedPayment",
  COMPLETED: "completed",
  CANCELLED: "cancelled"
});

export const SHIPPING_METHOD = Object.freeze({
  LOCAL_DELIVERY: "localDelivery",
  IN_STORE_PICKUP: "inStorePickup"
});

export const FINANCE_OPTIONS = Object.freeze({
  PAY_IN_FULL: "payInFull",
  PERSONAL_LOAN: "personalLoan"
});

export const size = {
  xs: 576,
  sm: 576,
  md: 768,
  lg: 1200,
  xxl: 1600
};

export const device = {
  xs: `(max-width: ${size.xs}px)`,
  sm: `(min-width: ${size.sm}px)`,
  md: `(min-width: ${size.md}px)`,
  lg: `(min-width: ${size.lg}px)`,
  xxl: `(min-width: ${size.xxl}px)`
};

export const TYPE_ORDER = Object.freeze({
  BUYER_DEALER: "buyer-dealer",
  DEALER_SUPPLIER: "dealer-supplier"
});

export const TYPE_CHAT_ADMIN1 = Object.freeze({
  BUYER: {
    key: "buyer",
    name: "Người mua"
  },
  DEALER: {
    key: "dealer",
    name: "Đại lý"
  },
  SUPPLIER: {
    key: "supplier",
    name: "Nhà cung cấp"
  },
  FINACE: {
    key: "finance",
    name: "Tài chính"
  }
});

export const TYPE_CHAT_SUPPLIER = Object.freeze({
  DEALER: {
    key: "dealer",
    name: "Dealer"
  },
  ADMIN: {
    key: "admin",
    name: "Admin"
  },
  FINACE: {
    key: "finance",
    name: "Finance"
  }
});

export const TYPE_CHAT_DEALER = Object.freeze({
  BUYER: {
    key: "buyer",
    name: "Người mua"
  },
  SUPPLIER: {
    key: "supplier",
    name: "Đại lý"
  },
  ADMIN: {
    key: "admin",
    name: "Chuyên viên tư vấn"
  },
  FINACE: {
    key: "finance",
    name: "Tài chính"
  }
});

export const TYPE_CHAT_FINANCE = Object.freeze({
  DEALER: {
    key: "dealer",
    name: "Dealer"
  },
  SUPPLIER: {
    key: "supplier",
    name: "Supplier"
  },
  ADMIN: {
    key: "admin",
    name: "Admin"
  }
});

export const TYPE_CHAT_ADMIN = Object.freeze({
  DEALER: "dealer",
  SUPPLIER: "supplier",
  FINACE: "finance"
});

export const CHAT_TYPE_CREATE = Object.freeze({
  CAR: "car",
  ORDER: "order"
});

export const PURCHASE_PAYMENT_OPTION = Object.freeze({
  PAID_30: {
    key: "2",
    status: "paid_30",
    status_admin: "confirm_paid_30"
  },
  PAID_70: {
    key: "2",
    status: "paid_70",
    status_admin: "confirm_paid_70"
  },
  PAID_100: {
    key: "1",
    status: "paid_100",
    status_admin: "confirm_paid_100"
  }
});

export const ACTION_REQUEST_FINANCE = Object.freeze({
  APPROVE: "financePaid_70",
  DENY: "financeDenied",
  DISBURSE_LOAN: "confirmPaid_70"
});

export const PURCHASE_UPDATE_PAYMENT_PERMISSION = Object.freeze({
  DEALER_PURCHASE: "DEALER_PURCHASE",
  DEALER_ORDER: "DEALER_ORDER",
  ADMIN_ORDER: "ADMIN_ORDER"
});

export const TEXT_INFORMATION = Object.freeze({
  CUSTOMER_INFORMATION: "Customer Information",
  DEALER_INFORMATION: "Dealer Information",
  FINANCE_INFORMATION: "Finance Information",
  SUPPLIER_INFORMATION: "Supplier Information"
});

export const KEY_TEXT_INFORMATION = Object.freeze({
  CUSTOMER_INFORMATION: "customerInformation",
  DEALER_INFORMATION: "dealerInformation",
  FINANCE_INFORMATION: "financeInformation",
  SUPPLIER_INFORMATION: "supplierInformation"
});

export const HASH_ONBOARDING = "#onboarding";

export const HASH_PREVIEW = "#preview";

export const COLOR_STATUS = Object.freeze({
  new: {
    bgColor: "#CAEBF9",
    color: "#50BDEA",
    value: "new"
  },
  available: {
    bgColor: "#A5F6E2",
    color: "#1BD2A4",
    value: "available"
  },
  requestingStore: {
    bgColor: "#FFE2CC",
    color: "#FF9A4D",
    value: "requestingStore"
  },
  storeRejected: {
    color: "#666666",
    bgColor: "#D2D1D4",
    value: "storeRejected"
  },
  rejected: {
    color: "#666666",
    bgColor: "#D2D1D4",
    value: "rejected"
  },
  deleted: {
    color: "#F76969",
    bgColor: "#FCBFBF",
    value: "deleted"
  }
});

export const MONTHS = [
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X",
  "XI",
  "XII"
];

export const TYPE_DOC = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword"
];

export const REQUEST_STATUS = {
  offered: {
    text: 'Offered',
    status: 'success',
  },
  cancel: {
    text: 'Canceled',
    status: 'error',
  },
  completed: {
    text: 'Completed',
    status: 'processing',
  },
  pending: {
    text: 'Pending',
    status: 'warning',
  },
}
