import { createActions } from "reduxsauce";

export const {Types, Creators} = createActions(
    {
        notificationListAction: ["params"],
        notificationListSuccess: ["data"],

        markNotificationReadAction: ["params"],
        markNotificationReadSuccess: ["data"],

        notificationFail: []
    },
    {}
)
export default Creators;