import { createReducer } from "reduxsauce";
import { Types } from "./actions";
import { PAGINATION } from "./../../consts/Enum";

const INITIAL_STATE = {
  isFetching: false,
  marketplaceList: [],
  params: {
    page: PAGINATION.page,
    recordPerPage: PAGINATION.recordPerPage,
    recordTotal: PAGINATION.recordPerPage,
    all: false
  },
  paramsFilter: {
    brandId: [],
    modelId: [],
    variantId: [],
    sellingPriceFROM: 0,
    sellingPriceTO: 10000000000,
    milleageFROM: 0,
    milleageTO: 0,
    yearFROM: 1980,
    yearTO: 1980,
    color: [],
    transmissionId: [],
    locationId: []
  },
  paramsFilterApply: {
    brandId: [],
    modelId: [],
    variantId: [],
    sellingPriceFROM: 0,
    sellingPriceTO: 0,
    milleageFROM: 0,
    milleageTO: 0,
    yearFROM: 1980,
    yearTO: 1980,
    color: [],
    transmissionId: [],
    locationId: []
  },
  marketplaceDetail: {},
  marketBooking: {
    step: 0,
    // shippingMethod: SHIPPING_METHOD.LOCAL_DELIVERY,
    // financeOptions: FINANCE_OPTIONS.PAY_IN_FULL,
    mediaConact: []
  },
  infoOrder: {}
};

export const marketplaceListAction = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: true
  };
};

export const marketplaceListSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  params: { ...action.data.params },
  marketplaceList: action.data.data
});

export const marketplaceDetailAction = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: true
});

export const marketplaceDetailSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  marketplaceDetail: action.data
});

export const marketplaceBookingAction = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false
});

export const marketplaceBookingSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  marketBooking: { ...state.marketBooking, ...action.data }
});

export const marketplaceBookingCreateAction = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  isFetching: true
});

export const marketplaceBookingCreateSuccess = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  isFetching: false,
  infoOrder: action.data.data,
  marketBooking: {
    ...state.marketBooking,
    mediaConact: []
  }
});

export const marketplaceFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false
});

export const marketplaceListFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  marketplaceList: [],
  params: {
    page: PAGINATION.page,
    recordPerPage: PAGINATION.recordPerPage,
    recordTotal: PAGINATION.recordPerPage,
    all: false
  }
});

export const marketplaceUpdateFilterAction = (
  state = INITIAL_STATE,
  action
) => ({
  ...state
});

export const marketplaceUpdateFilterSuccess = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  paramsFilter: { ...state.paramsFilter, ...action.data }
});

export const marketplaceUpdateFilterApplyAction = (
  state = INITIAL_STATE,
  action
) => ({
  ...state
});

export const marketplaceUpdateFilterApplySuccess = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  paramsFilterApply: { ...state.paramsFilterApply, ...action.data }
});

export const HANDLERS = {
  [Types.MARKETPLACE_LIST_ACTION]: marketplaceListAction,
  [Types.MARKETPLACE_LIST_SUCCESS]: marketplaceListSuccess,
  [Types.MARKETPLACE_DETAIL_ACTION]: marketplaceDetailAction,
  [Types.MARKETPLACE_DETAIL_SUCCESS]: marketplaceDetailSuccess,
  [Types.MARKETPLACE_BOOKING_ACTION]: marketplaceBookingAction,
  [Types.MARKETPLACE_BOOKING_SUCCESS]: marketplaceBookingSuccess,
  [Types.MARKETPLACE_BOOKING_CREATE_ACTION]: marketplaceBookingCreateAction,
  [Types.MARKETPLACE_BOOKING_CREATE_SUCCESS]: marketplaceBookingCreateSuccess,
  [Types.MARKETPLACE_UPDATE_FILTER_ACTION]: marketplaceUpdateFilterAction,
  [Types.MARKETPLACE_UPDATE_FILTER_SUCCESS]: marketplaceUpdateFilterSuccess,
  [Types.MARKETPLACE_UPDATE_FILTER_APPLY_ACTION]:
    marketplaceUpdateFilterApplyAction,
  [Types.MARKETPLACE_UPDATE_FILTER_APPLY_SUCCESS]:
    marketplaceUpdateFilterApplySuccess,
  [Types.MARKETPLACE_FAILURE]: marketplaceFailure,
  [Types.MARKETPLACE_LIST_FAILURE]: marketplaceListFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
