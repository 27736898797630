import {createReducer} from "reduxsauce";
import {Types} from "./actions";
import { PAGINATION, TYPE_ORDER } from "./../../consts/Enum"

const INITIAL_STATE = {
    isFetching: false,
    brandList: [],
    colorList: [],
    params: {
        page: PAGINATION.page,
        recordPerPage: PAGINATION.recordPerPage,
        status: "all",
        type: TYPE_ORDER.BUYER_DEALER
    },
    brandDetail: {}
};

export const brandListAction = (state = INITIAL_STATE, action) => ({
    ...state,
    isFetching: true,
})

export const brandListSuccess = (state = INITIAL_STATE, action) => ({
    ...state,
    brandList: action.data,
})

export const brandCreateAction = (state = INITIAL_STATE, action) =>({
    ...state,
    isFetching: true,
})

export const createBrandSuccess = (state = INITIAL_STATE, action) =>({
    ...state,
    isFetching: false,
    brandDetail: action.data,
})
 
export const brandFailed = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
});

export const addColorAction = (state = INITIAL_STATE, action) => ({
    ...state,
    isFetching: true,
})

export const addColorSuccess = (state = INITIAL_STATE, action) => ({
    ...state,
    isFetching: false,
})

export const getColorAction = (state = INITIAL_STATE, action) => ({
    ...state,
    isFetching: true,
})

export const getColorSuccess = (state = INITIAL_STATE, action) => ({
    ...state,
    isFetching: false,
    colorList: action.data,
})


export const HANDLERS = {
    [Types.BRAND_LIST_ACTION]: brandListAction,
    [Types.BRAND_LIST_SUCCESS]: brandListSuccess,
    [Types.BRAND_CREATE_ACTION]: brandCreateAction,
    [Types.CREATE_BRAND_SUCCESS]: createBrandSuccess,
    [Types.ADD_COLOR_ACTION]: addColorAction,
    [Types.ADD_COLOR_SUCCESS]: addColorSuccess,
    [Types.GET_COLOR_ACTION]: getColorAction,
    [Types.GET_COLOR_SUCCESS]: getColorSuccess,
    [Types.BRAND_FAILED]: brandFailed,
};

export default createReducer(INITIAL_STATE, HANDLERS);