import Urls from 'consts/Urls';
import axiosClient from './axios';

const PreOrderService = {
  PRE_ORDER_LIST: async (params) => {
    return await axiosClient.get(Urls.PRE_ORDER_LIST, { params });
  },
  REQUEST_LIST: async (params) => {
    delete params?.page;
    delete params?.recordPerPage;
    if(params?.status === 'all') {
      delete params?.status;
    }
    return await axiosClient.get(Urls.REQUEST_LIST, { params });
  },
  CREATE_PRE_ORDER: async (params) => {
    return await axiosClient.post(Urls.PRE_ORDER_CREATE, params);
  },
  SEND_OFFER: async (params) => {
    return await axiosClient.post(
      `${Urls.REQUEST_LIST}/${params?.requestId}/new-offer`,
      { carCode: params?.carCode }
    );
  },
  CANCEL_REQUEST: async (params) => {
    return await axiosClient.post(
      `${Urls.REQUEST_LIST}/${params?.requestId}/cancel`
    );
  },
  COMPLETED_REQUEST: async (params) => {
    return await axiosClient.post(
      `${Urls.REQUEST_LIST}/${params?.requestId}/complete`,
      { orderCode: params?.orderCode }
    );
  }
};
export default PreOrderService;
