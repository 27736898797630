import Icon from "@ant-design/icons";
import React, { useCallback, useMemo } from "react";
import { BackSvg, CloseSvg } from "../../assets/images/blimobil";
import {
  PageHeaderStyled,
  PageHeaderStyledCalc
} from "./PageHeaderCustom.style";
import { useHistory } from "react-router";
import { size, TYPE_PAGE } from "../../consts/Enum";
import { Skeleton } from "antd";
import useWindowSize from "../../hooks/useWindowSize";

function BackIcon({ type }) {
  return type === TYPE_PAGE.DETAIL ? (
    <Icon component={BackSvg} width={10} height={18} />
  ) : (
    <Icon component={CloseSvg} width={20} height={20} />
  );
}

function PageHeaderCustom({
  type,
  title = "",
  subTitle = "",
  extra = [],
  loading = false,
  isBack = false,
  onHandleBack = () => {},
  isCalc = false
}) {
  const navigate = useHistory();
  const [windowSize] = useWindowSize();

  const isMobile = useMemo(() => {
    return windowSize.width < size.xs;
  }, [windowSize]);

  const extraComponent = useMemo(() => {
    return extra;
  }, [extra]);

  return isCalc ? (
    <PageHeaderStyledCalc
      className="site-page-header"
      onBack={() => (isBack ? onHandleBack() : navigate.go(-1))}
      title={
        !isMobile &&
        (loading ? <Skeleton.Input active={true} size="default" /> : title)
      }
      backIcon={<BackIcon type={type} />}
      subTitle={
        !isMobile &&
        (loading ? <Skeleton.Input active={true} size="default" /> : subTitle)
      }
      extra={extraComponent}
    />
  ) : (
    <PageHeaderStyled
      className="site-page-header"
      onBack={() => (isBack ? onHandleBack() : navigate.go(-1))}
      title={
        !isMobile &&
        (loading ? <Skeleton.Input active={true} size="default" /> : title)
      }
      backIcon={<BackIcon type={type} />}
      subTitle={
        !isMobile &&
        (loading ? <Skeleton.Input active={true} size="default" /> : subTitle)
      }
      extra={extraComponent}
    />
  );
}

export default React.memo(PageHeaderCustom);
