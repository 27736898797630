import Urls from 'consts/Urls';
import axiosClient from './axios';
import { removeKeyOjectValueEmpty } from 'utils/common';

const ReportService = {
  REPORT_LIST: async (params) => {
    delete params.recordTotal;
    delete params.pageTotal;
    const newParams = removeKeyOjectValueEmpty(params);
    return await axiosClient.get(Urls.REPORT_LIST, { params: newParams });
  },
  INVOICE_LIST: async (params) => {
    delete params.recordTotal;
    delete params.pageTotal;
    const newParams = removeKeyOjectValueEmpty(params);
    return await axiosClient.get(Urls.INVOICE_LIST, { params: newParams });
  }
};
export default ReportService;
