import { call, put, takeLatest, fork, all } from 'redux-saga/effects';

import { PreOrderService } from 'service';
import PreOrderAction, { Types } from './actions';
import { sagaErrorHandler, sagaWrapper } from 'utils/common';

function* getPreOrderList() {
  yield takeLatest(
    Types.PRE_ORDER_LIST_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(PreOrderService.PRE_ORDER_LIST, action.params);
      yield put(PreOrderAction.preOrderListSuccess({ data: result?.data }));
      action.callback && action.callback(result?.data)
    }, errorHandle(Types.PRE_ORDER_FAILURE))
  );
}

function* getRequestList() {
  yield takeLatest(
    Types.REQUEST_LIST_FOR_SUPPLIER_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(PreOrderService.REQUEST_LIST, action.params);
      yield put(PreOrderAction.requestListForSupplierSuccess({ data: result.data }));
      action.callback && action.callback(result?.data)
    }, errorHandle(Types.PRE_ORDER_FAILURE))
  );
}

function* createPreOrder() {
  yield takeLatest(
    Types.CREATE_PRE_ORDER_ACTION,
    sagaWrapper(function* (action) {
      const { params, callback } = action;
      const result = yield call(PreOrderService.CREATE_PRE_ORDER, params);
      yield put(PreOrderAction.createPreOrderSuccess({...result, params}));
      callback && callback()
    }, errorHandle(Types.PRE_ORDER_FAILURE))
  );
}

function* sendOffer() {
  yield takeLatest(
    Types.SEND_OFFER_ACTION,
    sagaWrapper(function* (action) {
      const { params, callback } = action;
      const result = yield call(PreOrderService.SEND_OFFER, params);
      yield put(PreOrderAction.sendOfferSuccess({...result, params}));
      callback && callback()
    }, errorHandle(Types.PRE_ORDER_FAILURE))
  );
}

function* cancelRequest() {
  yield takeLatest(
    Types.CANCEL_REQUEST_ACTION,
    sagaWrapper(function* (action) {
      const { params, callback } = action;
      const result = yield call(PreOrderService.CANCEL_REQUEST, params);
      yield put(PreOrderAction.cancelRequestSuccess({...result, params}));
      callback && callback()
    }, errorHandle(Types.PRE_ORDER_FAILURE))
  );
}

function* completedRequest() {
  yield takeLatest(
    Types.COMPLETED_REQUEST_ACTION,
    sagaWrapper(function* (action) {
      const { params, callback } = action;
      const result = yield call(PreOrderService.COMPLETED_REQUEST, params);
      yield put(PreOrderAction.completedRequestSuccess({...result, params}));
      callback && callback()
    }, errorHandle(Types.PRE_ORDER_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return sagaErrorHandler(function* (e, action) {
    yield put({ type: errorActionType, sessionId: action?.sessionId });
  });
}

export default function* rootSaga() {
  yield all([
    fork(getPreOrderList), 
    fork(createPreOrder),
    fork(getRequestList),
    fork(sendOffer),
    fork(cancelRequest),
    fork(completedRequest),
  ]);
}
