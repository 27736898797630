import { Avatar, Drawer, Input, List, Space, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Box, IText } from "..";
import {
  BoxChat,
  ContentHeaderDetailChat,
  HeaderDetailChat,
  PopupContentMessage
} from "./ChatCustom.styles";
import {
  CloseOutlined,
  LoadingOutlined,
  SendOutlined,
  UserOutlined
} from "@ant-design/icons";
import UIColor from "../../colors";
import { TextField } from "../Form/form.style";
import {
  CreateChatSendMessageCallback as createChatSendMessageCallback,
  GetChatDetailCallback as getChatDetailCallback
} from "../../callbacks/chatCallback";
import { useSelector } from "react-redux";
import { selectChatDetail } from "../../reselects/chatSelector";
import styled from "styled-components";
import {
  selectFetchingChat,
  selectFetchingChatDetail,
  selectFetchingSendMessageChat
} from "../../reselects/fetchingSelector";
import storage from "../../utils/localStorage";
import { timeDifference } from "../../utils/common";
import queryString from "query-string";
import Urls from "consts/Urls";
import { useLocation } from "react-router";
import { isEmpty } from "lodash";

const DrawerStyled = styled(Drawer)`
  .ant-drawer-header,
  .ant-drawer-footer {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
`;
let interval;

function DetailChats({ itemChat, onClose, visible, info }) {
  // state
  const [content, setContent] = useState("");
  const [visibleQuery, setVisibleQuery] = useState(false);
  const { search } = useLocation();

  // select
  const chatDetail = useSelector(selectChatDetail());
  const isFetching = useSelector(selectFetchingChatDetail());
  const isFetchingSendMessage = useSelector(selectFetchingSendMessageChat());

  // callback
  const getChatDetail = getChatDetailCallback();
  const createSendMessage = createChatSendMessageCallback();

  const searchQuery = useMemo(() => queryString.parse(search), [search]);

  useEffect(() => {
    if (itemChat.code || searchQuery?.convId) {
      getChatDetail && getChatDetail(itemChat.code || searchQuery?.convId);
      interval = setInterval(() => {
        getChatDetail && getChatDetail(itemChat.code || searchQuery?.convId);
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [itemChat.code, getChatDetail, searchQuery]);

  const onSendMessage = () =>
    content &&
    createSendMessage &&
    createSendMessage(
      itemChat.code || searchQuery?.convId,
      { content: content },
      () => {
        setContent("");
      }
    );

  const contentMesseage = (item) => {
    const isDealer = item.createBy.userRole === storage.getIdToken();
    return (
      <Box
        style={{ marginBottom: 12 }}
        justify={isDealer ? "flex-end" : "flex-start"}
        items="end"
      >
        {!isDealer && (
          <div style={{ width: 40 }}>
            <Avatar icon={<UserOutlined />} />
          </div>
        )}
        <PopupContentMessage isDealer={isDealer}>
          {item.content}
          <br />
          <div style={{ width: "100%", textAlign: "right" }}>
            <IText fSize={8} color={isDealer ? "white" : UIColor.primary}>
              {timeDifference(item.created)}
            </IText>
          </div>
        </PopupContentMessage>
      </Box>
    );
  };

  const onHandleClose = () => {
    clearInterval(interval);
    setVisibleQuery(false);
    onClose && onClose();
  };

  useEffect(() => {
    if (isEmpty(searchQuery)) {
      return;
    }
    if (isEmpty(itemChat)) {
      setVisibleQuery(searchQuery?.showChat === "true");
    }
  }, [searchQuery, itemChat]);

  return (
    <DrawerStyled
      title={
        <HeaderDetailChat>
          <ContentHeaderDetailChat>
            <Space size={12}>
              <Avatar
                style={{ objectFit: "contain" }}
                src={
                  itemChat?.car?.photos?.photos?.length
                    ? Urls.URL_FILE +
                      itemChat?.car?.photos?.photos[0]?.path?.width180px
                    : ""
                }
                size={46}
              />
              <Space direction="vertical" size={0}>
                <IText color="#333333" fSize={14}>
                  {info?.info || itemChat?.car?._text}
                </IText>
                <IText fSize={12} color={UIColor.sky._3}>
                  {itemChat?.car?._text || info?.code}
                </IText>
              </Space>
            </Space>
            <CloseOutlined onClick={onHandleClose} />
          </ContentHeaderDetailChat>
        </HeaderDetailChat>
      }
      width={300}
      closable={false}
      onClose={onHandleClose}
      visible={visible || visibleQuery}
      footer={
        <Input.Group>
          <BoxChat>
            <TextField.TextArea
              autoSize={{
                minRows: 2,
                maxRows: 3
              }}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              onPressEnter={(e) => {
                e.preventDefault();
                onSendMessage()
              }}
              placeholder="Nhập ở đây..."
              allowClear
            />
            {isFetchingSendMessage ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              <SendOutlined
                onClick={onSendMessage}
                style={{ color: "#0D3E9A", cursor: "pointer" }}
              />
            )}
          </BoxChat>
        </Input.Group>
      }
    >
      <List
        dataSource={chatDetail}
        // loading={isFetching}
        renderItem={contentMesseage}
      />
    </DrawerStyled>
  );
}

export default React.memo(DetailChats);
