import React from "react";

export default function IconColorSvg() {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.49976 0.5C3.11904 0.5 1.99976 1.61929 1.99976 3V6.79284L1.56036 7.23224C0.584046 8.20855 0.584048 9.79146 1.56036 10.7678L4.73193 13.9393C5.70824 14.9157 7.29115 14.9157 8.26747 13.9393L12.439 9.76777C13.4154 8.79146 13.4153 7.20855 12.439 6.23224L9.26747 3.06066C8.63235 2.42555 7.74054 2.20359 6.926 2.39479C6.65542 1.3065 5.67177 0.5 4.49976 0.5ZM5.99976 4.20705V7.5H6.99976V3.41383C7.5274 3.22776 8.13834 3.34575 8.56036 3.76777L11.7319 6.93934C12.3177 7.52513 12.3177 8.47488 11.7319 9.06066L11.2926 9.5H1.91354C1.72745 8.97234 1.84543 8.36138 2.26746 7.93934L5.99976 4.20705ZM5.99076 2.83478C5.90851 2.08409 5.27235 1.5 4.49976 1.5C3.67133 1.5 2.99976 2.17157 2.99976 3V5.79284L5.73193 3.06066C5.81415 2.97845 5.90067 2.90315 5.99076 2.83478Z"
        fill="#666666"
      />
      <path
        d="M12.6443 10.237L14.1786 11.7713C15.0931 12.6858 15.0096 14.1921 13.9998 15C13.2073 15.634 12.0812 15.634 11.2888 15C10.2789 14.1921 10.1955 12.6858 11.1099 11.7713L12.6443 10.237Z"
        fill="#666666"
      />
    </svg>
  );
}
