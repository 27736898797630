import { createReducer } from 'reduxsauce';

import { PAGINATION } from 'consts/Enum';
import { Types } from './actions';

const INITIAL_STATE = {
  isFetching: false,
  categoryList: [],
  params: {
    page: PAGINATION.page,
    recordPerPage: PAGINATION.recordPerPage,
  },
};

export const categoryListAction = (state = INITIAL_STATE) => {
  return {
    ...state,
    isFetching: true,
  };
};

export const categoryListSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  params: { ...state.params, ...action.data.params },
  categoryList: action.data.data,
});

export const createCategoryAction = (state = INITIAL_STATE) =>({
  ...state,
  isFetching: true,
})

export const createCategorySuccess = (state = INITIAL_STATE, action) =>({
  ...state,
  isFetching: false,
})

export const categoryFailure = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

export const HANDLERS = {
  [Types.CATEGORY_LIST_ACTION]: categoryListAction,
  [Types.CATEGORY_LIST_SUCCESS]: categoryListSuccess,
  [Types.CREATE_CATEGORY_ACTION]: createCategoryAction,
  [Types.CREATE_CATEGORY_SUCCESS]: createCategorySuccess,
  [Types.CATEGORY_FAILURE]: categoryFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
