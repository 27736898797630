import axios from "axios";
import { trimValueObject } from "utils/common";
import Urls from "../consts/Urls";
import storage from "../utils/localStorage";
import { message } from "antd";

const configs = {
  baseURL: Urls.API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    "Access-Control-Allow-Origin": "*",
  },
};

const axiosClient = axios.create(configs);
axiosClient.defaults.timeout = 20000;
axiosClient.interceptors.request.use(async (request) => {
  request.headers["Accept-Language"] = storage.getLanguage();
  const authorization = storage.getAccessToken();
  if (authorization) {
    // TODO Update me
    request.headers = {
      Authorization: `Bearer ${authorization}`,
    };
    request.headers.token = authorization;
  }
  if (request.method === "post") {
    request.data = trimValueObject(request.data);
  }
  return request;
});

axiosClient.interceptors.response.use(
  (response) => {
    // TODO: should return response with data not empty

    let check = false;
    if (
      (window.location.host.match("daily") &&
        response.data?.data?.user?.userRole === "dealer") ||
      (window.location.host.match("nhacungcap") &&
        response.data?.data?.user?.userRole === "supplier") ||
      (window.location.host.match("admin") &&
        response.data?.data?.user?.userRole === "admin") ||
      (window.location.host.match("fi") &&
        response.data?.data?.user?.userRole === "finance")
    ) {
      check = true;
    }
    if (window.location.host.match("localhost")) {
    } else {
      if (response.request.responseURL.match("login") && !check) {
        return Promise.reject({
          message: "Wrong authorization",
        });
      }
      if (
        response.request.responseURL.match("login") &&
        response?.data?.data?.user?.blockAt
      ) {
        return Promise.reject({
          message:
            "Your account is blocked. If you need further assistance, please contact +62-878-BLIMOBIL (25466245)",
        });
      }
    }
    if (
      [200, 201].includes(response.status) ||
      [204].includes(response.status)
    ) {
      return response.data;
    }
    return Promise.reject(response?.statusText || "");
  },
  async (error) => {
    const originalRequest = error.config;
    const { data, status } = error.response;
    if (status === 401) {
      message.error(
        "Your account is blocked. If you need further assistance, please contact ..."
      );
      localStorage.clear();
      window.location.replace("/");
      return;
    }
    const payload = JSON.parse(error.config.data);
    return Promise.reject({ ...error?.response?.data, payload });
  }
);

export default axiosClient;
