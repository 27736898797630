import Urls from 'consts/Urls';
import axiosClient from './axios';
import { removeKeyOjectValueEmpty } from 'utils/common';

const CategoryService = {
  CATEGORY_LIST: async (params) => {
    delete params.recordTotal;
    delete params.pageTotal;
    const newParams = removeKeyOjectValueEmpty(params);
    return await axiosClient.get(Urls.CATEGORY_LIST, { params: newParams });
  },
  CREATE_CATEGORY: async (params) => {
    return await axiosClient.post(Urls.CREATE_CATEGORY, params);
  }
};
export default CategoryService;
