import Urls from "../consts/Urls";
import axiosClient from "./axios";

const ConfigService = {
    BRAND_LIST: async(params)=>{
        return await axiosClient.get(
            `${Urls.BRAND_LIST}`, { params }
        )
    },
    BRAND_LIST_v2: async(params)=>{
        return await axiosClient.get(
            `${Urls.BRAND_LIST_v2}`
        )
    },
    CREATE_BRAND: async(params)=>{
        return await axiosClient.post(`${Urls.CREATE_BRAND}`, params)
    },
    ADD_COLOR: async(params)=>{
        return await axiosClient.post(`/${params.brandId}${Urls.ADD_COLOR}`, params)
    },
    GET_COLOR: async(params)=>{
        return await axiosClient.get(`/${params.brandId}${Urls.GET_COLOR}`)
    },
    ADD_COLOR_v2: async(params)=>{
        const { brandId, ...remainingParams } = params
        return await axiosClient.post(`v1/color/brand/${brandId}`, remainingParams)
    },
    GET_COLOR_v2: async(params)=>{
        return await axiosClient.get(`v1/color/${params.brandId}/list`)
    }


};
export default ConfigService;