import Urls from '../consts/Urls';
import axiosClient from './axios';

const ConfigService = {
  NOTIFICATION_LIST: async (params) => {
    return await axiosClient.get(Urls.NOTIFICATION);
  },
  MARK_NOTIFICATION_READ: async (params) => {
    return await axiosClient.post(Urls.NOTIFICATION, params);
  },
};
export default ConfigService;
