import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    purchaseListAction: ["params"],
    purchaseListSuccess: ["data"],

    purchaseDetailAction: ["purchaseCode", "callback"],
    purchaseDetailSuccess: ["data"],

    purchaseUpdateAction: ["purchaseCode", "params", "callback"],
    purchaseUpdateSuccess: [],

    purchaseFailure: []
  },
  {}
);
export default Creators;
