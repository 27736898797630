import { call, put, takeLatest, fork, all } from "redux-saga/effects";

import { NotificationService } from "service";
import NotificationAction,{ Types } from "./actions"
import { sagaWrapper,  sagaErrorHandler } from "utils/common";

function* getNotificationList() {
  yield takeLatest(
    Types.NOTIFICATION_LIST_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(NotificationService.NOTIFICATION_LIST, action?.params);
      yield put(NotificationAction.notificationListSuccess({...result, params: action.params}));
    }, errorHandle(Types.NOTIFICATION_FAIL))
  );
}

function* markNotificationRead() {
  yield takeLatest(
    Types.MARK_NOTIFICATION_READ_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(NotificationService.MARK_NOTIFICATION_READ, action?.params);
      yield put(NotificationAction.markNotificationReadSuccess({...result, params: action.params}));
    }, errorHandle(Types.NOTIFICATION_FAIL))
  );
}

function errorHandle(errorActionType) {
  return sagaErrorHandler(function* (e, action) {
    yield put({ type: errorActionType, sessionId: action?.sessionId });
  });
}

export default function* rootSaga() {
  yield all([
    fork(getNotificationList),
    fork(markNotificationRead),
  ]);
}