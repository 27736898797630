import React from "react";

export default function DollarSvg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.46712 1.06667V0H8.53379V1.06667H10.1338C12.1957 1.06667 13.8671 2.73814 13.8671 4.8H12.8005C12.8005 3.32724 11.6065 2.13333 10.1338 2.13333H5.86712C4.39436 2.13333 3.20046 3.32724 3.20046 4.8C3.20046 6.27276 4.39436 7.46667 5.86712 7.46667H10.1338C12.1957 7.46667 13.8671 9.13814 13.8671 11.2C13.8671 13.2619 12.1957 14.9333 10.1338 14.9333H8.53379V16H7.46712V14.9333H5.86712C3.80526 14.9333 2.13379 13.2619 2.13379 11.2H3.20046C3.20046 12.6728 4.39436 13.8667 5.86712 13.8667H10.1338C11.6065 13.8667 12.8005 12.6728 12.8005 11.2C12.8005 9.72724 11.6065 8.53333 10.1338 8.53333H5.86712C3.80526 8.53333 2.13379 6.86186 2.13379 4.8C2.13379 2.73814 3.80526 1.06667 5.86712 1.06667H7.46712Z"
        fill="currentColor"
      />
    </svg>
  );
}
