import React from "react";

export default function CarSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3337 8.66227H19.8337V8.5237L19.7623 8.4049L19.3337 8.66227ZM0.666992 8.66227L0.238321 8.4049L0.166992 8.5237V8.66227H0.666992ZM3.50161 3.94102L3.07294 3.68365L3.50161 3.94102ZM16.499 3.94102L16.9277 3.68365V3.68365L16.499 3.94102ZM4.66699 15.3333V14.8333H4.16699V15.3333H4.66699ZM15.3337 15.3333H15.8337V14.8333H15.3337V15.3333ZM18.8337 8.66227V16.6667H19.8337V8.66227H18.8337ZM1.09566 8.91964L3.93028 4.19839L3.07294 3.68365L0.238321 8.4049L1.09566 8.91964ZM6.93099 2.5H13.0697V1.5H6.93099V2.5ZM16.0704 4.19839L18.905 8.91964L19.7623 8.4049L16.9277 3.68365L16.0704 4.19839ZM3.33366 17.5H2.00033V18.5H3.33366V17.5ZM4.16699 15.3333V16.6667H5.16699V15.3333H4.16699ZM18.0003 17.5H16.667V18.5H18.0003V17.5ZM15.8337 16.6667V15.3333H14.8337V16.6667H15.8337ZM15.3337 14.8333H4.66699V15.8333H15.3337V14.8333ZM1.16699 16.6667V8.66227H0.166992V16.6667H1.16699ZM16.667 17.5C16.2068 17.5 15.8337 17.1269 15.8337 16.6667H14.8337C14.8337 17.6792 15.6545 18.5 16.667 18.5V17.5ZM3.33366 18.5C4.34618 18.5 5.16699 17.6792 5.16699 16.6667H4.16699C4.16699 17.1269 3.7939 17.5 3.33366 17.5V18.5ZM2.00033 17.5C1.54009 17.5 1.16699 17.1269 1.16699 16.6667H0.166992C0.166992 17.6792 0.987803 18.5 2.00033 18.5V17.5ZM13.0697 2.5C14.2987 2.5 15.4377 3.14467 16.0704 4.19839L16.9277 3.68365C16.1143 2.32886 14.6499 1.5 13.0697 1.5V2.5ZM3.93028 4.19839C4.56294 3.14467 5.70193 2.5 6.93099 2.5V1.5C5.35077 1.5 3.88635 2.32886 3.07294 3.68365L3.93028 4.19839ZM18.8337 16.6667C18.8337 17.1269 18.4606 17.5 18.0003 17.5V18.5C19.0128 18.5 19.8337 17.6792 19.8337 16.6667H18.8337ZM2.66699 13.1667H6.66699V12.1667H2.66699V13.1667ZM17.3337 12.1667H13.3337V13.1667H17.3337V12.1667ZM4.00033 9.16667H16.0003V8.16667H4.00033V9.16667Z"
        fill="currentColor"
      />
    </svg>
  );
}
