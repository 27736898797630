import React from "react";

export default function DeliveryorPickupSvg() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.92972 3.67663C3.77326 2.27167 5.29192 1.41211 6.93066 1.41211H13.0693C14.7081 1.41211 16.2267 2.27167 17.0703 3.67663L20 8.55629V16.7454C20 17.85 19.1046 18.7454 18 18.7454H16.6667C15.5621 18.7454 14.6667 17.85 14.6667 16.7454V16.0788H5.33333V16.7454C5.33333 17.85 4.4379 18.7454 3.33333 18.7454H2C0.89543 18.7454 0 17.85 0 16.7454V8.55629L2.92972 3.67663ZM16 9.41211H4V8.07878H16V9.41211ZM2.66667 13.4121H6.66667V12.0788H2.66667V13.4121ZM17.3333 12.0788H13.3333V13.4121H17.3333V12.0788Z"
        fill="currentColor"
      />
    </svg>
  );
}
