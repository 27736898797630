import { createReducer } from "reduxsauce";
import storage from "../../utils/localStorage";
import { Types } from "./actions";

const INITIAL_STATE = {
  isFetching: false,
  idToken: storage.getAccessToken()
};

export const signUpAction = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: true
});

export const signUpSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false
});

export const signInAction = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: true
});

export const signInSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false
});
export const changePasswordAction = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true
});

export const changePasswordSuccess = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false
});
export const getCodeAction = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true
});

export const getCodeSuccess = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false
});
export const getLinkAction = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true
});

export const getLinkSuccess = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false
});
export const resetPassWithCodeAction = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true
});

export const resetPassWithCodeSuccess = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false
});

export const resetPassWithLinkAction = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true
});

export const resetPassWithLinkSuccess = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false
});

export const login = (state = INITIAL_STATE, action) => ({
  ...state,
  idToken: action.token
});

export const signUpDealerAction = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: true
});

export const signUpDealerSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false
});

export const signInDealerAction = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: true
});

export const signInDealerSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false
});

export const logOut = (state = INITIAL_STATE, action) => ({
  ...state,
  idToken: null
});

export const authorizationFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false
});

export const HANDLERS = {
  [Types.SIGN_UP_ACTION]: signUpAction,
  [Types.SIGN_UP_SUCCESS]: signUpSuccess,
  [Types.SIGN_IN_ACTION]: signInAction,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.CHANGE_PASSWORD_ACTION]: changePasswordAction,
  [Types.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [Types.GET_CODE_ACTION]: getCodeAction,
  [Types.GET_CODE_SUCCESS]: getCodeSuccess,
  [Types.GET_LINK_ACTION]: getLinkAction,
  [Types.GET_LINK_SUCCESS]: getLinkSuccess,
  [Types.RESET_PASS_WITH_CODE_ACTION]: resetPassWithCodeAction,
  [Types.RESET_PASS_WITH_CODE_SUCCESS]: resetPassWithCodeSuccess,
  [Types.RESET_PASS_WITH_LINK_ACTION]: resetPassWithLinkAction,
  [Types.RESET_PASS_WITH_LINK_SUCCESS]: resetPassWithLinkSuccess,
  [Types.SIGN_UP_DEALER_ACTION]: signUpDealerAction,
  [Types.SIGN_UP_DEALER_SUCCESS]: signUpDealerSuccess,
  [Types.SIGN_IN_DEALER_ACTION]: signInDealerAction,
  [Types.SIGN_IN_DEALER_SUCCESS]: signInDealerSuccess,
  [Types.LOGIN]: login,
  [Types.LOG_OUT]: logOut,
  [Types.AUTHORIZATION_FAILURE]: authorizationFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
