import _get from 'lodash/get';
import _map from 'lodash/map';
import { createReducer } from 'reduxsauce';

import { Types } from './actions';
import { PAGINATION } from 'consts/Enum';

const INITIAL_STATE = {
  isFetching: false,
  notificationList: [],
  params: {
    page: PAGINATION.page,
    recordPerPage: PAGINATION.recordPerPage,
  },
};

export const notificationListAction = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: true,
  };
};

export const notificationListSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    params: { ...state.params, ...action.data.params },
    isFetching: false,
    notificationList: action.data,
  };
};

export const markNotificationReadAction = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: true,
  };
};

export const markNotificationReadSuccess = (state = INITIAL_STATE, action) => {
  const idList = _get(action, 'data.params.ids', []);
  if (idList.length === 1) {
    const newNotificationList = _map(
      _get(state, 'notificationList.data', []),
      (item) => {
        if (item._id === idList[0]) {
          return {
            ...item,
            read: 1,
          };
        }
        return item;
      }
    );
    return {
      ...state,
      notificationList: {
        ...state.notificationList,
        data: newNotificationList
      }
    };
  }
  return {
    ...state,
  };
};

export const notificationFail = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
});

export const HANDLERS = {
  [Types.NOTIFICATION_LIST_ACTION]: notificationListAction,
  [Types.NOTIFICATION_LIST_SUCCESS]: notificationListSuccess,
  [Types.MARK_NOTIFICATION_READ_ACTION]: markNotificationReadAction,
  [Types.MARK_NOTIFICATION_READ_SUCCESS]: markNotificationReadSuccess,
  [Types.NOTIFICATION_FAIL]: notificationFail,
};

export default createReducer(INITIAL_STATE, HANDLERS);
