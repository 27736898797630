import Urls from "../consts/Urls";
import { removeKeyOjectValueEmpty } from "../utils/common";
import axiosClient from "./axios";

const UserService = {
  LIST_USER_ADIM: async (params) => {
    const newPamrams = removeKeyOjectValueEmpty(params);
    return await axiosClient.get(Urls.LIST_USER_ADMIN, { params: newPamrams });
  },
  DETAIL_USER: async (userCode) => {
    if (userCode) {
      return await axiosClient.get(`${Urls.DETAIL_USER}/${userCode}/profile`);
    }
    return await axiosClient.get(`${Urls.DETAIL_PROFILE}`);
  },
  STATIC_USER: async () => {
    return await axiosClient.get(Urls.STATIC_USER);
  },
  STATIC_USER_MERCHANT: async (params) => {
    return await axiosClient.get(Urls.STATIC_USER_MERCHANT, { params });
  },
  UPDATE_USER: async (params) => {
    return await axiosClient.post(Urls.UPDATE_USER, params);
  }
};

export default UserService;
