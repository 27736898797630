import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    requestListAction: ["params"],
    requestListSuccess: ["data"],

    requestDetailAction: ["requestCode"],
    requestDetailSuccess: ["data"],

    requestFinanceUpdateStatusAction: [
      "orderCode",
      "typeOrder",
      "params",
      "paramsList",
      "callback"
    ],
    requestFinanceUpdateStatusSuccess: [],

    createFinanceAction: ["params", "callback"],
    createFinanceSuccess: ["data"],

    requestFailure: []
  },
  {}
);
export default Creators;
