import React from "react";

export default function CompanySvg() {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 0.997559L6.85355 0.644005C6.65829 0.448743 6.34171 0.448743 6.14645 0.644005L6.5 0.997559ZM0.5 6.99756L0.146447 6.64401L0 6.79045V6.99756H0.5ZM12.5 6.99756H13V6.79045L12.8536 6.64401L12.5 6.99756ZM12.8536 6.64401L6.85355 0.644005L6.14645 1.35111L12.1464 7.35111L12.8536 6.64401ZM6.14645 0.644005L0.146447 6.64401L0.853553 7.35111L6.85355 1.35111L6.14645 0.644005ZM13 13.9976V6.99756H12V13.9976H13ZM0 6.99756V13.9976H1V6.99756H0ZM1.5 15.4976H11.5V14.4976H1.5V15.4976ZM12 13.9976C12 14.2737 11.7761 14.4976 11.5 14.4976V15.4976C12.3284 15.4976 13 14.826 13 13.9976H12ZM0 13.9976C0 14.826 0.671573 15.4976 1.5 15.4976V14.4976C1.22386 14.4976 1 14.2737 1 13.9976H0Z"
        fill="#666666"
      />
    </svg>
  );
}
