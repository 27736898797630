import { createReducer } from 'reduxsauce';

import { PAGINATION } from 'consts/Enum';
import { Types } from './actions';

const INITIAL_STATE = {
  isFetching: false,
  preOrderList: [],
  params: {
    page: PAGINATION.page,
    recordPerPage: PAGINATION.recordPerPage,
  },
  requestList: [],
};

export const preOrderListAction = (state = INITIAL_STATE) => {
  return {
    ...state,
    isFetching: true,
  };
};

export const preOrderListSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  preOrderList: action?.data?.data,
});

export const requestListForSupplierAction = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const requestListForSupplierSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  requestList: action.data.data,
});

export const createPreOrderAction = (state = INITIAL_STATE) =>({
  ...state,
  isFetching: true,
})

export const createPreOrderSuccess = (state = INITIAL_STATE) =>({
  ...state,
  isFetching: false,
})

export const sendOfferAction = (state = INITIAL_STATE) =>({
  ...state,
  isFetching: true,
})

export const sendOfferSuccess = (state = INITIAL_STATE) =>({
  ...state,
  isFetching: false,
})

export const cancelRequestAction = (state = INITIAL_STATE) =>({
  ...state,
  isFetching: true,
})

export const cancelRequestSuccess = (state = INITIAL_STATE) =>({
  ...state,
  isFetching: false,
})

export const completedRequestAction = (state = INITIAL_STATE) =>({
  ...state,
  isFetching: true,
})

export const completedRequestSuccess = (state = INITIAL_STATE) =>({
  ...state,
  isFetching: false,
})

export const preOrderFailure = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

export const HANDLERS = {
  [Types.PRE_ORDER_LIST_ACTION]: preOrderListAction,
  [Types.PRE_ORDER_LIST_SUCCESS]: preOrderListSuccess,
  [Types.REQUEST_LIST_FOR_SUPPLIER_ACTION]: requestListForSupplierAction,
  [Types.REQUEST_LIST_FOR_SUPPLIER_SUCCESS]: requestListForSupplierSuccess,
  [Types.CREATE_PRE_ORDER_ACTION]: createPreOrderAction,
  [Types.CREATE_PRE_ORDER_SUCCESS]: createPreOrderSuccess,
  [Types.SEND_OFFER_ACTION]: sendOfferAction,
  [Types.SEND_OFFER_SUCCESS]: sendOfferSuccess,
  [Types.CANCEL_REQUEST_ACTION]: cancelRequestAction,
  [Types.CANCEL_REQUEST_SUCCESS]: cancelRequestSuccess,
  [Types.PRE_ORDER_FAILURE]: preOrderFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
