import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    marketplaceListAction: ["params", "callback"],
    marketplaceListSuccess: ["data"],

    marketplaceDetailAction: ["code"],
    marketplaceDetailSuccess: ["data"],

    marketplaceBookingAction: ["params"],
    marketplaceBookingSuccess: ["data"],

    marketplaceBookingCreateAction: ["params", "callback"],
    marketplaceBookingCreateSuccess: ["data"],

    marketplaceUpdateFilterAction: ["params"],
    marketplaceUpdateFilterSuccess: ["data"],

    marketplaceUpdateFilterApplyAction: ["params"],
    marketplaceUpdateFilterApplySuccess: ["data"],

    marketplaceFailure: [],
    marketplaceListFailure: []
  },
  {}
);
export default Creators;
