import React from "react";

export default function BodyTypeSvg() {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.703 0C13.371 0 13.988 0.334 14.3595 0.8905L15.75 2.969C15.9136 3.21408 16.0007 3.50231 16 3.797V7.1095C16.0009 7.33146 15.9276 7.54735 15.7917 7.72284C15.6558 7.89833 15.4651 8.02335 15.25 8.078L13.953 8.4065C13.8597 8.85587 13.6147 9.25949 13.2591 9.54963C12.9035 9.83978 12.4589 9.99879 12 10C11.074 10 10.301 9.3595 10.078 8.5H5.42199C5.19899 9.3595 4.42599 10 3.49999 10C2.57399 10 1.80099 9.3595 1.57799 8.5H0.999991C0.452991 8.5 -9.53674e-06 8.047 -9.53674e-06 7.5V5.672C0.000469208 5.22633 0.149869 4.79359 0.424451 4.44255C0.699033 4.09151 1.08305 3.8423 1.51549 3.7345L4.21899 3.047L6.17199 0.719C6.35882 0.494189 6.59283 0.313238 6.85741 0.188981C7.122 0.0647246 7.41068 0.00020565 7.70299 0H12.703ZM12.703 1H10.5V3H14.5625L13.531 1.453V1.4375C13.4404 1.30144 13.3173 1.19015 13.1728 1.11375C13.0282 1.03735 12.8669 0.998249 12.7035 1H12.703ZM9.5 1H7.703C7.4065 1 7.127 1.131 6.9375 1.3595L5.5625 3H9.5V1ZM4.5625 4H15V7.11L13.8905 7.3905C13.631 6.586 12.8865 6 12 6C11.074 6 10.301 6.6405 10.078 7.5H5.422C5.199 6.6405 4.426 6 3.5 6C2.574 6 1.801 6.6405 1.578 7.5H1V5.672C0.997731 5.44965 1.07054 5.23303 1.20664 5.05719C1.34274 4.88134 1.53418 4.75655 1.75 4.703L4.5625 4ZM11 8C11 7.4415 11.4415 7 12 7C12.5585 7 13 7.4415 13 8C13 8.5585 12.5585 9 12 9C11.4415 9 11 8.5585 11 8ZM2.5 8C2.5 7.4415 2.9415 7 3.5 7C4.0585 7 4.5 7.4415 4.5 8C4.5 8.5585 4.0585 9 3.5 9C2.9415 9 2.5 8.5585 2.5 8Z"
        fill="#666666"
      />
    </svg>
  );
}
