import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    merchantListAction: ["params", "callback"],
    merchantListSuccess: ["data"],

    merchantCreateAction: ["params", "callback"],
    merchantCreateSuccess: ["data"],

    merchantBankCreateAction: ["params", "merchantCode", "callback"],
    merchantBankCreateSuccess: ["data"],

    merchantVerifyAction: ["params", "merchantCode", "callback"],
    merchantVerifySuccess: [],

    merchantBlockAction: ["merchantCode", "params", "callback"],
    merchantBlockSuccess: [],

    merchantDetailAction: ["merchantCode", "callback"],
    merchantDetailSuccess: ["data"],

    merchantUpdateStatusAction: ["data", "codeMerchant", "callback"],
    merchantUpdateStatusSuccess: [],

    resetUserFinance: [],

    merchantFailure: []
  },
  {}
);
export default Creators;
