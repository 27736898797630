import Urls from "../consts/Urls";
import axiosClient from "./axios";

const MerchantService = {
  CREATE_MERCHANT: async (params) => {
    return await axiosClient.post(Urls.CREATE_MERCHANT, params);
  },
  CREATE_MERCHANT_V2: async (params) => {
    return await axiosClient.post(Urls.CREATE_MERCHANT_V2, params);
  },
  CREATE_MERCHANT_V3: async (params) => {
    return await axiosClient.post(Urls.CREATE_MERCHANT_V3, params);
  },
  LIST_MERCHANT: async (params) => {
    return await axiosClient.get(Urls.LIST_MERCHANT, { params });
  },
  DETAIL_MERCHANT: async (merchantCode) => {
    return await axiosClient.get(`${Urls.DETAIL_MERCHANT}/${merchantCode}`);
  },
  UPDATE_STATUS_MERCHANT: async (params, merchantCode) => {
    return await axiosClient.post(
      `${Urls.CHANGE_STATUS_MERCHANT}/${merchantCode}`,
      params
    );
  },
  CREATE_MERCHANT_BANK: async (params, merchantCode) => {
    return await axiosClient.post(
      `${Urls.CREATE_BANK_MERCHANT}/${merchantCode}/update`,
      params
    );
  },
  CREATE_MERCHANT_BANK_V2: async (params, merchantCode) => {
    return await axiosClient.post(
      `${Urls.CREATE_BANK_MERCHANT_V2}/${merchantCode}/update`,
      params
    );
  },
  CREATE_MERCHANT_BANK_V3: async (params, merchantCode) => {
    return await axiosClient.post(
      `${Urls.CREATE_BANK_MERCHANT_V3}/${merchantCode}/update`,
      params
    );
  },
  VERIFY_MERCHANT: async (params, merchantCode) => {
    return await axiosClient.post(
      `${Urls.VERIFY_MERCHANT}/${merchantCode}/verify`,
      params
    );
  },
  BLOCK_MERCHANT: async (merchantCode, params) => {
    return await axiosClient.post(
      `${Urls.BLOCK_USER}/${merchantCode}/block`,
      params
    );
  },
};

export default MerchantService;
