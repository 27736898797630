import { call, put, takeLatest, fork, all, takeLeading, takeEvery, delay } from "redux-saga/effects";
import { ConfigService } from "../../service";
import { errorHandler, promiseUpload, sagaWrapper} from "../../utils/common";
import BrandAction,{Types} from "./actions"
import _isEmpty from "lodash/isEmpty";
import { TYPE_FILE } from "consts/Enum";

function* getBrandList() {
  yield takeLatest(
    Types.BRAND_LIST_ACTION,
    sagaWrapper(function* (){
      const result = yield call(ConfigService.BRAND_LIST, { all: true});
       yield put(BrandAction.brandListSuccess(result))
    }), errorHandler(Types.BRAND_FAILED)
  );
}

function* createBrand(){
  yield takeLeading(
    Types.BRAND_CREATE_ACTION,
    sagaWrapper(function* (action){
      const oldPhotos = action.params.photos;
      delete action.params.photos;
      const result = yield call(ConfigService.CREATE_BRAND,action?.params)
      if (oldPhotos?.length > 0) {
        const imagesLocal = oldPhotos.filter((el) => el.originFileObj);
        const imageList = imagesLocal.filter((item) =>
          item.originFileObj.type.match("image")
        );
        if (!_isEmpty(imageList)) {
          yield promiseUpload(result.data.code, TYPE_FILE.BRAND, imageList);
        }
      }
      delay(1000);
      yield put(BrandAction.createBrandSuccess(result))
      action.callback && action.callback();
     })
  );
}

function* addColor(){
  yield takeLeading(
    Types.ADD_COLOR_ACTION,
    sagaWrapper(function* (action){
      const result = yield call(ConfigService.ADD_COLOR_v2,action?.params)
      yield put(BrandAction.addColorSuccess(result))
      action?.callback && action.callback()
      })
  );
}

function* getColor(){
  yield takeEvery(
    Types.GET_COLOR_ACTION,
    sagaWrapper(function* (action){
      const result = yield call(ConfigService.GET_COLOR_v2,action?.params)
      yield put(BrandAction.getColorSuccess(result?.data))
    })
  );
}


export default function* rootSaga() {
  yield all([
    fork(createBrand),
    fork(getBrandList),
    fork(addColor),
    fork(getColor),
  ]);
}