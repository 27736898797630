import React from "react";

export default function EditSvg() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66699 18.6667L6.24273 18.2425L6.06699 18.4182V18.6667H6.66699ZM18.667 6.66675L19.0913 6.24248C18.8569 6.00817 18.477 6.00817 18.2427 6.24248L18.667 6.66675ZM25.3337 13.3334L25.7579 13.7577C25.9922 13.5234 25.9922 13.1435 25.7579 12.9092L25.3337 13.3334ZM13.3337 25.3334V25.9334H13.5822L13.7579 25.7577L13.3337 25.3334ZM6.66699 25.3334H6.06699C6.06699 25.6648 6.33562 25.9334 6.66699 25.9334V25.3334ZM7.09126 19.091L19.0913 7.09101L18.2427 6.24248L6.24273 18.2425L7.09126 19.091ZM18.2427 7.09101L24.9094 13.7577L25.7579 12.9092L19.0913 6.24248L18.2427 7.09101ZM24.9094 12.9092L12.9094 24.9092L13.7579 25.7577L25.7579 13.7577L24.9094 12.9092ZM13.3337 24.7334H6.66699V25.9334H13.3337V24.7334ZM7.26699 25.3334V18.6667H6.06699V25.3334H7.26699ZM14.2427 11.091L20.9094 17.7577L21.7579 16.9092L15.0913 10.2425L14.2427 11.091ZM16.667 25.9334H26.0003V24.7334H16.667V25.9334Z"
        fill="white"
      />
    </svg>
  );
}
