import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    categoryListAction: ['params'],
    categoryListSuccess: ['data'],

    createCategoryAction: ['params', 'callback'],
    createCategorySuccess: [],

    categoryFailure: [],
  },
  {}
);
export default Creators;
