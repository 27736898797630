import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    inventoryListAction: ["params"],
    inventoryListSuccess: ["data"],

    inventoryDetailAction: ["code"],
    inventoryDetailSuccess: ["data"],

    inventoryCountStatusAction: [],
    inventoryCountStatusSuccess: ["data"],

    inventoryDeleteAction: ["code", "params", "callback"],
    inventoryDeleteSuccess: [],

    inventoryCreateAction: ["params", "callback"],
    inventoryCreateSuccess: [],

    inventoryUpdateAction: ["params", "code", "callback"],
    inventoryUpdateSuccess: [],
    
    inventoryStatusUpdateAction: ["params", "code", "callback"],
    inventoryStatusUpdateSuccess: [],

    inventoryDetailReset: [],
    inventoryFailure: []
  },
  {}
);
export default Creators;
