import React, { Fragment, lazy, Suspense, useMemo } from "react";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  useLocation,
  Link,
} from "react-router-dom";
import { useSelector } from "react-redux";

import ErrorBoundary from "./ErrorBoundary";
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from "./route.constants";
import Loader from "@iso/components/utility/loader";
import { BackgroundAuth, BlockAuth, HeaderAuth } from "./auth.styles";
import { IconSvg } from "./components/ScrumBoard/IconSvg/IconSvg";
import { IconCarhay } from "./assets/images/blimobil";
import useWindowSize from "./hooks/useWindowSize";
import { size } from "./consts/Enum";
import { Layout } from "antd";
import UIColor from "./colors";
import Scrollbar from "./components/utility/customScrollBar";
import styled from "styled-components";
import LanguageSwitcher from "containers/LanguageSwitcher/LanguageSwitcher";

const BUYER_ROUTE = 'https://carhay.vn';

const ScrollbarStyled = styled(Scrollbar)`
  height: calc(100vh - 58px - 47px);
`;

const { Footer } = Layout;

const styles = {
  footer: {
    background: UIColor.bgHeader,
    textAlign: "center",
    borderTop: "1px solid #ededed",
    color: "white",
    padding: "12px",
    position: "fixed",
    bottom: 0,
    width: "100%",
    fontSize: 12,
  },
};

const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));
const StorePage = lazy(() => import("./page/Store"));
const EditProfilePage = lazy(() => import("./page/Profile/Edit"));
const SignInPage = lazy(() => import("../src/page/Auth/SignIn"));
const SignInPhonePage = lazy(() =>
  import("../src/page/Auth/SignIn/SignInPhonePage")
);
const VerifyEmail = lazy(() => import("../src/page/Auth/Verify"));
const VerifyPhone = lazy(() =>
  import("../src/page/Auth/Verify/VerifyPhonePage")
);
const ForgotPasswordPage = lazy(() => import("page/Auth/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("page/Auth/ResetPassword"));

const ApproveEmail = lazy(() => import("./page/Auth/ApproveEmail"));

const authRoutesLocal = [
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import("../src/page/Auth/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import("../src/page/Auth/SignUp")),
  },
  {
    path: PUBLIC_ROUTE.VERIFY_EMAIL,
    component: VerifyEmail,
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP_PHONE,
    component: lazy(() => import("../src/page/Auth/SignUp/SignUpPhonePage")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN_PHONE,
    component: lazy(() => import("../src/page/Auth/SignIn/SignInPhonePage")),
  },
  {
    path: PUBLIC_ROUTE.VERIFY_PHONE,
    component: VerifyPhone,
  },
  {
    path: PUBLIC_ROUTE.APPROVE_EMAIL,
    component: ApproveEmail,
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: ForgotPasswordPage,
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: ResetPasswordPage,
  },
];

const authRoutes = [
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import("../src/page/Auth/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import("../src/page/Auth/SignUp")),
  },
  {
    path: PUBLIC_ROUTE.VERIFY_EMAIL,
    component: VerifyEmail,
  },
  {
    path: PUBLIC_ROUTE.APPROVE_EMAIL,
    component: ApproveEmail,
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: ForgotPasswordPage,
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: ResetPasswordPage,
  },
];

const authRoutesDealer = [
  {
    path: PUBLIC_ROUTE.SIGN_UP_PHONE,
    component: lazy(() => import("../src/page/Auth/SignUp/SignUpPhonePage")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN_PHONE,
    component: lazy(() => import("../src/page/Auth/SignIn/SignInPhonePage")),
  },
  {
    path: PUBLIC_ROUTE.VERIFY_PHONE,
    component: lazy(() => import("../src/page/Auth/Verify/VerifyPhonePage")),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: ForgotPasswordPage,
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: ResetPasswordPage,
  },
];

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import("@iso/containers/Pages/404/404")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_403,
    component: lazy(() => import("../src/page/403")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import("@iso/containers/Pages/500/500")),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    component: lazy(() =>
      import("@iso/containers/Authentication/Auth0/Auth0Callback")
    ),
  },
];
function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  const isVerifyPhone = useMemo(() => {
    return window.location.hostname.search("daily") >= 0;
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: isVerifyPhone ? "/signin-phone" : "/signin",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  const [sizeWindow, isMobile] = useWindowSize();
  const hostName = window.location.hostname;
  const isDealer = useMemo(() => {
    return hostName.search("daily") >= 0;
  }, [hostName]);

  const routerCondition = useMemo(() => {
    if (hostName.search("daily") >= 0) {
      return authRoutesDealer;
    }
    if (hostName.search("localhost") >= 0) {
      return authRoutesLocal;
    }
    return authRoutes;
  }, [hostName]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            <Route key={1} path={PUBLIC_ROUTE.LANDING} exact={true}>
              <BlockAuth>
                <HeaderAuth>
                      <a href="/#" onClick={(e) => {
                          e.preventDefault();
                          window.location.href = BUYER_ROUTE;
                      }}>
                    <IconSvg
                      width={123}
                      border="unset"
                      mr="0"
                      ml="0"
                      padding="0"
                      height={30}
                      onClick={() => {
                        console.log('123');
                      }}
                      src={isMobile ? IconCarhay : IconCarhay}
                    />
                  </a>
                  {/* <LanguageSwitcher showName={true} /> */}
                </HeaderAuth>
                <BackgroundAuth>
                  {isDealer ? <SignInPhonePage /> : <SignInPage />}
                </BackgroundAuth>
              </BlockAuth>
            </Route>
          </Switch>
          <Switch>
            {routerCondition.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <BlockAuth>
                  <HeaderAuth>
                    <a href="/#" onClick={(e) => {
                        e.preventDefault();
                        window.location.href = BUYER_ROUTE;
                    }}>
                      {sizeWindow.width <= size.xs ? (
                        <IconSvg
                          width={123}
                          border="unset"
                          mr="0"
                          ml="0"
                          padding="0"
                          height={30}
                          src={IconCarhay}
                        />
                      ) : (
                        <IconSvg
                          width={123}
                          border="unset"
                          mr="0"
                          ml="0"
                          padding="0"
                          height={30}
                          src={IconCarhay}
                        />
                      )}
                    </a>
                    {/* <LanguageSwitcher showName={true} /> */}
                  </HeaderAuth>
                  <BackgroundAuth>
                    <route.component />
                  </BackgroundAuth>
                  {sizeWindow.width <= size.xs && (
                    <Footer style={styles.footer}>
                      © 2023 Carhay. Tất cả các quyền được bảo lưu.
                    </Footer>
                  )}
                </BlockAuth>
              </Route>
            ))}
          </Switch>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path={PRIVATE_ROUTE.SET_UP_STORE}>
              <BlockAuth>
                <HeaderAuth>
                  <IconSvg
                    width={123}
                    border="unset"
                    mr="0"
                    ml="0"
                    padding="0"
                    height={30}
                    src={isMobile ? IconCarhay : IconCarhay}
                  />
                  {/* <LanguageSwitcher showName={true} /> */}
                </HeaderAuth>
                <BackgroundAuth>
                  <StorePage />
                </BackgroundAuth>
              </BlockAuth>
            </PrivateRoute>
            <PrivateRoute path={PRIVATE_ROUTE.SET_UP_ACCOUNT}>
              <BlockAuth>
                <HeaderAuth>
                  <IconSvg
                    width={123}
                    border="unset"
                    mr="0"
                    ml="0"
                    padding="0"
                    height={30}
                    src={isMobile ? IconCarhay : IconCarhay}
                  />
                  {/* <LanguageSwitcher showName={true} /> */}
                </HeaderAuth>
                <BackgroundAuth>
                  <EditProfilePage />
                </BackgroundAuth>
              </BlockAuth>
            </PrivateRoute>
            <PrivateRoute path={PRIVATE_ROUTE.STORE}>
              <BlockAuth>
                <HeaderAuth>
                  {sizeWindow.width <= size.xs ? (
                    <IconSvg
                      width={123}
                      border="unset"
                      mr="0"
                      ml="0"
                      padding="0"
                      height={30}
                      src={IconCarhay}
                    />
                  ) : (
                    <IconSvg
                      width={123}
                      border="unset"
                      mr="0"
                      ml="0"
                      padding="0"
                      height={30}
                      src={IconCarhay}
                    />
                  )}
                  {/* <LanguageSwitcher showName={true} /> */}
                </HeaderAuth>
                <BackgroundAuth>
                  <StorePage />
                </BackgroundAuth>
              </BlockAuth>
            </PrivateRoute>
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
