import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import { ReportService } from 'service';
import { sagaErrorHandler, sagaWrapper } from 'utils/common';
import ReportAction, { Types } from './actions';

function* getReportList() {
  yield takeLatest(
    Types.REPORT_LIST_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(ReportService.REPORT_LIST, action.params);
      const params = {
        page: result.data?.metadata[0]?.pageCurrent || 1,
        recordTotal: result?.data.metadata[0]?.recordTotal || 10,
        pageTotal:
          (result.data.metadata[0]?.recordTotal /
            result.data.metadata[0]?.recordPerPage) *
            10 || 1,
        recordPerPage: result.data.metadata[0]?.recordPerPage || 10,
      };
      yield put(ReportAction.reportListSuccess({ data: result.data, params }));
    }, errorHandle(Types.REPORT_FAILURE))
  );
}

function* getInvoiceList() {
  yield takeLatest(
    Types.INVOICE_LIST_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(ReportService.INVOICE_LIST, action?.params);
      const params = {
        page: result.data?.metadata[0]?.pageCurrent || 1,
        recordTotal: result?.data?.metadata[0]?.recordTotal || 10,
        pageTotal:
          (result?.data?.metadata[0]?.recordTotal /
            result?.data?.metadata[0]?.recordPerPage) *
            10 || 1,
        recordPerPage: result?.data?.metadata[0]?.recordPerPage || 10,
      };
      if(action.params?.productType){
        params.productType = action?.params?.productType;
      }
      yield put(ReportAction.invoiceListSuccess({ data: result?.data, params }));
    }, errorHandle(Types.REPORT_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return sagaErrorHandler(function* (e, action) {
    yield put({ type: errorActionType, sessionId: action?.sessionId });
  });
}

export default function* rootSaga() {
  yield all([fork(getReportList)]);
  yield all([fork(getInvoiceList)]);
}
