import language from "@iso/config/language.config";
import { EnglishFlag, VietnamFlag } from "../../assets/images/blimobil";

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: "vietnam",
      locale: "vi",
      text: "Vietnam",
      icon: VietnamFlag,
    },
    {
      languageId: "english",
      locale: "en",
      text: "English",
      icon: EnglishFlag,
    },
  ],
  flag: {
    en: EnglishFlag,
    vi: VietnamFlag,
  },
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach((language) => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
