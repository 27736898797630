export default {
  API_BASE_URL: process.env.REACT_APP_API_URL,
  URL_FILE: process.env.REACT_APP_URL_FILE,
  BUYER_URL: process.env.REACT_APP_BUYER_URL,

  //INVENTORY
  INVENTORY_LIST: "v1/car/merchant/my-merchant",
  INVENTORY_DETAIL: "v1/car/dealer/code",
  INVENTORY_CATE: "v1/category-type/list",
  INVENTORY_COUNT_STATUS: "v1/car/count-status",
  INVENTORY_UPDATE: "v1/car/code",
  INVENTORY_UPDATE_V2: "v2/car/code",
  INVENTORY_STATUS_V2: "v2/car/status/code",
  INVENTORY_CREATE: "v2/car",
  INVENTORY_DELETE: "v1/car/code",
  LIST_LOCATION: "v1/location",
  LIST_BRAND: "v1/brand/list",
  LIST_MODEL: "v1/model/list",
  INVENTORY_PRICING: "v1/pricing",
  INVENTORY_PRICING_QUOTE: "v1/merchant",

  //AUTH
  SIGN_UP: "v1/user/register",
  SIGN_IN: "v1/user/login",
  CHANGE_PASSWORD: "v1/user/profile/change-password",
  SIGN_UP_DEALER: "v1/user/register-by-phone",
  SIGN_IN_DEALER: "v1/user/login-by-phone",
  VERIFY_PHONE: "v1/user/phone-verify",
  RESEND_OTP_PHONE: "v1/user/get-phone-verify-code",
  GET_VERIFY_EMAIL: "v1/user/get-email-verify-link",
  VERIFY_EMAIL: "v1/user/email-verify",
  GET_CODE: "v1/user/password/get-code",
  GET_LINK: "v1/user/password/get-link",
  RESET_PASS_WITH_CODE: "v1/user/new-password-with-code",
  RESET_PASS_WITH_Link: "v1/user/new-password-with-link",
  //MERCHANT
  CREATE_MERCHANT: "v1/create/merchant",
  CREATE_MERCHANT_V2: "v2/create/merchant",
  CREATE_MERCHANT_V3: "v3/create/merchant",
  LIST_MERCHANT: "v1/admin/merchant/listing",
  DETAIL_MERCHANT: "v1/admin/merchant",
  CHANGE_STATUS_MERCHANT: "v1/admin/merchant",
  CREATE_BANK_MERCHANT: "v1/merchant",
  CREATE_BANK_MERCHANT_V2: "v2/merchant",
  CREATE_BANK_MERCHANT_V3: "v3/merchant",
  VERIFY_MERCHANT: "v1/admin/user",

  //ORDER
  ORDER_LIST_DEALER: "v1/dealer/order/listing",
  ORDER_LIST_SUPPLIER: "v1/supplier/order/listing",
  ORDER_LIST_ADMIN: "v1/admin/order/listing",
  ORDER_DETAIL: "v1/order",
  ORDER_DETAIL_ADMIN: "v1/admin/order",
  UPDATE_STATUS_ORDER_ADMIN: "v1/admin/order",
  PRE_ORDER_INFO: "v1/dealer",
  ORDER_STATISTIC_DEALER: "v1/purchase/statistic",
  ORDER_STATISTIC_BUYER: "v1/order/statistic",
  UPDATE_STATUS_ORDER_BUYER: "v1/dealer/order",

  //ADMIN - USER
  LIST_USER_ADMIN: "v1/admin/account/listing",
  DETAIL_USER: "v1/admin/user",
  DETAIL_PROFILE: "v1/user/profile",
  STATIC_USER: "v1/admin/user/statistic",
  UPDATE_USER: "v1/user/profile",
  STATIC_USER_MERCHANT: "v1/admin/merchant/stats",
  BLOCK_USER: "v1/admin/user",

  //UPLOAD
  UPLOAD_FILE: "v1/file/upload",
  UPLOAD_FILE_V2: "v1/file/upload-with-watermark",
  GET_PRESIGNED_URL: "v1/file/get-pre-signed-url",
  DELETE_FILE: "v1/file/",
  DELETE_FILE_V2: "v1/file-upload/",
  DELETE_MERCHANT_FILE: "v1/merchant/file/",

  //CHAT
  CHAT_LIST: "v1/conv/listing",
  CHAT_DETAIL: "v1/conv",
  CHAT_CREATE: "v1/conv/create",
  CHAT_ORDER_CREATE: "v1/conv/by-order",
  SEND_MESSAGE: "v1/conv",
  CHAT_ORDER_CAR: "v1/conv/by-car",

  //MARKETPLACE
  MARKETPLACE_LIST: "v1/marketplace/dealer",
  CREATE_ORDER_MARKETPLACE: "v1/dealer/create-order",
  INFO_DEALER_AS_BUYER: "v1/car/dealer-as-buyer/code",
  USED_CAR_MARKETPLACE_LIST: "v1/car/buyer/list",
  NEW_CAR_MARKETPLACE_LIST: "v1/marketplace/dealer",

  //PURCHASE
  PURCHASE_LIST: "v1/dealer/purchase/listing",
  PURCHASE_DETAIL: "v1/purchase",
  PURCHASE_UPDATE: "v1/purchase",
  PURCHASE_ADMIN_UPDATE: "v1/admin/order",

  //FINANCE
  FINANCE_LIST_DEALER: "v1/dealer/finance/listing",
  FINANCE_LIST: "v1/finance/order/listing",
  FINANCE_DETAIL: "v1/finance/order",
  UPDATE_STATUS_REQUEST_FINANCE: "v1/admin/order",
  STATIC_REQUEST: "v1/finance/order/statistic",
  FINACE_CREATE: "v1/admin/user/create-finance",

  //BRAND
  BRAND_LIST: "v1/brand/list",
  BRAND_LIST_v2: "v2/brand/list",
  CREATE_BRAND: "v1/brand",
  ADD_COLOR: "v1/color",
  GET_COLOR: "v1/color/list",

  //MODEL
  MODEL_LIST: "v1/model/list",
  CONNECT_BRAND: "v1/model",

  //NOTIFICATION
  NOTIFICATION: "v1/user/notifications",

  //REPORT
  REPORT_LIST: "v1/admin/accounting/transactions",
  INVOICE_LIST: "v1/admin/invoices",

  //CATEGORY
  CATEGORY_LIST: "v1/category-type/list",
  CREATE_CATEGORY: "v1/category-type",

  //PRE-ORDER
  PRE_ORDER_LIST: "v1/request/my-request",
  PRE_ORDER_CREATE: "v1/car-request",
  REQUEST_LIST: "v1/requests",
};
