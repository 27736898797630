import { TYPE_USER } from "../consts/Enum";
import Urls from "../consts/Urls";
import { removeKeyOjectValueEmpty, urlListOrder } from "../utils/common";
import storage from "../utils/localStorage";
import axiosClient from "./axios";

const OrderService = {
  LIST_ORDER: async (params) => {
    delete params.recordTotal;
    delete params.pageTotal;
    const newPamrams = removeKeyOjectValueEmpty(params);
    return await axiosClient.get(urlListOrder(storage.getIdToken()), {
      params: newPamrams
    });
  },
  ORDER_DETAIL: async (orderCode) => {
    if (
      storage.getIdToken() === TYPE_USER.FINANCE ||
      storage.getIdToken() === TYPE_USER.SUPPLIER
    ) {
      return await axiosClient.get(
        `${Urls.PURCHASE_DETAIL}/${orderCode}/detail`
      );
    }
    return await axiosClient.get(`${Urls.ORDER_DETAIL}/${orderCode}/detail`);
  },
  ORDER_DETAIL_ADMIN: async (orderCode, type) => {
    if (
      storage.getIdToken() === TYPE_USER.FINANCE ||
      storage.getIdToken() === TYPE_USER.SUPPLIER
    ) {
      return await axiosClient.get(
        `${Urls.UPDATE_STATUS_ORDER_BUYER}/${orderCode}/detail`
      );
    }
    return await axiosClient.get(
      `${Urls.ORDER_DETAIL_ADMIN}/${orderCode}/detail?type=${type}`
    );
  },
  UPDATE_STATUS_ORDER_ADMIN: async (orderCode, typeOrder, params) => {
    return await axiosClient.post(
      `${Urls.UPDATE_STATUS_ORDER_ADMIN}/${orderCode}/update?type=${typeOrder}`,
      params
    );
  },
  PRE_ORDER_INFO: async (orderCode, paymentOption) => {
    return await axiosClient.get(
      `${Urls.PRE_ORDER_INFO}/${orderCode}/pre-order-info?paymentOption=${paymentOption}`
    );
  },
  ORDER_STATISTIC_DEALER: async () => {
    return await axiosClient.get(Urls.ORDER_STATISTIC_DEALER);
  },
  ORDER_STATISTIC_BUYER: async () => {
    return await axiosClient.get(Urls.ORDER_STATISTIC_BUYER);
  }
};

export default OrderService;
