// json
import common_en from "translation/vi/common.json";
import inventory_en from "translation/vi/inventory.json";
import signin_en from "translation/vi/signin.json";
import signup_en from "translation/vi/signup.json";
import verify_phone_en from "translation/vi/verifyphone.json";
import approve_email_en from "translation/vi/approveEmail.json";
import email_verify_en from "translation/vi/emailVerify.json";
import order_en from "translation/vi/order.json";
import category_en from "translation/vi/category.json";
import finance_en from "translation/vi/finance.json";
import user_en from "translation/vi/user.json";
import breadcrumb_en from "translation/vi/breadcrumb.json";
import status_en from "translation/vi/status.json";
import forgot_password_en from "translation/vi/forgotPassword.json";
import profile_en from "translation/vi/profile.json";
import marketplace_en from "translation/vi/marketplace.json";
import purchase_en from "translation/vi/purchase.json";
import error_en from "translation/vi/error.json";
import store_en from "translation/vi/store.json";
import chat_en from "translation/vi/chat.json";

const translation = {
  common: common_en,
  inventory: inventory_en,
  signin: signin_en,
  signup: signup_en,
  verifyPhone: verify_phone_en,
  approveEmail: approve_email_en,
  emailVerify: email_verify_en,
  order: order_en,
  finance: finance_en,
  user: user_en,
  breadcrumb: breadcrumb_en,
  status: status_en,
  category: category_en,
  forgotPassword: forgot_password_en,
  profile: profile_en,
  marketplace: marketplace_en,
  purchase: purchase_en,
  error: error_en,
  store: store_en,
  chat: chat_en,
};

export default translation;
