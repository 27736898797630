import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    reportListAction: ['params'],
    reportListSuccess: ['data'],

    invoiceListAction: ['params'],
    invoiceListSuccess: ['data'],

    reportFailure: [],
  },
  {}
);
export default Creators;
