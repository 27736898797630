import React, { Fragment } from "react";
import { message } from "antd";
import {
  camelCase,
  capitalize,
  get,
  isArray,
  isEmpty,
  isNil,
  isNumber,
  omitBy,
  reduce,
  trim,
} from "lodash";
import {
  CAR_STATUS_KEY,
  STATUS_DEALER,
  TYPE_DOC,
  TYPE_USER,
} from "../consts/Enum";
import UIColor from "../colors";
import moment from "moment";
import { UploadService } from "../service";
import Urls from "../consts/Urls";
import storage from "./localStorage";
import { PdfIcon, DocIcon } from "assets/images/blimobil";

export function sagaWrapper(saga, errorHandle) {
  return function* (action) {
    try {
      yield saga(action);
    } catch (e) {
      yield errorHandle(e, action);
    }
  };
}

/**
 * Handle displaying errors in saga processes.
 * @param {err} object - callback async function* after show error message.
 */
export function errorHandler(
  err,
  action,
  itemStringKey,
  bypassErrorNotify = false
) {
  const errorMessage = parseErrorMessage(err);
  if (!bypassErrorNotify && errorMessage) {
    message.error({
      content: <Fragment>{errorMessage}</Fragment>,
      key: get(action, "type"),
      duration: 5,
    });
  }
}

export function parseErrorMessage(err) {
  if (!err.errors)
    return typeof err.message === "object" ? err?.message?.en : err.message;
  const result = Object.keys(err.errors).map(function (key, index) {
    return <p>{err.errors[key]}</p>;
  });

  return result;
}

/**
 * Handle displaying errors in saga processes.
 * @param {function} callback - callback async function* after show error message.
 * @param {string} itemStringKey - item string key to get item error message: (Ex: hotel, tour, order, group,...).
 */
export function sagaErrorHandler(
  callback,
  itemStringKey = "",
  bypassErrorNotify = false
) {
  return function* (e, action) {
    errorHandler(e, action, itemStringKey, bypassErrorNotify);
    console.warn(e);
    if (callback) {
      yield callback(e);
    }
  };
}

/**
 * Display success notifications in saga processes
 * @param {Object} [action] - saga action object.
 * @param {string} [message] - message to display.
 * @param {string} description - description to display as sub text bellow the message.
 */
export function sagaSuccessMessage({
  action,
  successMessage = null,
  description = null,
}) {
  if (action && successMessage === null && description === null) {
    successMessage = action.message;
    description = action.description;
    action = action.action;
  }
  return message.success({
    content: (
      <React.Fragment>
        <span>{successMessage}</span>
        {!!description && (
          <React.Fragment>
            <br />
            <div style={{ width: "100%", paddingLeft: 25, textAlign: "left" }}>
              <span style={{ fontStyle: "italic", fontSize: 10, opacity: 0.9 }}>
                {description}
              </span>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    ),
    key: action ? action.type : undefined,
    duration: 4,
  });
}

/**
 * Display loading notifications in saga processes
 * @param {Object} [action] - saga action object.
 * @param {string} [message] - message to display.
 */
export function sagaLoadingMessage(
  action,
  loadingMessage = null,
  duration = undefined
) {
  if (
    action &&
    typeof action.message === "string" &&
    loadingMessage === null &&
    duration === undefined
  ) {
    loadingMessage = action.message;
    duration = action.duration;
    action = action.action;
  }
  return message.loading({
    content: loadingMessage,
    key: action ? action.type : undefined,
    duration,
  });
}

export function formatNumber(value) {
  if (value === 0) {
    return 0;
  }
  return !!value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
}

export function formatCurrency(value) {
  if (value === 0) {
    return 0;
  }
  return !!value
    ? `${Math.round(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    : "";
}

export function valueNull(value) {
  return !value ? null : value;
}

export function removeKeyOjectValueEmpty(obj) {
  const isRole = storage.getIdToken();
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "" || obj[key] === "all") {
      delete obj[key];
    }
  });
  if (isRole === TYPE_USER.SUPPLIER) {
    delete obj.type;
  }
  return obj;
}

export function stringBoolean(data) {
  return data ? "Có" : "Không";
}

export function stringRequired(data) {
  return data ? "Có" : "Không";
}

export function branchTwoArray(data = []) {
  if (isEmpty(data)) return [];
  const column = Math.round(data.length / 2);

  const arrayFirst = [];
  const arraySecond = [];

  for (let i = 0; i < column; i++) {
    arrayFirst.push({ value: data[i]?.name || "" });
  }

  for (let j = column; j < data.length; j++) {
    arraySecond.push({ value: data[j]?.name || "" });
  }

  return [arrayFirst, arraySecond];
}

export function detectUrlString(string) {
  return /^(http|https):\/\/.*/.test(string);
}

export function parseListStatusCar(data) {
  if (isEmpty(data)) return [];
  let totalAll = 0;
  const result = [];
  for (const [key, value] of Object.entries(data[0])) {
    if (key !== "SOLD") {
      totalAll += value;
    }
    result.push({
      label: capitalize(key),
      count: value,
      key: CAR_STATUS_KEY[key],
      color: UIColor.status[camelCase(key)],
    });
  }
  result.unshift({
    label: "All Cars",
    count: totalAll,
    key: "all",
  });
  return result;
}

export function dataSelectYear(year) {
  const d = new Date();
  let currentYear = d.getFullYear();
  const result = [{ name: "Trước năm 1980", key: "before_1980" }];
  for (let i = year; i <= currentYear + 1; i++) {
    result.push({
      name: `${i}`,
      key: i,
    });
  }
  return result;
}

export function parseSelect(data, keyName = "name", keyCode = "code") {
  if (isEmpty(data)) return [];
  const result = data.map((item) => {
    const name = item[keyName];
    const key = item[keyCode];
    const id = item._id;
    return { name, key, id };
  });
  return result;
}

export function parseFeature(data, dataIcon) {
  if (isEmpty(data)) return [];
  const newData = [...data];
  newData.forEach((item, idx) => {
    item.icon = dataIcon.find((el) => el.name === item.name)?.icon;
    item.vi = dataIcon.find((el) => el.name === item.name)?.vi;
    item.en = dataIcon.find((el) => el.name === item.name)?.en;
  });
  return newData;
}

export function parseListCode(data) {
  if (isEmpty(data)) return [];
  const ids = data.map((item) => item.code);
  return ids;
}

export function parseDateColumnTable(date, monthFormat = "MMMM") {
  return date
    ? `${moment(date).format("DD")} ${moment(date).format(
        monthFormat
      )} ${moment(date).format("YYYY")}`
    : "-";
}

function sequence(tasks, fn) {
  return tasks.reduce((promise, task) => promise.then(() => fn(task)), Promise.resolve());
}

export async function promiseUpload(
  code,
  type,
  files,
  uploadFileResults,
  action
) {
  return new Promise((resolve) => {
    (async function loop() {
      var index = 0;
      for(const item of files) {
        if (item?.originFileObj?.type?.match("video")) {
          const res = await fetch(item?.thumbUrl);
          const blob = await res.blob();
          const file = new File(
            [blob],
            item?.originFileObj?.name?.split(".")?.shift(),
            { type: "image/png" }
          );
          await UploadService.UPLOAD_FILE(
            {
              refCode: code,
              refType: type,
            },
            {
              file,
              videoPath: uploadFileResults[index]?.videoPath,
            }
          );
        }
        if (item?.originFileObj?.type?.match("application/pdf")) {
          let file;
          await fetch(PdfIcon)
            .then((res) => res.blob())
            .then((myBlob) => {
              file = myBlob;
            });
          await UploadService.UPLOAD_FILE(
            {
              refCode: code,
              refType: "creditApproval",
            },
            {
              file,
              videoPath: uploadFileResults[index]?.videoPath,
            }
          );
        }
        if (TYPE_DOC.includes(item?.originFileObj?.type)) {
          let file;
          await fetch(DocIcon)
            .then((res) => res.blob())
            .then((myBlob) => {
              file = myBlob;
            });
          await UploadService.UPLOAD_FILE(
            {
              refCode: code,
              refType: "creditApproval",
            },
            {
              file,
              videoPath: uploadFileResults[index]?.videoPath,
            }
          );
        }
    
        await UploadService.UPLOAD_FILE(
          {
            refCode: code,
            refType: type,
          },
          {
            file: item.originFileObj,
          }
        );
        if (index + 1 === files?.length) {
          resolve(true)
        }
        index++;
      }
    })();
  })
  // const urls = result.map(
  //   (item) => Urls.URL_FILE + item.result.fileAdded.path.origin
  // );
  // return urls;
}

export async function promiseUploadV2(
  code,
  type,
  files,
  uploadFileResults,
  action
) {
  return new Promise((resolve) => {
    (async function loop() {
      var index = 0;
      for(const item of files) {
        if (item?.originFileObj?.type?.match("video")) {
          const res = await fetch(item?.thumbUrl);
          const blob = await res.blob();
          const file = new File(
            [blob],
            item?.originFileObj?.name?.split(".")?.shift(),
            { type: "image/png" }
          );
          await UploadService.UPLOAD_FILE_V2(
            {
              refCode: code,
              refType: type,
            },
            {
              file,
              videoPath: uploadFileResults[index]?.videoPath,
            }
          ).catch(() => false);;
        }
        if (item?.originFileObj?.type?.match("application/pdf")) {
          let file;
          await fetch(PdfIcon)
            .then((res) => res.blob())
            .then((myBlob) => {
              file = myBlob;
            });
          await UploadService.UPLOAD_FILE_V2(
            {
              refCode: code,
              refType: "creditApproval",
            },
            {
              file,
              videoPath: uploadFileResults[index]?.videoPath,
            }
          ).catch(() => false);;
        }
        if (TYPE_DOC.includes(item?.originFileObj?.type)) {
          let file;
          await fetch(DocIcon)
            .then((res) => res.blob())
            .then((myBlob) => {
              file = myBlob;
            });
          await UploadService.UPLOAD_FILE_V2(
            {
              refCode: code,
              refType: "creditApproval",
            },
            {
              file,
              videoPath: uploadFileResults[index]?.videoPath,
            }
          ).catch(() => false);;
        }
    
        await UploadService.UPLOAD_FILE_V2(
          {
            refCode: code,
            refType: type,
          },
          {
            file: item.originFileObj,
          }
        ).catch(() => false);
        if (index + 1 === files?.length) {
          resolve(true)
        }
        index++;
      }
    })();
  })
  // const urls = result.map(
  //   (item) => Urls.URL_FILE + item.result.fileAdded.path.origin
  // );
  // return urls;
}


export function disabledActionCar(status) {
  return status === CAR_STATUS_KEY.BOOKED || status === CAR_STATUS_KEY.SOLD;
}

export function nameButtonActionCar(status, t) {
  return status === CAR_STATUS_KEY.DRAFT ? t("publish") : t("unpublish");
}

export function parseListImage(images) {
  if (!images && !images?.length) return [];
  const result = images.map((item) => {
    if (item.originFileObj || item.url) {
      return item;
    } else {
      const uid = item._id;
      const name = `${item.refCode}-${item._id}`;
      const url = Urls.URL_FILE + item?.path?.origin;
      const status = "done";
      return { uid, name, url, status, videoPath: item?.videoPath };
    }
  });
  return result;
}

export function listImageURL(images) {
  if (!images && !images?.length) return [];
  const result = images.map((item) => {
    return Urls.URL_FILE + item.path.origin;
  });
  return result;
}

export function textEmpty(text) {
  return text ? text : "-";
}

export function statusDeadler(merchantData) {
  if (merchantData) {
    if (merchantData.approved) return STATUS_DEALER.AVAILABLE;
    if (merchantData.rejected) return STATUS_DEALER.STORE_REJECTED;
    return STATUS_DEALER.RESQUESTING_STORE;
  }
  return STATUS_DEALER.NEW;
}

export function totalCount(data) {
  const result = reduce(
    data,
    function (sum, item) {
      return sum + item.count;
    },
    0
  );
  return result;
}

function noText(text) {
  return isEmpty(text) ? "" : text;
}

export function nameCar(item) {
  return !isEmpty(item)
    ? noText(item.year) +
        " " +
        noText(item?.brand?.name) +
        " " +
        noText(item?.engine?.name) +
        " " +
        noText(item?.variant?.name) +
        " " +
        noText(item?.seatingCapacity?.name)
    : "-";
}

export function marketplaceNameCar(item) {
  return !isEmpty(item)
    ? noText(item?.brand?.name) + " " + noText(item?.model?.name)
    : "-";
}

export function validateIsBorderBottom(length, index) {
  if (length % 2 === 0) {
    return index < length - 2;
  } else {
    return index < length - 1;
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? "s" : "";
  };
  const number = (num) => (num > 9 ? "" + num : "0" + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + " day" + numberEnding(days);
      } else {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}

export function compare(a, b) {
  if (new Date(a.created).getTime() < new Date(b.created).getTime()) {
    return -1;
  }
  if (new Date(a.created).getTime() > new Date(b.created).getTime()) {
    return 1;
  }
  return 0;
}

export function urlListOrder(key) {
  switch (key) {
    case TYPE_USER.ADMIN: {
      return Urls.ORDER_LIST_ADMIN;
    }
    case TYPE_USER.DEALER: {
      return Urls.ORDER_LIST_DEALER;
    }
    case TYPE_USER.SUPPLIER: {
      return Urls.PURCHASE_LIST;
    }
    default: {
      return Urls.ORDER_LIST_DEALER;
    }
  }
}

export function compactObject(data) {
  return omitBy(data, isNil);
}

export function colorStatus(status) {
  switch (status) {
    case "new":
      return {
        bgColor: "#CAEBF9",
        color: "#50BDEA",
        value: "new",
      };
    case "paymentPending":
      return {
        bgColor: "#CAEBF9",
        color: "#50BDEA",
        value: "paymentPending",
      };
    case "partialPaymentVerifying":
      return {
        bgColor: "#A5F6E2",
        color: "#1BD2A4",
        value: "partialPaymentVerifying",
      };
    case "verifyingPayment":
      return {
        bgColor: "#FFF1E6",
        color: "#FF9A4D",
        value: "verifyingPayment",
      };
    case "partialPaymentPending":
      return {
        bgColor: "#FFE2CC",
        color: "#FF9A4D",
        value: "partialPaymentPending",
      };
    case "payment":
      return {
        bgColor: "#A5F6E2",
        color: "#1BD2A4",
        value: "Payment",
      };
    case "delivered":
      return {
        color: "#666666",
        bgColor: "#D2D1D4",
        value: "delivered",
      };
    case "onDelivery":
      return {
        color: "#666666",
        bgColor: "#D2D1D4",
        value: "onDelivery",
      };
    case "completed":
      return {
        color: "#376ED7",
        bgColor: "#C1D5FB",
        value: "completed",
      };
    case "paid":
      return {
        color: "#1BD2A4",
        bgColor: "#E9FDF8",
        value: "paid",
      };
    case "cancelled":
      return {
        color: "#F76969",
        bgColor: "#FCBFBF",
        value: "cancelled",
      };
    case "releasedMoney":
      return {
        bgColor: "#FFF8DF",
        color: "#EBBA16",
        value: status,
      };
    default:
      return {
        bgColor: "#CAEBF9",
        color: "#50BDEA",
        value: status,
      };
  }
}

export function parseFilterMarketplaceQuerystring(filter) {
  const compactResult = compactObject(filter);
  let result = {};
  for (const [key, value] of Object.entries(compactResult)) {
    if (
      ((key === "yearFROM" || key === "yearTO") && value === 1980) ||
      (isEmpty(value) && !isNumber(value))
    ) {
      continue;
    }
    console.log(key, isArray(value));
    if (isArray(value)) {
      const stringQueryArray = reduce(
        value,
        function (res, i) {
          return (res += `${i},`);
        },
        ""
      );
      result[key] = stringQueryArray.substring(0, stringQueryArray.length - 1);
    } else {
      if (value === 0) {
        continue;
      }
      result[key] = value;
    }
  }
  return result;
}

export function textPhone(code, phone) {
  return code ? `${code} ${phone}` : "";
}

export function trimValueObject(object) {
  const oldData = { ...object };
  for (const [key, value] of Object.entries(oldData)) {
    if (typeof value === "string") {
      oldData[key] = trim(value);
    }
  }
  return oldData;
}

export const formatterNumber = (val) => {
  if (!val) return 0;
  return `${val}`
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    .replace(/\.(?=\d{0,2}$)/g, ",");
};

export const parserNumber = (val) => {
  if (!val) return 0;
  return Number.parseFloat(
    val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")
  ).toFixed(2);
};

export const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

export const toHHMMSS = (time) => {
  var sec_num = parseInt(time, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

export const sortAlphabet = (data, key) => {
  return data.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};

export const apiMarketplace = () => {
  const rule = storage.getIdToken();
  // eslint-disable-next-line default-case
  switch (rule) {
    case TYPE_USER.ADMIN: {
      if (window.location.pathname.search("used-cars") > 0) {
        return Urls.USED_CAR_MARKETPLACE_LIST;
      }
      return Urls.NEW_CAR_MARKETPLACE_LIST;
    }
    case TYPE_USER.DEALER: {
      return Urls.MARKETPLACE_LIST;
    }
    default: {
      return Urls.MARKETPLACE_LIST;
    }
  }
};

export const sortAlphabeticallyCities = (cities) =>
  cities.sort((a, b) => a.name.localeCompare(b.name));

export const getBase64Extension = (base64Data) =>
  base64Data
    ? base64Data.substring("data:image/".length, base64Data.indexOf(";base64"))
    : "";

export const splitNameFile = (nameFile) => nameFile.split("_").pop();
