import React from "react";

export default function LocationSvg() {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 8.99478C8.604 8.99478 9.5 8.09934 9.5 6.99606C9.5 5.89278 8.604 4.99738 7.5 4.99738C6.396 4.99738 5.5 5.89278 5.5 6.99606C5.5 8.09934 6.396 8.99478 7.5 8.99478Z"
        stroke="#666666"
        stroke-linecap="square"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5 6.99606C13.5 11.9928 8.5 14.9909 7.5 14.9909C6.5 14.9909 1.5 11.9928 1.5 6.99606C1.5 3.68522 4.187 1 7.5 1C10.813 1 13.5 3.68522 13.5 6.99606Z"
        stroke="#666666"
        stroke-linecap="square"
      />
    </svg>
  );
}
