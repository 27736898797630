import { Modal, Image, message, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useMemo, useState } from "react";
import { UploadStyled, IconUpload, BlockUpload } from "./UploadList.style";
import { deleteFile, deleteMerchantFile } from "service/upload.service";

import { IText } from "..";
import { getBase64Extension, parseListImage } from "../../utils/common";
import Urls from "consts/Urls";
import { useTranslation } from "react-i18next";
import { TYPE_FILE } from "consts/Enum";

const MAX_5M = 5242880; // Max file size 5M : 5 * 1024 * 1024
// const MAX_5M = 2097152; // Max file size 2M Test

const IMAGE_ONLY_REGEX = /(\.jpg|\.jpeg|\.png|\.gif|\.bmp)$/i;

function checkAcceptImageType(fileType) {
  return IMAGE_ONLY_REGEX.test(`.${fileType.split('/').pop()}`)
}

const beforeUploadProps = {
  beforeUpload: (file) => {
    const isLtSize = file.size / MAX_5M < 1;
      
    let isValidType = checkAcceptImageType(file.type);
    if (!isValidType) {
      message.warning('Tệp hình ảnh không hợp lệ');
      return Upload.LIST_IGNORE
    }
    if (!isLtSize) {
      message.warning(`Kích thước file ${file.name} quá lớn`);
      return Upload.LIST_IGNORE
    }
    return false
  }
};

const draggerProps = {
  customRequest: ({ onSuccess }) => { onSuccess("ok") }
};

export default function UploadList({
  multiple = true,
  deleteType,
  isComponentUpload = false,
  componentUpload = () => {},
  maxLength = 7,
  width,
  height,
  ref,
  onChange,
  fileList = [],
  onBlur,
  listType = "picture-card",
  isVideos = false,
  accept = "image/*",
  iconRender,
  visiblePreview,
  errors,
  name,
  ...props
}) {
  const onChangeUpload = async ({ fileList: newFileList }) => {
    // // Test async render base64 preview
    const originalImages = newFileList.filter(img => !img?.originFileObj);
    const blobImages = newFileList.filter(img => img?.originFileObj);

    // const validFileList = blobImages.filter(el => {
    //   return el.size < MAX_5M && isValidImageType(el.type)
    // })
    try {
      const promiseList = blobImages.map(el => {
        return new Promise(resolve => {
          if (el.type?.includes("video")) {
            (async () => {
              const result = await generateVideoThumbnails(el, 1);
              resolve({
                ...el,
                originFileObj: el.originFileObj,
                thumbUrl: result,
              });
            })();
          }  else {
            const reader = new FileReader();
            reader.readAsDataURL(el.originFileObj);
            reader.onload = () =>
              resolve({
                ...el,
                originFileObj: el.originFileObj,
                thumbUrl: reader.result,
              });
          }
        });
      });
      const newImageListWithThumb = await Promise.all(promiseList);
      onChange([...originalImages, ...newImageListWithThumb]);
    } catch (error) {
      console.log('error', error);
      // onChange(newFileList);
      message.error('Có lỗi! Vui lòng thử lại sau')
    }
    // Test async render base64 preview
  };

  const { t } = useTranslation("inventory");
  const [visible, setVisible] = useState(false);
  const [video, setVideo] = useState();

  const onPreview = async (file) => {
    let src = file?.url;
    const videoPath = file?.videoPath;
    const thumbUrl = file?.thumbUrl;

    if (file?.originFileObj?.type?.includes("video")) {
      src = URL.createObjectURL(file.originFileObj);
      setVideo({
        src,
        thumbUrl,
        type: file.originFileObj.type
      });
      return;
    }
    if ((src && !videoPath) || thumbUrl) {
      setVisible(src || thumbUrl);
    }
    if (videoPath) {
      setVideo({
        src: Urls.URL_FILE + videoPath,
        type: "video/" + videoPath.split(".").pop(),
        thumbUrl: src
      });
    }
  };

  const uploadButton = isComponentUpload ? (
    componentUpload()
  ) : (
    <BlockUpload>
      <IconUpload>
        <PlusOutlined style={{ color: "white", fontSize: 13 }} />
      </IconUpload>
      <IText fSize={12} color="#666666">
        Tải ảnh lên
      </IText>
    </BlockUpload>
  );

  const fileListProps = useMemo(() => {
    return parseListImage(fileList);
  }, [fileList]);

  const generateVideoThumbnails = async (videoFile) => {
    return new Promise(async (resolve, reject) => {
      if (!videoFile.type?.includes("video")) reject("Tệp video không hợp lệ");
      const url = await getVideoThumbnail(videoFile, 0);
      resolve(url);
    });
  };

  const getVideoThumbnail = (file, videoTimeInSeconds) => {
    return new Promise((resolve, reject) => {
      if (file.type.match("video")) {
        importFileAndPreview(file).then((urlOfFIle) => {
          var video = document.createElement("video");
          var timeupdate = function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
              video.pause();
            }
          };
          video.addEventListener("loadeddata", function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
            }
          });
          var snapImage = function () {
            var canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
              .getContext("2d")
              .drawImage(video, 0, 0, canvas.width, canvas.height);
            var image = canvas.toDataURL();
            var success = image.length > 100000;
            if (success) {
              URL.revokeObjectURL(urlOfFIle);
              resolve(image);
            }
            return success;
          };
          video.addEventListener("timeupdate", timeupdate);
          video.preload = "metadata";
          video.src = urlOfFIle;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.currentTime = videoTimeInSeconds;
          video.play();
        });
      } else {
        reject("Tệp không hợp lệ");
      }
    });
  };

  const importFileAndPreview = (file, revoke) => {
    return new Promise((resolve) => {
      window.URL = window.URL || window.webkitURL;
      let preview = window.URL.createObjectURL(file);
      // remove reference
      if (revoke) {
        window.URL.revokeObjectURL(preview);
      }
      setTimeout(() => {
        resolve(preview);
      }, 100);
    });
  };

  const cancelHandler = () => setVideo("");
  const deleteHandler = async (file) => {
    if (!file?.originFileObj) {
      try {
        if (deleteType === TYPE_FILE.merchant) {
          await deleteMerchantFile(file?.uid);
        } else {
          await deleteFile(file?.uid);
        }
      } catch (error) {
        console.log(error);
      }
    }
    return true;
  };

  const acceptUpload = useMemo(() => {
    if (isVideos) {
      return "video/*, image/*";
    }
    return accept;
  }, [isVideos, accept]);

  const base64Extension = useMemo(() => getBase64Extension(visible), [visible]);

  return (
    <>
      <UploadStyled
        {...draggerProps}
        {...beforeUploadProps}
        listType={listType}
        fileList={fileListProps || []}
        width={width}
        height={height}
        onChange={onChangeUpload}
        onPreview={onPreview}
        multiple={multiple}
        ref={ref}
        iconRender={iconRender}
        onBlur={onBlur}
        accept={acceptUpload}
        onRemove={deleteHandler}
        maxCount={maxLength}
        name={name}
      >
        {(fileListProps || []).length < maxLength && uploadButton}
      </UploadStyled>
     {visible && <Image
        width={200}
        style={{ display: "none" }}
        src={visible}
        preview={
          !["png", "jpg", "jpeg"].includes(base64Extension)
            ? false
            : {
                visible,
                src: visible,
                onVisibleChange: (value) => {
                  setVisible(value);
                }
              }
        }
      />} 
      {!!errors && errors[name] && (
             <div style={{ textAlign: 'left' }}>
               <IText color="red">{errors[name]?.message}</IText>
             </div>
            )}
      {video && (
        <Modal
          visible={video}
          onCancel={cancelHandler}
          footer={null}
          centered
          bodyStyle={{ padding: 0 }}
          closable={false}
        >
          <video
            width="100%"
            height="100%"
            poster={video?.thumbUrl}
            autoplay="autoplay"
            controls
          >
            <source src={video?.src} type={video?.type} />
          </video>
        </Modal>
      )}
    </>
  );
}
