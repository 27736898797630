import React from "react";

export default function StoreInfoSvg() {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 3V2.5C1.26166 2.5 1.05645 2.66823 1.00971 2.90194L1.5 3ZM13.5 3L13.9903 2.90194C13.9435 2.66823 13.7383 2.5 13.5 2.5V3ZM14.5 8V8.5C14.6498 8.5 14.7917 8.43284 14.8867 8.31699C14.9816 8.20115 15.0197 8.04883 14.9903 7.90194L14.5 8ZM0.5 8L0.00970966 7.90194C-0.0196679 8.04883 0.0183596 8.20115 0.113329 8.31699C0.208298 8.43284 0.350203 8.5 0.5 8.5V8ZM3.5 11H3V11.5H3.5V11ZM11.5 11V11.5H12V11H11.5ZM0 15.5H15V14.5H0V15.5ZM1 8V15H2V8H1ZM13 8V15H14V8H13ZM1.5 3.5H13.5V2.5H1.5V3.5ZM13.0097 3.09806L14.0097 8.09806L14.9903 7.90194L13.9903 2.90194L13.0097 3.09806ZM14.5 7.5H0.5V8.5H14.5V7.5ZM0.99029 8.09806L1.99029 3.09806L1.00971 2.90194L0.00970966 7.90194L0.99029 8.09806ZM1 1.5H14V0.5H1V1.5ZM3 8V11H4V8H3ZM3.5 11.5H11.5V10.5H3.5V11.5ZM12 11V8H11V11H12Z"
        fill="currentColor"
      />
    </svg>
  );
}
