import Urls from "../consts/Urls";
import axiosClient from "./axios";

const ConfigService = {
  MODEL_LIST: async (params) => {
    delete params.recordTotal;
    delete params.pageTotal;
    if (params?.brandId) {
      return await axiosClient.get(
        Urls.MODEL_LIST,
        { params }
      );
    }
  },
  CONNECT_BRAND: async (params) => {
    return await axiosClient.post(Urls.CONNECT_BRAND, params);
  }
};
export default ConfigService;
