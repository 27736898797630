import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "@iso/redux/languageSwitcher/actions";
import config from "./config";
import { Dropdown, Image } from "antd";
import {
  MenuCustom,
  MenuItemCustom,
} from "../../components/DropdownCustom/DropdownCustom";
import i18n from "i18n";
import { Box, IText, ITextBlack } from "components";
import { DownOutlined } from "@ant-design/icons";
import useWindowSize from "hooks/useWindowSize";

const { changeLanguage } = actions;

export default function LanguageSwitcher({ showName = false }) {
  const { language } = useSelector((state) => state.LanguageSwitcher);
  const [windowSize, isMobile] = useWindowSize();
  const dispatch = useDispatch();

  const content = useCallback(() => {
    return (
      <MenuCustom
        defaultValue={language.locale}
        onClick={({ key }) => {
          dispatch(changeLanguage(key));
          i18n.changeLanguage(key);
        }}
      >
        {config.options.map((option) => {
          const { locale, icon } = option;
          return (
            <MenuItemCustom key={locale} style={{ padding: "12px 20px" }}>
              <Box justify="flex-start">
                <Image src={icon} preview={false} />
                <ITextBlack>
                  {locale === "vi" ? "Vietnam" : "English"}
                </ITextBlack>
              </Box>
            </MenuItemCustom>
          );
        })}
      </MenuCustom>
    );
  }, [dispatch, language]);

  return showName ? (
    <Dropdown overlay={content} trigger="click" placement="bottom">
      <Box style={{ cursor: "pointer" }}>
        <Image
          style={{ width: 24, height: 24 }}
          src={config.flag[language.locale]}
          preview={false}
        />
        <IText color={isMobile ? "white" : "black"}>
          {language.locale === "vi" ? "Vietnam" : "English"}
        </IText>
        <DownOutlined />
      </Box>
    </Dropdown>
  ) : (
    <Dropdown overlay={content} trigger="click" placement="bottom">
      <Image
        style={{ cursor: "pointer" }}
        src={config.flag[language.locale]}
        preview={false}
      />
    </Dropdown>
  );
}
