const UIColor = {
  bgHeader: "#333333",
  primaryHeader: "#0D3E9A",
  primary: "#0D3E9A",
  status: {
    draft: "#D2D1D4",
    available: "#1BD2A4",
    booked: "#50BDEA",
    sold: "#FF8B34"
  },
  primaryBtnSuccess: "#FA8F21",
  primaryBtnUnpublish: "#555555",
  sky: {
    _3: "#50BDEA"
  },
  green: {
    _1: "#18BA92"
  },
  blue: {
    _3: "#0D3E9A",
    _5: "#295EC2",
    _6: "#376ED7",
    _12: "#E9F1FE"
  },
  orange: {
    _1: "#CC5801",
    _3: "#F87009",
    _4: "#FF7D1A",
    _5: "#FF8B34"
  },
  gray: {
    _1: "#0F0D15",
    _2: "#222222",
    _3: "#333333",
    _4: "#444444",
    _5: "#666666",
    _7: "#8E8C94",
    _8: "#A5A3A9",
    _10: "#D2D1D4",
    _11: "#EEEEEE"
  },
  geekBlue: {
    _2: "#d6e4ff"
  },
  red: {
    _5: "#F65354"
  }
};

export default UIColor;
