import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    userListAction: ["params"],
    userListSuccess: ["data"],

    userDetailAction: ["userCode"],
    userDetailSuccess: ["data"],

    userChangeParams: ["params"],

    userUpdateAction: ["params", "callback"],
    userUpdateSuccess: ["data"],

    userFailure: []
  },
  {}
);
export default Creators;
