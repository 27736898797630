import { createActions } from "reduxsauce";

export const {Types, Creators} = createActions(
    {
        brandListAction: ["params"],
        brandListSuccess: ["data"],

        brandCreateAction: ["params", "callback"],
        createBrandSuccess: [],

        addColorAction: ["params", "callback"],
        addColorSuccess: [],

        getColorAction: ["params"],
        getColorSuccess: ["data"],

        brandFailed: []
    },
    {}
)
export default Creators;