import { TYPE_FILE } from "consts/Enum";
import Urls from "consts/Urls";
import { last } from "lodash";
import { isEmpty } from "lodash";
import { call, put, takeLatest, fork, all, delay } from "redux-saga/effects";
import { MerchantService } from "../../service";
import {
  parseDateColumnTable,
  promiseUpload,
  promiseUploadV2,
  sagaErrorHandler,
  sagaSuccessMessage,
  sagaWrapper,
  statusDeadler,
} from "../../utils/common";
import MerchantAction, { Types } from "./actions";

function* getMerchantList() {
  yield takeLatest(
    Types.MERCHANT_LIST_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(MerchantService.LIST_MERCHANT, action.params);
      const data = (result.data || []).map((item) => {
        const dealerName = {
          email: item.createBy.email,
          avatar: item.createBy.avatar,
          name: item.createBy.name,
        };
        const dateRegistered = parseDateColumnTable(item.submitted);
        const companyName = item?.merchant?.contactName || item?.contactName;
        const lastVisited = "-";
        const status = statusDeadler(item);
        const inventory = item.carInventory;
        const totalIncome = "";
        const totalCharged = "";
        const id = item.createBy.code;
        const merchantCode = item.code;
        return {
          ...item,
          dealerName,
          companyName,
          dateRegistered,
          lastVisited,
          status,
          totalIncome,
          inventory,
          totalCharged,
          id,
          merchantCode,
        };
      });
      const newResult = Object.assign(result, { data: data });
      yield put(MerchantAction.merchantListSuccess(newResult));
      action.callback && action.callback();
    }, errorHandle(Types.MERCHANT_FAILURE))
  );
}

function* createMerchant() {
  yield takeLatest(
    Types.MERCHANT_CREATE_ACTION,
    sagaWrapper(function* (action) {
      const oldAvatar = action.params.avatar;
      delete action.params.avatar;
      const oldPhotos = action.params.photos;
      delete action.params.photos;
      const result = yield call(
        MerchantService.CREATE_MERCHANT_V3,
        action.params
      );
      const merchantCode = result.data.code;
      if (merchantCode) {
        if (!isEmpty(oldAvatar) && !oldAvatar?.[0]?._id) {
          const avatarLocal = oldAvatar.filter((el) => el.originFileObj);
          if (avatarLocal?.length > 0) {
            yield promiseUpload(
              merchantCode,
              TYPE_FILE.merchant,
              avatarLocal
            );
          }
        }
        if (!isEmpty(oldPhotos) && oldPhotos?.length > 0) {
          const imagesLocal = oldPhotos.filter((el) => el.originFileObj);
          if (imagesLocal?.length > 0) {
            yield promiseUploadV2(
              merchantCode,
              TYPE_FILE.merchantPhoto,
              imagesLocal
            );
          }
        }
      }
      delay(1000);
      sagaSuccessMessage({
        action,
        successMessage: "Thiết lập cửa hàng thành công",
        description: action?.params?.contactName,
      });
      localStorage.setItem("id_store", result.data.code);
      yield put(MerchantAction.merchantCreateSuccess());
      action.callback && action.callback();
    }, errorHandle(Types.MERCHANT_FAILURE))
  );
}

function* createMerchantBank() {
  yield takeLatest(
    Types.MERCHANT_BANK_CREATE_ACTION,
    sagaWrapper(function* (action) {
      const oldAvatar = action.params.avatar;
      delete action.params.avatar;
      if (!isEmpty(oldAvatar) && !oldAvatar?.[0]?._id) {
        const avatarLocal = oldAvatar.filter((el) => el.originFileObj);
        if (avatarLocal?.length > 0) {
          yield promiseUpload(action.merchantCode, TYPE_FILE.merchant, avatarLocal);
        }
      }

      delay(1000);
      const oldPhotos = action.params.photos;
      delete action.params.photos;
      if (!isEmpty(oldPhotos) && oldPhotos?.length > 0) {
        const imagesLocal = oldPhotos.filter((el) => el.originFileObj);
        if (imagesLocal?.length > 0) {
          yield promiseUploadV2(action.merchantCode, TYPE_FILE.merchantPhoto, imagesLocal);
        }
      }
      yield call(
        MerchantService.CREATE_MERCHANT_BANK_V3,
        action.params,
        action.merchantCode
      );
      delay(1000);
      yield put({
        type: Types.MERCHANT_DETAIL_ACTION,
        merchantCode: action.merchantCode,
      });
      sagaSuccessMessage({
        action,
        successMessage: "Cập nhật thành công",
        description: action?.params?.bankName,
      });
      yield put(MerchantAction.merchantBankCreateSuccess());
      action.callback && action.callback();
    }, errorHandle(Types.MERCHANT_FAILURE))
  );
}

function* getMerchantDetail() {
  yield takeLatest(
    Types.MERCHANT_DETAIL_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(
        MerchantService.DETAIL_MERCHANT,
        action.merchantCode
      );
      if (isEmpty(result?.result?.photos)) {
        result.result.avatarDefault = "";
        result.result.photos = [];
      } else {
        result.result.photos = [...result?.result?.photos];
        result.result.avatarDefault =
          Urls.URL_FILE + last(result?.result?.photos)?.path?.origin;
      }
      if (isEmpty(result?.result?.avatar)) {
        result.result.avatarDefault = "";
        result.result.avatar = [];
      } else {
        result.result.avatarDefault =
          Urls.URL_FILE + last(result?.result?.avatar)?.path?.origin;
        result.result.avatar = [...result?.result?.avatar];
      }
      yield put(MerchantAction.merchantDetailSuccess(result.result));
      action.callback && action.callback(result.result);
    }, errorHandle(Types.MERCHANT_FAILURE))
  );
}

function* verifyMerchant() {
  yield takeLatest(
    Types.MERCHANT_VERIFY_ACTION,
    sagaWrapper(function* (action) {
      yield call(
        MerchantService.VERIFY_MERCHANT,
        action.params,
        action.merchantCode
      );
      sagaSuccessMessage({
        action,
        successMessage: "Verify success",
        description: action?.merchantCode,
      });
      yield put(MerchantAction.merchantVerifySuccess());
      action.callback && action.callback();
    }, errorHandle(Types.MERCHANT_FAILURE))
  );
}

function* blockMerchant() {
  yield takeLatest(
    Types.MERCHANT_BLOCK_ACTION,
    sagaWrapper(function* (action) {
      yield call(
        MerchantService.BLOCK_MERCHANT,
        action.merchantCode,
        action.params
      );
      sagaSuccessMessage({
        action,
        successMessage: "Account lock successful",
        description: action?.merchantCode,
      });
      yield put(MerchantAction.merchantBlockSuccess());
      action.callback && action.callback();
    }, errorHandle(Types.MERCHANT_FAILURE))
  );
}

function* updateStatusMerchant() {
  yield takeLatest(
    Types.MERCHANT_UPDATE_STATUS_ACTION,
    sagaWrapper(function* (action) {
      let parts = action.codeMerchant.split("-");
      let result = "Đại lý " + parts[1];
      yield call(
        MerchantService.UPDATE_STATUS_MERCHANT,
        action.data,
        action.codeMerchant
      );
      delay(1000);
      sagaSuccessMessage({
        action,
        successMessage: "Cập nhật đại lý thành công",
        description: result,
      });
      yield put(MerchantAction.merchantUpdateStatusSuccess());
      action.callback && action.callback();
    }, errorHandle(Types.MERCHANT_FAILURE))
  );
}

function* resetUserFinance() {
  yield takeLatest(
    Types.RESET_USER_FINANCE,
    sagaWrapper(function* (action) {}, errorHandle(Types.INVENTORY_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return sagaErrorHandler(function* (e, action) {
    console.warn("SAP SYSTEM ERROR:", e);
    yield put({ type: errorActionType, sessionId: action?.sessionId });
  });
}

export default function* rootSaga() {
  yield all([
    fork(createMerchant),
    fork(getMerchantList),
    fork(getMerchantDetail),
    fork(updateStatusMerchant),
    fork(createMerchantBank),
    fork(verifyMerchant),
    fork(resetUserFinance),
    fork(blockMerchant),
  ]);
}
