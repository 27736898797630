import { TYPE_DOC, TYPE_FILE } from "consts/Enum";
import { isEmpty } from "lodash";
import { call, put, takeLatest, fork, all, delay } from "redux-saga/effects";
import { FinanceService } from "../../service";
import {
  nameCar,
  promiseUpload,
  sagaErrorHandler,
  sagaSuccessMessage,
  sagaWrapper
} from "../../utils/common";
import FinanceAction, { Types } from "./actions";
import { uploadToS3 } from "service/upload.service";

function* getRequestList() {
  yield takeLatest(
    Types.REQUEST_LIST_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(FinanceService.FINANCE_LIST, action.params);
      const data = result.data.data.map((item) => {
        const order = item.code;
        const status = item.status || "pendingPayment";
        const product = nameCar(item?.car);
        const company = item?.merchant;
        const buyer = item?.buyer?.email;
        const date = item.created;
        const totalAmount = item?.totalAmount;
        const totalFee = item.platformFee || 0;
        const id = item.code;
        const dealer = item?.buyer?.companyName;
        const dealerName = item?.merchant;
        return {
          ...item,
          order,
          status,
          product,
          buyer,
          date,
          id,
          totalAmount,
          totalFee,
          dealer,
          company,
          dealerName
        };
      });
      const params = {
        page: result.data?.metadata[0]?.pageCurrent || 1,
        recordTotal: result?.data.metadata[0]?.recordTotal || 10,
        pageTotal:
          (result.data.metadata[0]?.recordTotal /
            result.data.metadata[0]?.recordPerPage) *
            10 || 1,
        status: action.params.status || "all",
        recordPerPage: result.data.metadata[0]?.recordPerPage || 10
      };
      const newResult = Object.assign(result, { data, params });
      yield put(FinanceAction.requestListSuccess(newResult));
    }, errorHandle(Types.REQUEST_FAILURE))
  );
}

function* getRequestDetail() {
  yield takeLatest(
    Types.REQUEST_DETAIL_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(
        FinanceService.FINANCE_DETAIL,
        action.requestCode
      );
      yield put(FinanceAction.requestDetailSuccess(result.data));
    }, errorHandle(Types.REQUEST_FAILURE))
  );
}

function* updateRequestStatus() {
  yield takeLatest(
    Types.REQUEST_FINANCE_UPDATE_STATUS_ACTION,
    sagaWrapper(function* (action) {
      const oldPhotos = action.params.photos;
      delete action.params.photos;
      if (oldPhotos?.length > 0) {
        const fileList = oldPhotos.filter(
          (item) =>
            item.originFileObj.type.match("application/pdf") ||
            TYPE_DOC.includes(item.originFileObj.type)
        );
        if (!isEmpty(fileList)) {
          yield uploadToS3(
            fileList,
            action.orderCode,
            TYPE_FILE.contract,
            "proof"
          );
        }
      }
      delete action.params.merchantCode;
      yield call(
        FinanceService.UPDATE_STATUS_REQUEST_FINANCE,
        action.orderCode,
        action.typeOrder,
        action.params,
        action.paramsList
      );
      if (action.paramsList) {
        yield put({
          type: Types.REQUEST_LIST_ACTION,
          requestCode: action.paramsList
        });
      } else {
        yield put({
          type: Types.REQUEST_DETAIL_ACTION,
          requestCode: action.orderCode
        });
      }

      delay(1000);
      sagaSuccessMessage({
        action,
        successMessage: "Update success",
        description: action.orderCode
      });
      yield put(FinanceAction.requestFinanceUpdateStatusSuccess());
      action.callback && action.callback();
    }, errorHandle(Types.REQUEST_FAILURE))
  );
}

function* createFinanceStatus() {
  yield takeLatest(
    Types.CREATE_FINANCE_ACTION,
    sagaWrapper(function* (action) {
      const oldAvatar = action.params.photos;
      delete action.params.photos;
      const result = yield call(FinanceService.FINACE_CREATE, action.params);
      if (!isEmpty(oldAvatar)) {
        const imagesLocal = oldAvatar.filter((el) => el.originFileObj);
        yield promiseUpload(
          result?.data?.user?.merchantCode,
          TYPE_FILE.avatar,
          imagesLocal
        );
      }
      delay(1000);
      sagaSuccessMessage({
        action,
        successMessage: "Create success",
        description: action.params.companyName
      });
      yield put(FinanceAction.createFinanceSuccess());
      action.callback && action.callback();
    }, errorHandle(Types.REQUEST_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return sagaErrorHandler(function* (e, action) {
    console.warn("SAP SYSTEM ERROR:", e);
    yield put({ type: errorActionType, sessionId: action?.sessionId });
  });
}

export default function* rootSaga() {
  yield all([
    fork(getRequestList),
    fork(getRequestDetail),
    fork(updateRequestStatus),
    fork(createFinanceStatus)
  ]);
}
