import { call, put, takeLatest, takeEvery, fork, all } from "redux-saga/effects";

import { ModelService } from "service";
import ModelAction,{ Types } from "./actions"
import { sagaWrapper,  sagaErrorHandler } from "../../utils/common";

function* getModelList() {
  yield takeLatest(
    Types.MODEL_LIST_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(ModelService.MODEL_LIST, action.params);
      const params = {
        page: result?.pageCurrent || 1,
        recordTotal: result?.recordTotal || 10,
        pageTotal:
          (result?.recordTotal /
            result?.recordPerPage) *
            10 || 1,
        recordPerPage: result?.recordPerPage || 10,
      };
      yield put(ModelAction.modelListSuccess({ ...result, params }));
    }, errorHandle(Types.MODEL_FAIL))
  );
}

function* connectBrand() {
  yield takeEvery(
    Types.CONNECT_BRAND_ACTION,
    sagaWrapper(function* (action) {
      const { params, callback } = action;
      const result = yield call(ModelService.CONNECT_BRAND, params);
      yield put(ModelAction.connectBrandSuccess({...result, params}));
      callback && callback()
    }, errorHandle(Types.MODEL_FAIL))
  );
}

function errorHandle(errorActionType) {
  return sagaErrorHandler(function* (e, action) {
    yield put({ type: errorActionType, sessionId: action?.sessionId });
  });
}

export default function* rootSaga() {
  yield all([
    fork(getModelList),
    fork(connectBrand),
  ]);
}