import { removeKeyOjectValueEmpty } from "utils/common";
import Urls from "../consts/Urls";
import axiosClient from "./axios";

const FinanceService = {
  FINANCE_LIST_DEALER: async (params) => {
    return await axiosClient.get(Urls.FINANCE_LIST_DEALER, { params });
  },
  FINANCE_LIST: async (params) => {
    const newParams = removeKeyOjectValueEmpty(params);
    return await axiosClient.get(Urls.FINANCE_LIST, { params: newParams });
  },
  FINANCE_DETAIL: async (orderCode) => {
    return await axiosClient.get(`${Urls.FINANCE_DETAIL}/${orderCode}/detail`);
  },
  UPDATE_STATUS_REQUEST_FINANCE: async (orderCode, typeOrder, params) => {
    return await axiosClient.post(
      `${Urls.UPDATE_STATUS_REQUEST_FINANCE}/${orderCode}/update?type=${typeOrder}`,
      params
    );
  },
  FINANCE_STATIC_REQUEST: async (params) => {
    return await axiosClient.get(Urls.STATIC_REQUEST, { params });
  },
  FINACE_CREATE: async (params) => {
    return await axiosClient.post(Urls.FINACE_CREATE, params);
  }
};

export default FinanceService;
