import { all } from "redux-saga/effects";
import authSagas from "@iso/redux/auth/saga";
import inventorySagas from "./inventory/saga";
import authorizationSagas from "./authorization/saga";
import merchantSagas from "./merchant/saga";
import userSagas from "./user/saga";
import orderSagas from "./order/saga";
import reportSagas from "./report/saga";
import marketplaceSagas from "./marketplace/saga";
import chatSagas from "./chat/saga";
import purchaseSagas from "./purchase/saga";
import financeSagas from "./finance/saga";
import configSagas from "./config/saga";
import modelSagas from "./model/saga";
import notificationSagas from "./notification/saga";
import categorySagas from "./category/saga";
import preOrderSagas from "./preOrder/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    inventorySagas(),
    authorizationSagas(),
    merchantSagas(),
    userSagas(),
    orderSagas(),
    marketplaceSagas(),
    chatSagas(),
    purchaseSagas(),
    financeSagas(),
    configSagas(),
    modelSagas(),
    notificationSagas(),
    reportSagas(),
    categorySagas(),
    preOrderSagas(),
  ]);
};
