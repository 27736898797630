import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    signUpAction: ["params", "callback"],
    signUpSuccess: [],

    signInAction: ["params", "callback"],
    signInSuccess: [],

    changePasswordAction: ["params", "callback"],
    changePasswordSuccess: [],

    getCodeAction: ["params", "callback"],
    getCodeSuccess: [],

    resetPassWithCodeAction: ["params", "callback"],
    resetPassWithCodeSuccess: [],

    getLinkAction: ["params", "callback"],
    getLinkSuccess: [],

    resetPassWithLinkAction: ["params", "callback"],
    resetPassWithLinkSuccess: [],

    signUpDealerAction: ["params", "callback"],
    signUpDealerSuccess: [],

    signInDealerAction: ["params", "callback"],
    signInDealerSuccess: [],

    login: ["token"],
    logOut: [],

    authorizationFailure: []
  },
  {}
);
export default Creators;
