import { TYPE_FILE, PURCHASE_PAYMENT_OPTION, TYPE_DOC } from "consts/Enum";
import data from "containers/Remove/Mail/data";
import { isEmpty } from "lodash";
import { call, put, takeLatest, fork, all, delay } from "redux-saga/effects";
import { uploadToS3 } from "service/upload.service";
import Urls from "../../consts/Urls";
import {
  MarketplaceService,
  OrderService,
  PurchaseService
} from "../../service";
import {
  nameCar,
  sagaErrorHandler,
  sagaSuccessMessage,
  sagaWrapper
} from "../../utils/common";
import MarketplaceAction, { Types } from "./actions";

function* getMarketplaceList() {
  yield takeLatest(
    Types.MARKETPLACE_LIST_ACTION,
    sagaWrapper(function* (action) {
      const newParams = { ...action.params };
      if (newParams?.type) {
        delete newParams.type;
      }
      const result = yield call(MarketplaceService.LIST_MARKETPLACE, newParams);
      const data = result.data.map((item) => {
        const car = {
          thumb: item?.photos?.length
            ? Urls.URL_FILE + item?.photos[0]?.path?.origin
            : "",
          name: nameCar(item),
          des: item.vin
        };
        const supplier = "";
        const price = item?.cal?.totalAmount || item?.originalPrice;
        const id = item.code;
        const model = item?.model?.name;
        const location = item?.location?.name;
        return { ...item, supplier, car, price, id, location, model };
      });
      const params = {
        page: result.pageCurrent || 1,
        recordTotal: result.recordTotal,
        pageTotal: result?.pageTotal,
        recordPerPage: result.recordPerPage || 10
      };
      if (action.params?.type) {
        params.type = action.params.type;
      }
      const newResult = Object.assign(result, { data, params });
      action.callback && action.callback(data);
      yield put(MarketplaceAction.marketplaceListSuccess(newResult));
    }, errorHandle(Types.MARKETPLACE_LIST_FAILURE))
  );
}

function* getMarketplaceDetail() {
  yield takeLatest(
    Types.MARKETPLACE_DETAIL_ACTION,
    sagaWrapper(function* (action) {
      const result = yield call(
        MarketplaceService.INFO_DEALER_AS_BUYER,
        action.code
      );
      result.data.car = {
        thumb: result?.data?.photos?.length
          ? Urls.URL_FILE + result?.data?.photos[0]?.path?.origin
          : ""
      };
      yield put(MarketplaceAction.marketplaceDetailSuccess(result.data));
    }, errorHandle(Types.MARKETPLACE_FAILURE))
  );
}

function* updateMarketplaceBooking() {
  yield takeLatest(
    Types.MARKETPLACE_BOOKING_ACTION,
    sagaWrapper(function* (action) {
      yield put(MarketplaceAction.marketplaceBookingSuccess(action.params));
    }, errorHandle(Types.MARKETPLACE_FAILURE))
  );
}

function* updateFilterMarketplace() {
  yield takeLatest(
    Types.MARKETPLACE_UPDATE_FILTER_ACTION,
    sagaWrapper(function* (action) {
      yield put(
        MarketplaceAction.marketplaceUpdateFilterSuccess(action.params)
      );
    }, errorHandle(Types.MARKETPLACE_FAILURE))
  );
}

function* updateFilterApplyMarketplace() {
  yield takeLatest(
    Types.MARKETPLACE_UPDATE_FILTER_APPLY_ACTION,
    sagaWrapper(function* (action) {
      yield put(
        MarketplaceAction.marketplaceUpdateFilterApplySuccess(action.params)
      );
    }, errorHandle(Types.MARKETPLACE_FAILURE))
  );
}

function* createMarketplaceBooking() {
  yield takeLatest(
    Types.MARKETPLACE_BOOKING_CREATE_ACTION,
    sagaWrapper(function* (action) {
      const oldPhotos = action.params.mediaConact;
      delete action.params.mediaConact;
      const preOrderInfo = yield call(
        OrderService.PRE_ORDER_INFO,
        action.params.car.code,
        action.params.paymentOption
      );
      const paramsNew = {
        ...action.params,
        preOrderInfo: preOrderInfo.data.preOrderInfo
      };
      const result = yield call(
        MarketplaceService.CREATE_MARKETPLACE_ORDER,
        paramsNew
      );
      if (
        oldPhotos?.length > 0 &&
        action.params.paymentOption === PURCHASE_PAYMENT_OPTION.PAID_70.key
      ) {
        const fileList = oldPhotos.filter(
          (item) =>
            item.originFileObj.type.match("application/pdf") ||
            TYPE_DOC.includes(item.originFileObj.type)
        );
        if (!isEmpty(fileList)) {
          yield uploadToS3(
            fileList,
            result.result.orderRecord.code,
            TYPE_FILE.contract,
            "concat"
          );
        }
      }
      const resultOrder = yield call(
        PurchaseService.PURCHASE_DETAIL,
        result.result.orderRecord.code
      );
      delay(1000);
      sagaSuccessMessage({
        action,
        successMessage: "Create order success",
        description: action.params.vin
      });
      resultOrder.data.preOrderInfo = preOrderInfo.data.preOrderInfo;
      yield put(MarketplaceAction.marketplaceBookingCreateSuccess(resultOrder));
      action.callback && action.callback(resultOrder?.data?.code);
    }, errorHandle(Types.MARKETPLACE_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return sagaErrorHandler(function* (e, action) {
    yield put({ type: errorActionType, sessionId: action?.sessionId });
  });
}

export default function* rootSaga() {
  yield all([
    fork(getMarketplaceList),
    fork(getMarketplaceDetail),
    fork(updateMarketplaceBooking),
    fork(createMarketplaceBooking),
    fork(updateFilterMarketplace),
    fork(updateFilterApplyMarketplace)
  ]);
}
