import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./en";
import translationIn from "./in";
import translationVi from "./vi";
import storage from "../utils/localStorage";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
const resources = {
  vi: {
    ...translationVi,
  },
  en: {
    ...translationEN,
  },
  in: {
    ...translationIn,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // fallbackLng: storage.getLanguage() || "vi",
    lng: 'vi', // default language
    fallbackLng: "vi",
    defaultNS: "common",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
