import Urls from "../consts/Urls";
import axiosClient from "./axios";

const AuthService = {
  SIGN_UP: async (params) => {
    return await axiosClient.post(`${Urls.SIGN_UP}?userRole=supplier`, params);
  },
  SIGN_IN: async (params) => {
    return await axiosClient.post(Urls.SIGN_IN, params);
  },
  CHANGE_PASSWORD: async (params) => {
    return await axiosClient.post(Urls.CHANGE_PASSWORD, params);
  },
  GET_CODE: async (params) => {
    return await axiosClient.post(Urls.GET_CODE, params);
  },
  GET_LINK: async (params) => {
    return await axiosClient.post(Urls.GET_LINK, params);
  },
  RESET_PASS_WITH_CODE: async (params) => {
    return await axiosClient.post(Urls.RESET_PASS_WITH_CODE, params);
  },
  RESET_PASS_WITH_LINK: async (params) => {
    const { password, token } = params
    return await axiosClient.post(Urls.RESET_PASS_WITH_Link + `?token=${token}`, { password });
  },
  SIGN_UP_DEALER: async (params) => {
    return await axiosClient.post(
      `${Urls.SIGN_UP_DEALER}?userRole=dealer`,
      params
    );
  },
  SIGN_IN_DEALER: async (params) => {
    return await axiosClient.post(Urls.SIGN_IN_DEALER, params);
  },
  VERIFY_PHONE: async (params) => {
    return await axiosClient.post(Urls.VERIFY_PHONE, params);
  },
  RESEND_OTP_PHONE: async (params) => {
    return await axiosClient.post(Urls.RESEND_OTP_PHONE, params);
  },
  GET_VERIFY_EMAIL: async (params) => {
    return await axiosClient.post(Urls.GET_VERIFY_EMAIL, params);
  },
  VERIFY_EMAIL: async (params) => {
    return await axiosClient.post(Urls.VERIFY_EMAIL, params);
  }
};

export default AuthService;
