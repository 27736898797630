import { createReducer } from "reduxsauce";
import { Types } from "./actions";

const INITIAL_STATE = {
  isFetching: false,
  merchantList: [],
  merchantDetail: {}
};

export const merchantListAction = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: true
});

export const merchantListSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  merchantList: action.data.data
});

export const createMerchantAction = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: true
});

export const createMerchantSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false
});

export const createMerchantBankAction = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: true
});

export const createMerchantBankSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false
});

export const updateStatusMerchantAction = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: true
});

export const updateStatusMerchantSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false
});

export const merchantDetailAction = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: true
});

export const verifyMerchantAction = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: true
});

export const verifyMerchantSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false
});

export const merchantDetailSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  merchantDetail: action.data
});

export const resetUserFinance = (state = INITIAL_STATE, action) => ({
  ...state,
  merchantDetail: {}
});

export const merchantFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false
});

export const HANDLERS = {
  [Types.MERCHANT_LIST_ACTION]: merchantListAction,
  [Types.MERCHANT_LIST_SUCCESS]: merchantListSuccess,
  [Types.MERCHANT_CREATE_ACTION]: createMerchantAction,
  [Types.MERCHANT_CREATE_SUCCESS]: createMerchantSuccess,
  [Types.MERCHANT_DETAIL_ACTION]: merchantDetailAction,
  [Types.MERCHANT_DETAIL_SUCCESS]: merchantDetailSuccess,
  [Types.MERCHANT_UPDATE_STATUS_ACTION]: updateStatusMerchantAction,
  [Types.MERCHANT_UPDATE_STATUS_SUCCESS]: updateStatusMerchantSuccess,
  [Types.MERCHANT_BANK_CREATE_ACTION]: createMerchantBankAction,
  [Types.MERCHANT_BANK_CREATE_SUCCESS]: createMerchantBankSuccess,
  [Types.MERCHANT_VERIFY_ACTION]: verifyMerchantAction,
  [Types.MERCHANT_VERIFY_SUCCESS]: verifyMerchantSuccess,
  [Types.RESET_USER_FINANCE]: resetUserFinance,
  [Types.MERCHANT_FAILURE]: merchantFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
