import React from "react";

export default function SaveSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00033 19.3334V15.3334C6.00033 14.597 6.59728 14.0001 7.33366 14.0001H12.667C13.4034 14.0001 14.0003 14.597 14.0003 15.3334V19.3334M18.0003 19.3334H2.00033C1.26395 19.3334 0.666992 18.7365 0.666992 18.0001V2.00008C0.666992 1.2637 1.26395 0.666748 2.00033 0.666748H13.448C13.8017 0.666748 14.1408 0.807224 14.3909 1.05727L18.9431 5.60956C19.1932 5.85961 19.3337 6.19874 19.3337 6.55237V18.0001C19.3337 18.7365 18.7367 19.3334 18.0003 19.3334Z"
        stroke="white"
        stroke-width="1.2"
      />
    </svg>
  );
}
