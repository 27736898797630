import Urls from "../consts/Urls";
import { removeKeyOjectValueEmpty } from "../utils/common";
import axiosClient from "./axios";

const InventoryService = {
  LIST_INVENTORY: async (params) => {
    delete params.recordTotal;
    delete params.pageTotal;
    const newPamrams = removeKeyOjectValueEmpty(params);
    return await axiosClient.get(Urls.INVENTORY_LIST, { params: newPamrams });
  },
  COUNT_STATUS_INVENTORY: async () => {
    return await axiosClient.get(Urls.INVENTORY_COUNT_STATUS);
  },
  DETAIL_INVENTORY: async (code) => {
    return await axiosClient.get(`${Urls.INVENTORY_DETAIL}/${code}`);
  },
  LIST_CATEGORY: async (params) => {
    return await axiosClient.get(`${Urls.INVENTORY_CATE}`, { params: params });
  },
  DELETE_INVENTORY: async (code) => {
    return await axiosClient.delete(`${Urls.INVENTORY_DELETE}/${code}`);
  },
  LIST_LOCATION: async (params) => {
    return await axiosClient.get(`${Urls.LIST_LOCATION}`, { params });
  },
  LIST_BRAND: async (params = {}) => {
    return await axiosClient.get(`${Urls.LIST_BRAND}`, { params });
  },
  LIST_MODEL: async (params) => {
    return await axiosClient.get(`${Urls.LIST_MODEL}`, { params });
  },
  CREATE_INVENTORY: async (params) => {
    return await axiosClient.post(`${Urls.INVENTORY_CREATE}`, params);
  },
  UPDATE_INVENTORY: async (params, code) => {
    return await axiosClient.patch(
      `${Urls.INVENTORY_UPDATE_V2}/${code}`,
      params
    );
  },
  UPDATE_INVENTORY_STATUS: async (params, code) => {
    return await axiosClient.patch(
      `${Urls.INVENTORY_STATUS_V2}/${code}`,
      params
    );
  },
  GET_PRICING: async (params) => {
    return await axiosClient.post(`${Urls.INVENTORY_PRICING}`, params);
  },
  GET_PRICING_QUOTE: async (merchantCode, params) => {
    return await axiosClient.post(
      `${Urls.INVENTORY_PRICING_QUOTE}/${merchantCode}/car-quote`,
      params
    );
  },
};

export default InventoryService;
