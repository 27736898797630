import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    preOrderListAction: ['params', 'callback'],
    preOrderListSuccess: ['data'],

    requestListForSupplierAction: ['params', 'callback'],
    requestListForSupplierSuccess: ['data'],

    createPreOrderAction: ['params', 'callback'],
    createPreOrderSuccess: [],

    sendOfferAction: ['params', 'callback'],
    sendOfferSuccess: [],

    cancelRequestAction: ['params', 'callback'],
    cancelRequestSuccess: [],

    completedRequestAction: ['params', 'callback'],
    completedRequestSuccess: [],

    preOrderFailure: [],
  },
  {}
);
export default Creators;
