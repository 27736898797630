import { PURCHASE_UPDATE_PAYMENT_PERMISSION, TYPE_USER } from "../consts/Enum";
import Urls from "../consts/Urls";
import { removeKeyOjectValueEmpty } from "../utils/common";
import storage from "../utils/localStorage";
import axiosClient from "./axios";

const PurchaseService = {
  LIST_PURCHASE: async (params) => {
    delete params.recordTotal;
    delete params.pageTotal;
    const newPamrams = removeKeyOjectValueEmpty(params);
    return await axiosClient.get(Urls.PURCHASE_LIST, { params: newPamrams });
  },
  PURCHASE_DETAIL: async (orderCode) => {
    return await axiosClient.get(`${Urls.PURCHASE_DETAIL}/${orderCode}/detail`);
  },
  PURCHASE_UPDATE: async (orderCode, params) => {
    if (
      params.permission === PURCHASE_UPDATE_PAYMENT_PERMISSION.DEALER_PURCHASE
    ) {
      return await axiosClient.post(
        `${Urls.PURCHASE_UPDATE}/${orderCode}/update`,
        params
      );
    }
    if (params.permission === PURCHASE_UPDATE_PAYMENT_PERMISSION.DEALER_ORDER) {
      return await axiosClient.post(
        `${Urls.UPDATE_STATUS_ORDER_BUYER}/${orderCode}/update`,
        params
      );
    }
    if (params.permission === PURCHASE_UPDATE_PAYMENT_PERMISSION.ADMIN_ORDER) {
      return await axiosClient.post(
        `${Urls.PURCHASE_ADMIN_UPDATE}/${orderCode}/update?type=${params.type}`,
        params
      );
    }
  }
};

export default PurchaseService;
