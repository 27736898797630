import {createReducer} from "reduxsauce";
import {Types} from "./actions";
import { PAGINATION } from "consts/Enum";

const INITIAL_STATE = {
    isFetching: false,
    modelList: [],
    params: {
        page: PAGINATION.page,
        recordPerPage: PAGINATION.recordPerPage
    },
};

export const modelListAction = (state = INITIAL_STATE, action) => {
    return {
    ...state,
    isFetching: true,
}}

export const modelListSuccess = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        params: { ...state.params, ...action.data.params },
        isFetching: false,
        modelList: action.data
    };
}

export const connectBrandAction = (state = INITIAL_STATE, action) =>({
    ...state,
    isFetching: true,
})

export const connectBrandSuccess = (state = INITIAL_STATE, action) =>({
    ...state,
    isFetching: false,
})
 
export const brandFail = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
});

export const HANDLERS = {
    [Types.MODEL_LIST_ACTION]: modelListAction,
    [Types.MODEL_LIST_SUCCESS]: modelListSuccess,
    [Types.CONNECT_BRAND_ACTION]: connectBrandAction,
    [Types.CONNECT_BRAND_SUCCESS]: connectBrandSuccess,
    [Types.MODEL_FAIL]: brandFail,
};

export default createReducer(INITIAL_STATE, HANDLERS);