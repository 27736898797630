// json
import common_in from 'translation/in/common.json';
import inventory_in from 'translation/in/inventory.json';
import signin_in from 'translation/in/signin.json';
import signup_in from 'translation/in/signup.json';
import verify_phone_in from 'translation/in/verifyphone.json';
import approve_email_in from 'translation/in/approveEmail.json';
import email_verify_in from 'translation/in/emailVerify.json';
import order_in from 'translation/in/order.json';
import category_in from 'translation/in/category.json';
import finance_in from 'translation/in/finance.json';
import user_in from 'translation/in/user.json';
import breadcrumb_in from 'translation/in/breadcrumb.json';
import status_in from 'translation/in/status.json';
import forgot_password_in from 'translation/in/forgotPassword.json';
import profile_in from 'translation/in/profile.json';
import marketplace_in from 'translation/in/marketplace.json';
import purchase_in from 'translation/in/purchase.json';
import error_in from 'translation/in/error.json';
import store_in from 'translation/in/store.json';
import chat_in from 'translation/in/chat.json';
import platform_fee_report_in from 'translation/in/platformFeeReport.json';

const translation = {
  common: common_in,
  inventory: inventory_in,
  signin: signin_in,
  signup: signup_in,
  verifyPhone: verify_phone_in,
  approveEmail: approve_email_in,
  emailVerify: email_verify_in,
  order: order_in,
  finance: finance_in,
  user: user_in,
  breadcrumb: breadcrumb_in,
  status: status_in,
  category: category_in,
  forgotPassword: forgot_password_in,
  profile: profile_in,
  marketplace: marketplace_in,
  purchase: purchase_in,
  error: error_in,
  store: store_in,
  chat: chat_in,
  platformFeeReport: platform_fee_report_in,
};

export default translation;
