import { all, takeEvery, put, fork } from "redux-saga/effects";
import { createBrowserHistory } from "history";

import { getToken, clearToken } from "@iso/lib/helpers/utility";
import actions from "./actions";
import storage from "utils/localStorage";

const history = createBrowserHistory();

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* ({ payload }) {
    const { token, idUser, idStore } = payload;
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
        idUser: idUser,
        idStore: idStore,
        profile: "Profile"
      });
    }
    yield put({ type: actions.LOGIN_ERROR });
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem("id_token", payload.token);
    yield localStorage.setItem("id_store", payload.idStore);
    yield localStorage.setItem("id_user", payload.idUser);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
    yield storage.removeEmailOrPhoneSigin();
    yield storage.removePhoneCodeSigin();
    yield storage.removeAvatar();
    history.push("/");
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get("idToken");
    const idStore = localStorage.getItem("id_store");
    const idUser = localStorage.getItem("id_user");
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        idStore: idStore === "null" ? null : idStore,
        idUser,
        profile: "Profile"
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout)
  ]);
}
