import React from "react";

export default function CloseSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 2L18 18M2 18L18 2" stroke="white" stroke-width="1.2" />
    </svg>
  );
}
